const AppLocationsMap: { [key: string]: string | object } = {
	// Dashboard
	"/dashboard": "Dashboard",
	// Indicators
	"/indicators/list": "Indicateurs",
	// Labels
	"/indicators/label/clean": "Dealtracker Sponsoring - Nettoyage de label",
	"/indicators/label/edit": {
		IDX_SPT_DEALTRACKER: "Dealtracker Sponsoring - Édition de label",
		IDX_BOAMP: "BOAMP - Édition de label",
	},
	// Projects
	"/visualisations/projects/edit": "Édition de projet",
	"/visualisations/projects/new": "Nouveau projet",
	"/visualisations/projects/list": "Projets",
	"/visualisations/views/list": "Visualisations",
	// Visualisations
	"/visualisations/vue/new": "Création d'infographie",
	"/visualisations/vue": "Visualisations",
	// Dealtracker
	"/indicators/edit/a446c7f9-5629-4d16-9f6f-bb36449ff939":
		"Dealtracker Sponsoring - Administration",
	"/indicators/add/a446c7f9-5629-4d16-9f6f-bb36449ff939":
		"Dealtracker Sponsoring - Ajouter des données",
	"/vue/a446c7f9-5629-4d16-9f6f-bb36449ff939":
		"Dealtracker Sponsoring - Vue par défaut",
	"/indicators/edit/spec/a446c7f9-5629-4d16-9f6f-bb36449ff939":
		"Dealtracker Sponsoring - Paramétrage de la structure",
	// BOAMP
	"/indicators/edit/2a028238-5e9a-4d7b-9610-7e227b4e863b": "BOAMP - Administration",
	"/indicators/add/2a028238-5e9a-4d7b-9610-7e227b4e863b": "BOAMP - Ajouter des données",
};
export default AppLocationsMap;
