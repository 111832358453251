export function shuffle<T>(array: T[]) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

export function remove<T>(arr: T[], i: number): T[] {
	const newArr: T[] = [];
	for (let j = 0; j < arr.length; j++) {
		if (i === j) continue;
		newArr.push(arr[j]);
	}
	return newArr;
}

export function inBetween<T>(arr: T[], e: T): T[] {
	const newArr: T[] = [];
	for (let i = 0; i < arr.length; i++) {
		newArr.push(arr[i]);
		if (i !== arr.length - 1) newArr.push(e);
	}
	return newArr;
}

export const DeepObject = Object.freeze({ compact: false, showHidden: true, colors: true, });
