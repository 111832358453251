export interface Lens<A, B> {
	get: (a: A) => B;
	set: (a: A, b: B) => A;
}

export function composeLens<A, B, C>(ab: Lens<A, B>, bc: Lens<B, C>): Lens<A, C> {
	return {
		get: (a) => bc.get(ab.get(a)),
		set: (a, c) => ab.set(a, bc.set(ab.get(a), c)),
	};
}

export function curry2<T, U>(f: (t: T, u: U) => void): (t: T) => (u: U) => void {
	return (t: T) => (u: U) => f(t, u);
}

export function uncurry2<T, U, V>(f1: (t: T) => U, f2: (u: U) => V): (t: T) => V {
	return (t: T) => f2(f1(t));
}