import { useRef, useState } from "react";
import { connect, ConnectedProps, useStore } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { Button, Form, Modal, Space, Typography } from "antd";

import { Callbackize } from "../../../../lib/functions";
import { filterActionSet, InfoState } from "../../../../store/infographics";

import { Clause, DataType, Filter } from "../../../../model/filters";
import { Map, Maybe } from "../../../../interfaces/Utils";
import FilterWrapper from "./FilterAnd";

import tryLoadProject from "../../../Projects/Edit/fetchProject";

import { LoginState } from "../../../../store/login/interface";

const { Text } = Typography;

const dataTypePairsDealtracker: Map<DataType> = {
	"organization.name": "string",
	"league.name": "string",
	"contract.season": "number",
	"contract.begin": "string",
	"contract.sponsorship_status.fr": "string",
	"contract.sponsorship_status.en": "string",
	"contract.visibility.fr": "string",
	"contract.visibility.en": "string",
	"contract.duration": "number",
	"contract.end": "string",
	"contract.news_link": "string",
	"contract.amount": "number",
	"contract.level": "number",
	"contract.officialisation": "date",
	"contract.marketing_manager": "string",
	"contract.category.name.fr": "array:string",
	"contract.category.name.en": "array:string",
	"sponsor.name": "string",
	"sponsor.sector.name.fr": "array:string",
	"sponsor.sector.name.en": "array:string",
};

const dataTypePairsBOAMP: Map<DataType> = {
	"deal.objet": "string",
	"deal.date_parution": "date",
	"deal.date_limite_rep": "date",
	"deal.date_fin_diffusion": "date",
	"deal.global_amount": "string",
	"deal.lot_count": "string",
	"deal.url_doc": "string",
	"deal.location.dep_code": "string",
	"domaine.name": "string",
	"famille.name": "string",
	"procedure.name": "string",
	"emetteur.location.commune": "string",
	"emetteur.location.zip_code": "number",
	"emetteur.name": "string",
	"newstank.name": "string",
};

export const dataTypeMasks: Map<string> = {
	"organization.name": "Club",
	"league.name": "Championnat",
	"contract.season": "Saison",
	"contract.begin": "Début",
	"contract.sponsorship_status.fr": "Statut",
	"contract.sponsorship_status.en": "Status",
	"contract.sponsorship_status": "Statut",
	"contract.visibility.fr": "Visibilité",
	"contract.visibility.en": "Visibility",
	"contract.visibility": "Visibilité",
	"contract.duration": "Durée",
	"contract.end": "Fin",
	"contract.news_link": "Source",
	"contract.amount": "Montant (en million(s) d'euros)",
	"contract.level": "Niveau",
	"contract.officialisation": "Date d'officialisation",
	"contract.marketing_manager": "Régie marketing",
	"contract.category.name.fr": "Type 🇫🇷",
	"contract.category.name.en": "Type 🇬🇧/🇺🇸",
	"contract.category": "Type",
	"sponsor.name": "Sponsor",
	"sponsor.sector.name.fr": "Secteur d'activité",
	"sponsor.sector.name.en": "Sector",
	"sponsor.sector": "Secteur d'activité",
	"deal.objet": "Objet",
	"deal.date_parution": "Date de parution",
	"deal.date_limite_rep": "Date limite de réponse",
	"deal.date_fin_diffusion": "Date de fin de diffusion",
	"deal.global_amount": "Montant total",
	"deal.lot_count": "Nombre de lot(s)",
	"deal.url_doc": "Source",
	"deal.location.dep_code": "Lieu des marchés",
	"domaine.name": "Domaine",
	"famille.name": "Famille",
	"procedure.name": "Procédure",
	"emetteur.location.commune": "Commune",
	"emetteur.location.zip_code": "Code postal",
	"emetteur.name": "Emetteur",
	"newstank.name": "Newstank",
};

export const emptyClause: Clause = { include: true };

interface StateProps {
	filter: Filter;
}

function mapStateToProps(state: { info: InfoState }): StateProps {
	const {
		info: { filter },
	} = state;

	return {
		filter: filter ?? {},
	};
}

interface DispatchProps {
	setFilter: (filter: Filter) => void;
}
function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setFilter: (filter: Filter) => {
			dispatch(filterActionSet(filter));
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {
	type: Maybe<string>;
}
// TODO adapter le type
function DataSourceFilter(props: Props) {
	const { filter, setFilter, type } = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const clauses = filter?.clauses;
	const [form] = Form.useForm<{ ands: Clause[][] }>();
	const dataTypePairs =
		type === "IDX_BOAMP" ? dataTypePairsBOAMP : dataTypePairsDealtracker;
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const queryParameters = new URLSearchParams(window.location.search);
	const projectId = queryParameters.get("projectId") ?? "";
	const store = useStore().getState();
	// Au premier chargement si on est en pleine création d'une vue de type BOAMP on ajoute un filtre par defaut sur le NT du projet
	if (!store?.info?.desc?.uid && isFirstLoad && type === "IDX_BOAMP") {
		setIsFirstLoad(false);
		const loginState: Maybe<LoginState> = store?.login;
		const mUserId: Maybe<string> = loginState?.userId;
		tryLoadProject(projectId, mUserId).then((r) => {
			loginState?.newstanks?.forEach((nt) => {
				const exist = nt.children.filter((child) => child.code === r.project?.nt);
				if (exist.length) {
					setFilter({
						clauses: [
							[
								{
									field: "newstank.name",
									include: true,
									op: "equalTo",
									type: "string",
									value: nt.name,
								},
							],
						],
					});
					return false;
				}
			});
		});
	}
	// #region FilterModal
	const showModal = Callbackize(setIsModalVisible, true);
	const closeModal = Callbackize(setIsModalVisible, false);
	// #endregion

	const title = "Ajouter un filtre";
	const footer = [
		<Button key="back" onClick={closeModal}>
			Annuler
		</Button>,
		<Button key="use" type="primary" onClick={form.submit}>
			Sauvegarder
		</Button>,
	];

	function onEnd(clauses: Maybe<Clause[][]>) {
		setFilter({ clauses: clauses });
		closeModal();
	}

	const closeRef = useRef(onEnd);
	return (
		<>
			<Button onClick={showModal}>Filtrer les sources</Button>
			<Modal
				title={title}
				style={{ minWidth: 1100 }}
				visible={isModalVisible}
				onOk={closeModal}
				onCancel={closeModal}
				footer={footer}
				maskClosable={false}
			>
				<Space direction="vertical" style={{ height: 600, overflowY: "scroll" }}>
					<FilterWrapper
						dataTypePairs={dataTypePairs}
						values={clauses}
						form={form}
						closeRef={closeRef}
					/>
				</Space>
			</Modal>
		</>
	);
}

export default connector(DataSourceFilter);
