import { useState, useRef } from "react";

import { Button, Modal } from "antd";
import { TableOutlined } from "@ant-design/icons";

import HerePaste from "./HerePaste";

import { Callbackize } from "../../../../lib/functions";
import { Map } from "../../../../interfaces/Utils";

interface AddDataProps {
	id: string;
	userId?: string;
	headers: { title: string; dataIndex: string }[];
	keys: Map<{ data_type: string; title: string }>;
	arrays: Map<string>;
	requiredValues: string[];
	title: string;
	editingKey: React.Key;
}

function AddData(props: AddDataProps) {
	const { id, userId, headers, keys, arrays, requiredValues, title } = props;
	const [visible, setVisible] = useState<boolean>(false);
	const setVisibleRef = useRef(setVisible);
	return (
		<div>
			<Modal
				title={"Ajouter depuis Excel"}
				visible={visible}
				footer={null}
				onCancel={Callbackize(setVisible, false)}
				destroyOnClose
			>
				<HerePaste
					id={id}
					userId={userId}
					headers={headers}
					keys={keys}
					setVisibleRef={setVisibleRef}
					arrays={arrays}
					requiredValues={requiredValues}
					title={title}
				/>
			</Modal>
			<Button
				style={{ width: 110 }}
				onClick={Callbackize(setVisible, true)}
			>
				<TableOutlined />
				Importer
			</Button>
		</div>
	);
}

export default AddData;
