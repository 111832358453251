import { curry } from "lodash";

import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { saveProject } from "./projectHandlers";
import { EmptyProject } from "../interface";
import { Map, Maybe } from "../../../interfaces/Utils";
import { useHistory } from "react-router";

const urlCreator = curry(function uncurriedURLCreator(
	action: string,
	projectId: string
): string {
	return `/visualisations/vue/${action}?projectId=${projectId}`;
});

interface UploadButtonProps {
	project: Maybe<EmptyProject>;
	userId: string;
	action: "new" | "select";
}

function UploadButton(props: UploadButtonProps) {
	const { project, userId, action } = props;
	const h = useHistory();
	const title = project?.info?.private?.title;
	const body: Map<any> = project ? { ...project } : {};
	const msg = action === "new" ? "Créer une nouvelle vue" : "Ajouter une vue existante";
	return (
		<Button
			// type="link"
			// size="small"
			// className="nicer-link"
			onClick={() => saveProject(title, body, userId, h, {
				urlCreator: urlCreator(action),
			})}
		>
			<PlusOutlined />
			{msg}
		</Button>
	);
}

export default UploadButton;
