import { TimedResponse } from "../widget/interfaces";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_SET_LOADING = "LOGIN_SET_LOADING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const LOGIN_SET_NEWSTANKS = "LOGIN_SET_NEWSTANKS";

export const USER_COOKIE_KEY = "NTDNFO";
export const UNAUTHORIZED = "unauthorized";

export type LoginActions =
	| typeof LOGIN
	| typeof LOGIN_SUCCESSFUL
	| typeof LOGIN_SET_LOADING
	| typeof LOGOUT
	| typeof LOGIN_FAILED
	| typeof LOGIN_SET_NEWSTANKS
	;

export interface Newstank {
	color: string;
	langsAvailable: string[];
	name: string;
	url: string;
	id: number;
}

export interface UserData {
	email: string;
	firstname: string;
	lastname: string;
	hasAccess: boolean;
	newstanks: Newstank[];
}

export interface LoginData {
	userId: string;
	userData: UserData;
	userPref?: Record<string, unknown>;
}

export interface LoginError {
	code: number;
	message?: string;
}

export interface LoginAction {
	type: LoginActions;
	payload?: {
		userId?: string;
		remember?: boolean;
		reason?: LoginError;
		loading?: boolean;
		data?: LoginData;
		nts?: NewstanksInfo[];
	};
}

export interface LoginState {
	userId?: string;
	loginError?: LoginError;
	userData?: UserData;
	loading?: boolean;
	userPref?: Record<string, unknown>;
	newstanks?: NewstanksInfo[];
}

export interface NewstanksInfo {
	id: number;
	name: string;
	nameFront: string;
	code: string;
	color: string;
	rank: number;
	type: string;
	parent: number;
	parentCMS: number;
	hasSite: boolean;
	hasCMS: boolean;
	isActive: boolean;
	url: string;
	langDefault: string;
	langsAvailable: string[];
	children: NewstanksInfo[];
}

export type NewstanksInfoResponse = TimedResponse<NewstanksInfo[], never>;

export interface MinimalLoginState {
	login: LoginState;
}