import { Link, useLocation } from "react-router-dom";

import { Menu } from "antd";
import { PieChartTwoTone, HomeTwoTone, DatabaseTwoTone } from "@ant-design/icons";

import UserMenu from "./UserMenu";
import Logo from "./Logo";

const colorVar = "#9254DE";

// #region MenuElements
export interface MenuElement {
	title: string;
	icon: React.ReactNode;
	url?: string;
	disabled?: boolean;
}

const elements: MenuElement[] = [
	{
		title: "Dashboard",
		icon: <HomeTwoTone twoToneColor={colorVar} />,
		url: "/dashboard",
	},
	{
		title: "Données",
		icon: <DatabaseTwoTone twoToneColor={colorVar} />,
		url: "/indicators/list",
	},
	{
		title: "Visualisations",
		icon: <PieChartTwoTone twoToneColor={colorVar} />,
		url: "/visualisations/projects/list",
		// REACTIVATE
		// }, {
		// 	title: 'Liaisons annuaire',
		// 	icon: (<IdcardTwoTone twoToneColor={colorVar} />),
		// 	url: "/org"
	},
];
// #endregion

interface ComponentProps {
	collapsed: boolean;
}

function AppMenu({ collapsed }: ComponentProps) {
	const path = useLocation();
	const pathSnippets = path.pathname.split("/").filter((i) => i);
	const root = `/${pathSnippets[0]}`;
	const defaultSelectedKeys = [`${elements.findIndex((e) => e.url === root)}`];
	return (
		<div
			style={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<span>
				<UserMenu collapsed={collapsed} />
				<Menu
					id="app-menu"
					className="AppMenu-menu"
					mode="inline"
					defaultSelectedKeys={defaultSelectedKeys}
					theme="light"
				>
					{elements.map(({ title, icon, url, disabled }, i) => (
						<Menu.Item
							className="AppMenu-menu-item"
							key={`${i}`}
							icon={icon}
							disabled={disabled}
						>
							{url ? <Link to={url}>{title}</Link> : title}
						</Menu.Item>
					))}
				</Menu>
			</span>
			<Logo collapsed={collapsed} />
		</div>
	);
}

export default AppMenu;
