import { Maybe } from "../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../lib/fetch";
import { View } from "../../../model/vue";
import { TimedResponse } from "../../../store/widget/interfaces";
import { Project } from "../interface";
import { PayloadError, PayloadStatus, ViewAndUsed } from "./interfaces";
import { reduceFilter } from "./projectHandlers";

export interface FetchProjectResponse {
	err?: string;
	project?: Project;
	views?: ViewAndUsed[];
	viewErrors?: PayloadError[];
}

const errUserId = "invalid or missing usedId";
const errProject = "invalid or missing project";

function tryFetchProject(
	projectId: string,
	userId: string
): Promise<FetchProjectResponse> {
	const url = new URL(`${ROOT_API_URL}/api/v1/doc/project/${projectId}`);
	const headers = withAuthorization(userId, newHeaders());

	return fetch(url.href, { headers: headers }).then(async (r) => {
		if (!r.ok) {
			return {
				err: `Merci de faire transmettre le code d'erreur (${r.status}) lorsque vous réfererez cette erreur`,
			};
		}
		const { data }: TimedResponse<Project, never> = await r.json();
		const localProject = data;

		if (localProject === undefined) {
			return {
				err: `Une erreur est survenue lors de la récupération des données. Merci d'indiquer "/visualisations/projects/edit/${projectId}" lorsque vous réfererez cette erreur.`,
			};
		}
		return { project: localProject };
	});
}

function tryFetchProjectViews(
	project: Project,
	userId: string
): Promise<FetchProjectResponse> {
	const { used_views: usedViews } = project;
	// !TODO: this should be done in the API calls automatically, not forced
	// manually to the user
	const headers = withAuthorization(userId, newHeaders());
	return Promise.all<Promise<PayloadStatus>[]>(
		usedViews.map<Promise<PayloadStatus>>((id) => {
			const url = new URL(`${ROOT_API_URL}/api/v1/doc/vue/${id}`);
			url.searchParams.append("_formatter", "[uid,in,project,used_views,count]");
			const p: Promise<PayloadStatus> = fetch(url.href, {
				headers: headers,
			}).then(async (r) => {
				if (r.ok) {
					const b: TimedResponse<View, number> = await r.json();
					const { data: payload, fetched } = b;
					if (payload !== null) {
						return {
							status: r.status,
							payload: { view: payload, usedIn: fetched ?? 1 },
						};
					}
					return { status: r.status };
				}
				return {
					status: r.status,
					err: {
						msg: "n'a pas pu être chargé(e)",
						uid: id,
					},
				};
			});
			return p;
		})
	).then<FetchProjectResponse>((resp) => {
		const views = resp
			.filter((v) => v.err === undefined)
			.reduce(
				reduceFilter((t) => t.payload),
				[]
			);
		const viewErrors = resp
			.filter((v) => v.err !== undefined)
			.reduce(
				reduceFilter((t) => t.err),
				[]
			);
		return {
			project: project,
			views: views,
			viewErrors: viewErrors,
		};
	});
}

async function tryLoadProject(
	mProjectId: Maybe<string>,
	mUserId: Maybe<string>
): Promise<FetchProjectResponse> {
	if (!mUserId) {
		return new Promise(function builder(resolve) {
			resolve({ err: errUserId });
		});
	}
	if (!mProjectId) {
		return new Promise(function builder(resolve) {
			resolve({});
		});
	}
	const r = await tryFetchProject(mProjectId, mUserId);
	const { project, err } = r;
	if (err != null) {
		return r;
	}
	if (!project) {
		return { err: errProject };
	}
	return tryFetchProjectViews(project, mUserId);
}

export default tryLoadProject;
