import { useHistory } from "react-router-dom";

import { message, Tabs } from "antd";
import { useMemo } from "react";

const { TabPane } = Tabs;

function findKey(tabs: TabData[], defaultKey?: number | string): string | undefined {
    if (defaultKey === undefined) {
        return undefined;
    }
    const tab = (typeof defaultKey === "string")
        ? tabs.find((t) => t.key === defaultKey)
        : tabs[defaultKey];
    return tab?.key;
}

export interface TabData {
    key: string;
    url: string;
    label: string;
    icon: React.ReactNode
}

interface NavigatorProps {
    tabs: TabData[];
    defaultKey?: number | string;
}

function Navigator(props: NavigatorProps) {
    const { push } = useHistory();
    const { tabs, defaultKey } = props;
    const current = useMemo(() => findKey(tabs, defaultKey), [tabs, defaultKey]);

    return (
        <Tabs
            defaultActiveKey={current}
            onTabClick={(tabKey) => {
                const e = tabs.find((t) => t.key === tabKey);
                if (!e) {
                    message.error(`Clef \`${tabKey} non géré.`);
                    return;
                }
                push(e?.url);
            }}
        >
            {tabs.map((t) => <TabPane
                key={t.key}
                tab={
                    <span >
                        {t.icon}
                        {t.label}
                    </span>
                }
            />)}
        </Tabs>
    );
}

export default Navigator;