export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

export type SidebarActions = typeof OPEN_SIDEBAR | typeof CLOSE_SIDEBAR;

export type ComponentName = string;
export interface SidebarAction {
	type: SidebarActions;
	payload?: {
		component?: ComponentName;
		data?: any;
	};
}

export interface SidebarState {
	collapsed: boolean;
	component?: ComponentName;
	data?: any;
}

const initialState: SidebarState = {
	collapsed: true,
};

export const OpenAction = (component?: ComponentName, data?: any) => ({
	type: OPEN_SIDEBAR,
	payload: {
		component: component,
		data: data,
	},
});

export const CloseAction = {
	type: CLOSE_SIDEBAR,
};

// eslint-disable-next-line default-param-last
const sidebarReducer = (state = initialState, action: SidebarAction) => {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case OPEN_SIDEBAR: {
			const { component, data } = action.payload ?? {};
			const newState: SidebarState = {
				...state,
				collapsed: false,
				component: component,
				data: data,
			};
			return newState;
		}
		case CLOSE_SIDEBAR: {
			return {
				...state,
				collapsed: true,
				component: undefined,
				data: undefined,
			};
		}
		default: {
			return state;
		}
	}
};

export default sidebarReducer;
