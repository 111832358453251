import { useStore } from "react-redux";

import { Typography, Input, DatePicker, Space, Switch, Select } from "antd";

import moment from "moment";

import locale from "antd/lib/date-picker/locale/fr_FR";

import { Maybe, RSetter } from "../../../interfaces/Utils";
import { MetaAccess, MetaKey, updateMaybeBoth } from "../../../model/vue";
import MetaInfo from "../MetaInfo";
import { LoginState, NewstanksInfo } from "../../../store/login/interface";
import IntegrationCode from "../../tools/IntegrationCode";
import ProjectVues from "./ProjectVues";
import { OnChangePayload, ProjectBuilderData } from "./interfaces";
import ActionButtons from "./ActionButtons";
import { frenchFormat } from "../../../interfaces/editorial";
import StatusTag from "../../tools/StatusTag";

const { Title } = Typography;
const { Option, OptGroup } = Select;

function createOnChange(
	state: Maybe<ProjectBuilderData>,
	setState: RSetter<Maybe<ProjectBuilderData>>
) {
	return function (outer: MetaAccess, inner: MetaKey) {
		return function onChange({ target: { value } }: OnChangePayload): void {
			return setState({
				...state,
				project: {
					...(state?.project || {}),
					info: updateMaybeBoth(state?.project?.info, outer, inner, value),
				},
			});
		};
	};
}

const defaultProps = {
	lang: "fr",
};

interface ProjectEditProps_ {
	userId: string;
	projectId: Maybe<string>;
	data: Maybe<ProjectBuilderData>;
	setData: RSetter<Maybe<ProjectBuilderData>>;
	setLoading: RSetter<boolean>;
	lang: string;
}

type ProjectEditProps = ProjectEditProps_ & typeof defaultProps;

function ProjectEdit(props: ProjectEditProps) {
	const { data, projectId, userId, setData, setLoading, lang } = props;
	const loginState: Maybe<LoginState> = useStore().getState()?.login;
	const userData = loginState?.userData;
	const onChangeFunc = createOnChange(data, setData);
	function updatePublication(m: moment.Moment | null) {
		if (!userData) {
			return;
		}

		const { firstname, lastname } = userData;
		const dates = data?.project?.dates ?? {
			created: moment.parseZone(moment.now()).format(frenchFormat),
		};
		dates.published = m
			? {
					date: m.format(frenchFormat),
					author: `${firstname} ${lastname}`,
			  }
			: undefined;

		setData({
			...(data ?? {}),
			project: {
				...(data?.project ?? {}),
				dates: dates,
			},
		});
	}

	function setDefaultDataView() {
		if (data?.project !== undefined) {
			const hasDataView = (data.project.data_view ?? "").length > 0;
			const newProject = {
				...data.project,
				data_view: hasDataView ? undefined : "rubrique_data",
				dates: {
					...data?.project?.dates,
					published: hasDataView ? undefined : data.project.dates?.published,
				},
			};
			const newData = {
				...data,
				project: newProject,
			};
			setData(newData);
		}
	}
	const ntnsOptions: JSX.Element[] = [];
	const allNtns = loginState?.newstanks;
	const userNtns = loginState?.userData?.newstanks;
	let defNt: string | undefined = data?.project?.nt;
	if (allNtns && userNtns) {
		const ntns: NewstanksInfo[] = userNtns.reduce<NewstanksInfo[]>(
			(acc, { name }) => {
				const nt = allNtns.find((a) => a.name === name);
				return nt ? [...acc, nt] : acc;
			},
			[]
		);
		// Désactivation tout les newstanks car non fonctionnel
		// ntnsOptions.push(
		// 	<OptGroup label={lang === "en" ? "All Newstanks" : "Tout les Newstank"}>
		// 		<Option value="*">
		// 			{`${lang === "en" ? "All Newstanks" : "Tout les Newstank"} (*)`}
		// 		</Option>
		// 	</OptGroup>
		// );

		ntns.forEach((ntn) => {
			ntnsOptions.push(
				<OptGroup label={ntn.name} style={{ color: ntn.color }}>
					{ntn.children.map((nt) => {
						defNt = defNt ?? nt.code;
						return (
							<Option value={nt.code}>{`${nt.name} (${nt.code})`}</Option>
						);
					})}
				</OptGroup>
			);
		});
	}
	if (data?.project && data.project.nt !== defNt) {
		const newProject = { ...data.project };
		newProject.nt = defNt;
		const newData = {
			...data,
			project: newProject,
		};
		setData(newData);
	}
	return (
		<Space direction="vertical">
			<Space align="center" style={{ marginBottom: 12 }}>
				<Title level={3} style={{ marginBottom: 0 }}>
					À propos du projet
				</Title>
				<StatusTag project={data?.project} />
			</Space>
			<MetaInfo project={data?.project} />
			<Title level={5}>Privé</Title>
			<div>
				<span style={{ color: "red", paddingRight: 5 }}>*</span>
				Titre :
			</div>
			<Input
				value={data?.project?.info?.private.title ?? data?.project?.name ?? ""}
				onChange={onChangeFunc("private", "title")}
			/>
			<div>Description :</div>
			<Input.TextArea
				value={
					data?.project?.info?.private.description ??
					data?.project?.description ??
					""
				}
				onChange={onChangeFunc("private", "description")}
			/>
			{/* PUBLICATION */}
			<Title level={3} style={{ marginTop: 24 }}>
				Publication
			</Title>
			<Title level={5}>Usage destiné</Title>
			<Space style={{ marginBottom: 8 }}>
				<span style={{ color: "red", paddingRight: 5 }}>*</span>
				Newstank :{" "}
				<Select
					style={{ width: 350 }}
					value={defNt}
					placeholder={
						lang === "en"
							? "Choose a Newstank to publish to."
							: "Choisir un newstank où publier."
					}
					onSelect={(nt) => {
						if (!data?.project) {
							return;
						}
						const newProject = { ...data.project };
						newProject.nt = nt;
						const newData = {
							...data,
							project: newProject,
						};
						setData(newData);
					}}
				>
					{ntnsOptions}
				</Select>
			</Space>
			<Space style={{ marginBottom: 8 }}>
				Article
				<Switch
					checked={(data?.project?.data_view ?? "").length > 0}
					onChange={setDefaultDataView}
				/>
				Rubrique Data
			</Space>
			<Title level={5}>Date de publication</Title>
			<DatePicker
				placeholder="Choisir une date de publication"
				locale={locale}
				style={{ width: 250, marginBottom: 8 }}
				format="DD/MM/YYYY - HH:mm"
				onChange={updatePublication}
				value={
					data?.project?.data_view && data?.project?.dates?.published
						? moment.parseZone(
								data?.project?.dates?.published?.date,
								"DD/MM/YYYY'T'HH:mm:ss"
						  )
						: null
				}
				disabled={(data?.project?.data_view ?? "").length === 0}
			/>
			<Title level={5}>Code d'intégration</Title>
			{projectId ? <IntegrationCode id={projectId} /> : undefined}
			{/* VIEWS */}
			<ProjectVues
				state={data ?? {}}
				setState={setData}
				projectId={projectId}
				userId={userId}
				viewErrors={data?.viewErrors}
			/>
			{/* BUTTONS */}
			<ActionButtons
				projectId={projectId}
				userId={userId}
				project={data?.project}
				setLoading={setLoading}
			/>
		</Space>
	);
}

ProjectEdit.defaultProps = defaultProps;

export default ProjectEdit;
