import { Map, Maybe } from "../../../interfaces/Utils";
import { KeyParent } from "../../Widget/Table/edit/interfaces";
import boamp from "./boamp";
import dealtracker from "./dealtracker.json";

// console.log(boamp);

const idxs: Map<KeyParent[]> = {
	"2a028238-5e9a-4d7b-9610-7e227b4e863b": boamp,
	"a446c7f9-5629-4d16-9f6f-bb36449ff939": dealtracker as KeyParent[],
};

export default function get(id?: string): Maybe<KeyParent[]> {
	return id ? idxs[id] : undefined;
}
