import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Input, message, Row, Space, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import { useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { MinimalLoginState } from "../../../../store/login/interface";
import { Map, Maybe } from "../../../../interfaces/Utils";
import { flatten } from "../../../../lib/functions";
import { CloseAction } from "../../../../store/sidebar";
import { cardStyle } from "../../../Indicators/Label/common/constants";
import { Indicator } from "../../../Indicators/Management/interface";
import { direxport } from "../../../Widget/Table/edit/HeaderButtons";
import handlers from "./handlers";
import { Ingestion, RenderValueMRef } from "./handlers/interface";
import { boampKeys, dealtrackerKeys } from "./lineConf";

import { AllowOrigin, ROOT_API_URL } from "../../../../lib/fetch";

import { Node } from "../../../Indicators/interface";

import { loginFailedAction } from "../../../../store/login";

function generateInputs(
	data: Map<any>,
	defs: Ingestion
): [JSX.Element[], RenderValueMRef] {
	const inputs: JSX.Element[] = [];
	const rs = useRef<Map<() => any>>({});
	for (const key in defs.keys) {
		const value = data[key];
		const def = defs.keys[key];

		const h = handlers(def.renderer);
		const input = (h ?? ((_, value) => <Input value={value} />))(
			key,
			value,
			def.renderArg,
			def.disabled ?? false,
			rs
		);

		inputs.push(
			<div
				style={{
					marginBottom: 10,
				}}
			>
				<Title level={4}>{def?.title ?? key}</Title>
				{input}
			</div>
		);
	}
	return [inputs, rs];
}

interface LineEditProps {
	data: {
		record: any;
		idx: Maybe<Indicator>;
	};
}

function LineEdit(props: LineEditProps) {
	const {
		data: { record, idx },
	} = props;
	const flattened = flatten(record);
	const isDealtracker = idx?.title === "Dealtracker Sponsoring";
	const keys = isDealtracker ? dealtrackerKeys : boampKeys;
	let finalKeys: Ingestion;
	if (!isDealtracker && record && record.deal && record.deal.idweb) {
		finalKeys = { keys: {} };
		Object.keys(keys.keys).forEach((key) => {
			if (keys.keys[key].extra && keys.keys[key].extra.ifUserCreated) {
				finalKeys.keys[key] = keys.keys[key];
			} else {
				finalKeys.keys[key] = { ...keys.keys[key], disabled: true };
			}
		});
	} else {
		finalKeys = keys;
	}
	const [inputs, rs] = generateInputs(flattened, finalKeys);
	const reduxDispatch = useDispatch();
	function closeSidebar() {
		reduxDispatch(CloseAction);
	}
	const create = record.length === 0;
	const title = create ? "Création" : "Édition";
	const id = (idx?.uid || (idx as any).id) as string;
	const {
		login: { userId },
	} = useStore<MinimalLoginState>().getState();
	const [open, setOpen] = useState(true);
	const dispatch = useDispatch();
	const saver = async () => {
		if (!id && !create) {
			message.error("Une erreur interne s'est produite...");
			console.log("missing idx id:", idx);
			return;
		}
		// On met a jour l'objet de base avec les modifs
		Object.keys(rs.current).forEach((key) => {
			flattened[key] = rs.current[key]();
		});
		let payload;
		if (create) {
			payload = { edited: [], created: [flattened], deleted: [] };
		} else {
			payload = { edited: [flattened], created: [], deleted: [] };
		}
		// On envoie les données
		const r = await direxport(userId, id, payload);
		// On attends un petit delai afin qu'ELS puisse se mettre a jour correctement
		const actionStr = create ? "créée" : "editée";
		if (r.error !== undefined) {
			if (r.error.code === 401) {
				dispatch(loginFailedAction(401));
				return;
			}
			message.error(
				`la ligne n'a pas pu être ${actionStr}: ${r.error.code} (${r.error.message})`
			);

			return;
		}
		const info = create ? r.result?.created[0] : r.result?.edited[0];
		if (!info) {
			message.error(`la ligne n'a pas du être ${actionStr}, regarder les logs.`);
			return;
		}

		if (info.error) {
			message.error(`la ligne n'a pas pu être ${actionStr}: ${info.error}`);
			return;
		}

		setTimeout(function () {
			window.location.reload();
			closeSidebar();
			setOpen(false);
		}, 500);
	};

	return (
		<Modal
			title={title}
			centered
			visible={open}
			onOk={() => {
				saver();
			}}
			onCancel={() => {
				closeSidebar();
				setOpen(false);
			}}
			okText="Sauvegarder"
			cancelText="Annuler"
			width="90vw"
			// height="90vh"
			bodyStyle={{ height: "80vh", overflow: "auto" }}
		>
			{inputs}
		</Modal>
	);
}

export default LineEdit;
