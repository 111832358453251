import { Lens, composeLens } from "../../lib/lens";
import { wrap } from "../../lib/strings";
import { Maybe } from "../../interfaces/Utils";
import Search, {
	defaultPagination,
	defaultSearchParams,
	Sorting,
	SearchParams,
	SearchParam,
	Pagination,
} from "../../model/search";
import { WidgetState, defaultSearch } from "../../store/widget";
import Vue, { VueData, Suggestions, defaultSuggestions } from "../../model/vue";

export const loadingLens: Lens<WidgetState, boolean> = {
	get({ loading }) { return !!loading; },
	set(s, l) { return ({ ...s, loading: l, }); },
};

export const vueLens: Lens<WidgetState, Maybe<Vue>> = {
	get({ vue }) { return vue; },
	set(s, v) { return ({ ...s, vue: v }); },
};

export const dataLens: Lens<WidgetState, Maybe<VueData>> = {
	get({ data }) { return data; },
	set(s, v) {
		return ({
			...s, data: v ? {
				...v,
				hits: [...v.hits]
			} : v
		});
	},
};

export const suggestionsLens: Lens<WidgetState, Suggestions> = {
	get({ suggestions }) { return suggestions ?? defaultSuggestions; },
	set(s, v) { return ({ ...s, suggestions: v }); },
};

export const searchLens: Lens<WidgetState, Search> = {
	get({ search }) { return search ?? defaultSearch; },
	set(st, se) { return ({ ...st, search: se, }); }
};

export const sortingLens: Lens<Search, Maybe<Sorting>> = {
	get({ sorting }) { return sorting; },
	set(s, sorting) { return ({ ...s, sorting, }); }
};

export const paramsLens: Lens<Search, SearchParams> = {
	get({ params }) { return params ?? defaultSearchParams; },
	set(s, params) { return ({ ...s, params, }) }
};

export const widgetSearchParamsLens = composeLens(searchLens, paramsLens);

export const allLens: Lens<SearchParams, Maybe<SearchParam>> = {
	get({ _all }) { return _all; },
	set(s, _all) { return ({ ...s, _all: wrap(_all ?? []), }); }
};

export const paginationLens: Lens<Search, Pagination> = {
	get({ pagination }) { return pagination ?? defaultPagination; },
	set(s, pagination) { return ({ ...s, pagination, }) },
};

export const searchParamsLens = (key: string) => ({
	get: (sp: SearchParams): Maybe<SearchParam> => sp[key],
	set: (sp: SearchParams, value: SearchParam): SearchParams => ({
		...sp,
		[key]: value,
	}),
});

export const searchSortLens = composeLens(searchLens, sortingLens);
