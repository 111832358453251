import { Tag, Checkbox, Row } from 'antd';
import { FilterDropdownProps } from "antd/lib/table/interface";
import _uniqueId from "lodash/uniqueId";

import { paginationLens, paramsLens } from "../../lenses";
import Search, { defaultPagination, SearchParams } from "../../../../model/search";
import Vue from "../../../../model/vue";
import { remove } from "../../../../lib/array";
import { Maybe } from '../../../../interfaces/Utils';

// const dealtrackerTags = [
// 	{
// 		value_fr: "Nouveau",
// 		value_en: "New",
// 		color: "green",
// 	},
// 	{
// 		value_fr: "Prolongation",
// 		value_en: "Renewal",
// 		color: "gold",
// 	},
// 	{
// 		value_fr: "Extension",
// 		value_en: "Extension",
// 		color: "lime",
// 	},
// 	{
// 		value_fr: "Renégociation",
// 		value_en: "Renegotiation",
// 		color: "magenta",
// 	},
// 	{
// 		value_fr: "Fin",
// 		value_en: "End",
// 		color: "red",
// 	},
// 	{
// 		value_fr: "Diminution",
// 		value_en: "Decrease",
// 		color: "volcano",
// 	},
// ]

const defaultProps = {
	lang: "fr",
};

type TagFilterProps = {
	e: any;
	search: Search;
	updateSearch(s: Search): void;
	vue: Vue;
	lang?: string;
} & FilterDropdownProps & typeof defaultProps;

function TagFilter(props: TagFilterProps) {
	const {
		e, search, updateSearch, vue, lang,
	} = props;
	const params: SearchParams = paramsLens.get(search);
	const index: string = e?._dataIndex ?? "";
	const def = vue.keys[index];
	let filters: Maybe<{ text?: string; value: React.Key; color: string; }[]>;
	const rawFilters = def?.filters?.args;
	if (Array.isArray(rawFilters)) {
		filters = rawFilters;
	} else {
		if (lang in rawFilters) {
			filters = rawFilters[lang];
		}
	}
	if (!filters || !def)
		return null;

	// Selected keys
	const selectedKeys = paramsLens.get(search)[index] ?? [];
	const setSelectedKeys: (keys: string[]) => void = keys => {
		const s1 = paramsLens.set(search, { ...params, [index]: keys, });
		const s2 = paginationLens.set(s1, defaultPagination);
		updateSearch(s2);
	};
	const onChange = (i: number, key: React.Key) => (checked: boolean) => {
		if (checked) {
			setSelectedKeys([...selectedKeys, '' + key]);
		} else {
			const index = selectedKeys.indexOf('' + key);
			if (index > -1)
				setSelectedKeys(remove(selectedKeys, index));
		}
	};

	return (
		<div style={{ padding: 8, width: 250, }}>
			<Checkbox.Group
				value={selectedKeys}
			>
				{/* <Row>
                <Checkbox value="all" onClick={checkAll}>
                    Tous
                </Checkbox>
            </Row> */}
				{filters.map((f, i) => {
					const { color, value, text } = f;
					const strValue = '' + value;

					function onClick() {
						let checked = selectedKeys.indexOf(strValue) === -1;
						onChange(i, value)(checked);
					}

					return (
						<Row key={_uniqueId('filters-')}>
							<Checkbox style={{ marginTop: 8 }} value={strValue} onClick={onClick}>
								<Tag
									key={strValue}
									// checked={selectedKeys.indexOf(value) !== -1}
									// onChange={onChange(i, f.value)}
									color={color}
								>
									{text ?? strValue}
								</Tag>
							</Checkbox>
						</Row>
					);
				})}
				{/* <Row>
                <Checkbox style={{ marginTop: 8 }} value="others">
                    <Tag
                        key="others"
                        // checked={selectedKeys.indexOf('' + f.value) !== -1}
                        // onChange={onChange(i, f.value)}
                        color="geekblue"
                    >
                        Autres
                    </Tag>
                </Checkbox>
            </Row> */}
			</Checkbox.Group>
		</div>
	);
}

TagFilter.defaultProps = defaultProps;

export default TagFilter;
