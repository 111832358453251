import { Spin } from "antd";
import Vue, { Suggestions, VueData } from "../../model/vue";
import Search from "../../model/search";
import WidgetTable from "./Table";

const defaultProps = {
	lang: "fr",
};

type DisplayWidgetProps = {
	id: string;
	color: string;
	vue: Vue;
	data: VueData;
	loading: boolean;
	search: Search;
	updateSearch(s: Search): void;
	suggestions: Suggestions;
	updateSuggestions(index: string, key: string, lang?: string): void;
	showCols: boolean;
	editable: boolean;
	hideInfo: boolean;
	lang?: string;
} & typeof defaultProps;

function DisplayWidget(props: DisplayWidgetProps) {
	const {
		id,
		color,
		vue,
		data,
		loading,
		search,
		updateSearch,
		suggestions,
		updateSuggestions,
		showCols,
		editable,
		hideInfo,
		lang,
	} = props;
	const v = "vue" in vue ? ((vue as any).vue as Vue) : vue;
	const { type } = v;

	switch (type) {
		case "table":
			return (
				<WidgetTable
					// {...props}
					vue={v}
					id={id}
					color={color}
					data={data}
					loading={loading}
					search={search}
					updateSearch={updateSearch}
					suggestions={suggestions}
					updateSuggestions={updateSuggestions}
					showCols={showCols}
					editable={editable}
					hideInfo={hideInfo}
					lang={lang}
				/>
			);
		default:
			return <Spin size="large" />;
	}
}

DisplayWidget.defaultProps = defaultProps;

export default DisplayWidget;
