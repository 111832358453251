import { useState } from "react";

import { Select } from "antd";

import { useStore } from "react-redux";
import { RenderValueMRef, SelectArgs } from "./interface";
import { LoginState } from "../../../../../store/login/interface";

interface ShortNewstank {
	name: string;
	code?: string;
	id: number;
	color: string;
}

const allNT: ShortNewstank = { name: "Tous/All", id: -1, color: "black" };

export const newsTanks: ShortNewstank[] = [
	{ name: "NT Energies", code: "energies-redaction", id: 28, color: "#2868a8" },
	{ name: "NT Mobilités", code: "nt-mobilites", id: 24, color: "#17a396" },
	{ name: "NT Cities", code: "cities-redaction", id: 16, color: "#ca8c1a" },
	{ name: "NT RH", code: "rh-redaction", id: 14, color: "#c00444" },
	{ name: "NT Education", code: "education-redaction", id: 5, color: "#00a9d2" },
	{ name: "NT Football", code: "football-fr", id: 2, color: "#008A46" },
	{ name: "NT Culture", code: "culture-redaction", id: 13, color: "#E32B86" },
];

function splitData(data: any, validIds: number[]): [{ id: number }[], number[]] {
	if (data === undefined || !Array.isArray(data)) {
		return [[], []];
	}

	return data.reduce(
		(acc, cur) => {
			if ("id" in cur) {
				const id = +cur.id;
				if (validIds.indexOf(id) !== -1) {
					acc[1].push(id);
				} else {
					acc[0].push({ id: id });
				}
			} else if ("name" in cur) {
				const e = newsTanks.find((nt) => nt.name === cur.name);
				if (e) {
					acc[1].push(e.id);
				}
			}
			return acc;
		},
		[[] as { id: number }[], [] as number[]]
	);
}

function remapCur(
	nts: ShortNewstank[]
): (slice: (number | { id: number })[]) => ShortNewstank[] {
	return (slice: (number | { id: number })[]) =>
		slice.reduce((acc, cur) => {
			const i: number = typeof cur === "number" ? cur : cur.id;
			const n = nts.find(({ id: ntId }) => ntId === i);
			return n !== undefined ? [...acc, n] : acc;
		}, [] as ShortNewstank[]);
}
export default function renderer(
	key: string,
	data: any,
	args: SelectArgs,
	disabled: boolean,
	rs: RenderValueMRef
): JSX.Element {
	const { login }: { login: LoginState } = useStore().getState();
	const userValidOptions = login.userData?.newstanks?.map((nt) => nt.id) ?? [];
	const [frozen, values] = splitData(data, userValidOptions);
	const [value, setValue] = useState<number[]>(values);
	const remap = remapCur(newsTanks);
	rs.current[key] = () => [...remap(frozen), ...remap(value)];
	const options = newsTanks
		.filter((nt) => userValidOptions.indexOf(nt.id) !== -1 || values.includes(nt.id))
		.map((nt) => (
			<Option value={nt.id}>
				<span
					style={{
						height: "10px",
						width: "10px",
						borderRadius: "50%",
						backgroundColor: nt.color,
						display: "inline-block",
					}}
				/>{" "}
				{`${nt.name}`}
			</Option>
		));
	return (
		<Select
			style={{ minWidth: 300, width: "100%" }}
			value={value}
			mode={args.multiple ?? false ? "multiple" : undefined}
			// options={options}
			onSelect={(v) => {
				if (value.indexOf(v) === -1) {
					setValue([...value, v]);
				}
			}}
			disabled={disabled}
			onDeselect={(v) => {
				const index = value.indexOf(v);
				if (index !== -1) {
					const nvalues = [...value];
					nvalues.splice(index, 1);
					setValue(nvalues);
				}
			}}
		>
			{options}
		</Select>
	);
}
