import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router";

import { Button, Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import { Maybe } from "../../../interfaces/Utils";
import { LoginState } from "../../../store/login/interface";
import { ProjectBuilderData, ProjectBuilderState } from "./interfaces";
import tryLoadProject from "./fetchProject";
import ProjectSkeleton from "./ProjectSkeleton";
import ProjectEdit from "./ProjectEdit";
import { builderFetch } from "./projectHandlers";

function ProjectBuilder() {
	const [state, setState] = useState<ProjectBuilderState>({});
	const { id: projectId } = useParams<{ id?: string }>();

	const loginState: Maybe<LoginState> = useStore().getState()?.login;
	const mUserId: Maybe<string> = loginState?.userId;

	function setData(data: Maybe<ProjectBuilderData>) {
		setState({ ...state, data: data });
	}

	function setLoading(value: boolean) {
		setState({...state, loading: value});
	}

	useEffect(() => {
		setState({ ...state, loading: true });
		tryLoadProject(projectId, mUserId).then(builderFetch(state, setState));
	}, [projectId]);

	const { loading, data, err } = state;

	if (loading && projectId) {
		return <ProjectSkeleton />;
	}
	if (!mUserId) {
		return (
			<Result
				title="Une erreur de session est survenue"
				// !TODO: changer l'icône
				icon={<SmileOutlined />}
				extra={<Button onClick={() => window.location.reload()} />}
			>
				Recharger la page
			</Result>
		);
	}
	if (err) {
		return (
			<Result
				title="Une erreur est survenue"
				icon={<SmileOutlined />}
				extra={
					<Button href="/visualisations/projects/list">
						Retour à la liste des projets
					</Button>
				}
			/>
		);
	}

	return (
		<ProjectEdit
			userId={mUserId}
			projectId={projectId}
			data={data}
			setData={setData}
			setLoading={setLoading}
		/>
	);
}

export default ProjectBuilder;
