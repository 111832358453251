import { Ingestion } from "./handlers/interface";

export const boampKeys: Ingestion = {
	keys: {
		newstank: {
			title: "Newstank",
			type: "string",
			renderer: "select-newstank",
			renderArg: {
				multiple: true,
				values: [
					{
						label: "Culture",
						value: "culture",
					},
					{
						label: "Education",
						value: "education-fr",
					},
					{
						label: "Cities",
						value: "cities",
					},
					{
						label: "Mobilités",
						value: "mobilites",
					},
					{
						label: "Ressources Humaines",
						value: "RH",
					},
					{
						label: "Energies",
						value: "energies",
					},
					{
						label: "Sport",
						value: "sport",
					},
					{
						label: "Football France",
						value: "football-eu",
					},
					{
						label: "Football International",
						value: "football-ch",
					},
				],
			},
			extra: { ifUserCreated: true },
		},
		"deal.objet": {
			title: "Objet",
			type: "string",
			renderer: "default-text",
			extra: { ifUserCreated: true },
		},
		"deal.date_parution": {
			title: "Date de parution",
			type: "date",
			renderer: "date",
			extra: { ifUserCreated: true },
		},
		"deal.date_limite_rep": {
			title: "Date limite de réponse",
			type: "date",
			renderer: "date",
			extra: { ifUserCreated: true },
		},
		"deal.date_fin_diffusion": {
			title: "Date de fin de diffusion",
			type: "date",
			renderer: "date",
			extra: { ifUserCreated: true },
		},
		"deal.global_amount": {
			title: "Montant global",
			type: "number",
			renderer: "default-number",
			renderArg: {
				defaultValue: -1,
				onDefault: "hide",
			},
			extra: { ifUserCreated: false },
		},
		"deal.lot_count": {
			title: "Nombre de lot(s)",
			type: "number",
			renderer: "default-number",
			extra: { ifUserCreated: false },
		},
		domaine: {
			title: "Domaine",
			type: "string",
			renderer: "tags",
			renderArg: {
				multiple: true,
				renderer: {
					label: "name",
					value: "code",
				},
				suggest: {
					match: "name",
					labels: ["DOMAINE"],
				},
			},
			extra: { ifUserCreated: false },
		},
		"famille.code": {
			title: "Famille",
			type: "string",
			renderer: "select",
			renderArg: {
				values: [
					{
						label: "Marchés entre 90 k€ et seuils européens",
						value: "FNS",
					},
					{
						label: "Marchés <90 k€ (MAPA)",
						value: "MAPA",
					},
					{
						label: "Divers",
						value: "DIVERS",
					},
					{
						label: "Marchés européens",
						value: "JOUE",
					},
					{
						label: "Délégation de service public",
						value: "DSP",
					},
					{
						label: "Autre",
						value: "-",
					},
				],
				default: {
					label: "Autre",
					value: "-",
				},
			},
			extra: { ifUserCreated: false },
		},
		"procedure.code": {
			title: "Procédure",
			type: "string",
			renderer: "select",
			renderArg: {
				values: [
					{
						label: "Procédure Ouverte",
						value: "OUVERT",
					},
					{
						label: "Procédure NC",
						value: "-",
					},
					{
						label: "Procédure Adaptée",
						value: "PROCEDURE_ADAPTE",
					},
					{
						label: "Procédure NC",
						value: "AUTRE",
					},
					{
						label: "Procédure Restreinte",
						value: "RESTREINT",
					},
					{
						label: "Procédure Dialogue compétitif",
						value: "DIALOGUE_COMPETITIF",
					},
					{
						label: "Procédure Négociée",
						value: "NEGOCIE",
					},
					{
						label: "Procédure NC",
						value: "ATTRIBUE_SANS_PUB_JOUE",
					},
				],
				default: {
					label: "Procédure NC",
					value: "-",
				},
			},
			extra: { ifUserCreated: false },
		},
		"emetteur.name": {
			title: "Nom de l'émetteur",
			type: "string",
			renderer: "default-text",
			extra: { ifUserCreated: true },
		},
		"emetteur.location.commune": {
			title: "Commune émetteur",
			type: "string",
			renderer: "default-text",
			renderArg: {
				renderer: {
					label: "label",
					value: "label",
				},
				suggest: {
					match: "label",
					labels: ["COMMUNE"],
				},
			},
			fallback: "emetteur.location.commune2",
			extra: { ifUserCreated: false },
		},
		"deal.url_doc": {
			title: "URL document",
			type: "string",
			renderer: "default-text",
			extra: { ifUserCreated: false },
		},
		// "deal.location": {
		//     title: "deal.location",
		//     type: "string",
		//     renderer: "default-text"
		// },
	},
};

export const dealtrackerKeys: Ingestion = {
	keys: {
		"organization.name": {
			title: "Club",
			type: "string",
			renderer: "default-text",
			renderArg: {
				renderer: {
					label: "name",
					value: "name",
				},
				suggest: {
					match: "name",
					labels: ["Organization"],
				},
			},
		},
		"league.name": {
			title: "Championnat",
			type: "string",
			renderer: "default-text",
			renderArg: {
				renderer: {
					label: "name",
					value: "name",
				},
				suggest: {
					match: "name",
					labels: ["Championship"],
				},
			},
		},
		"contract.season": { title: "Saison" },
		"contract.sponsorship_status": { title: "Statut" },
		"contract.begin": { title: "Début" },
		// "contract.status_FR": { title: "Statut" },
		"contract.visibility": { title: "Visibilité" },
		// "contract.visibility_EN": { title: "Visibility" },
		"contract.duration": {
			title: "Durée",
			type: "number",
			renderer: "default-number",
		},
		"contract.end": { title: "Fin" },
		"contract.news_link": { title: "Source" },
		"contract.amount": {
			title: "Montant",
			type: "number",
			renderer: "default-number",
		},
		"contract.level": { title: "Niveau", type: "number", renderer: "default-number" },
		"contract.officialisation": {
			title: "Officialisation",
			type: "date",
			renderer: "date",
		},
		"contract.marketing_manager": { title: "Régie marketing" },
		"contract.category": {
			title: "Type de contrat",
			type: "string",
			renderer: "select",
			renderArg: {
				multiple: true,
				values: [
					{
						label: "Nouveau",
						value: "nouveau",
					},
					{
						label: "Prolongation",
						value: "prolongation",
					},
					{
						label: "Extension",
						value: "extension",
					},
					{
						label: "Renégociation",
						value: "renégociation",
					},
					{
						label: "Fin",
						value: "fin",
					},
					{
						label: "Diminution",
						value: "diminution",
					},
				],
			},
		}, // Tableau
		"sponsor.name": { title: "Partenaire" },
		"sponsor.sector": {
			title: "Secteur d'activité",
			type: "string",
			renderer: "tags",
			renderArg: {
				multiple: true,
				result: "array:string",
				renderer: {
					label: "name",
					value: "name",
				},
				suggest: {
					match: "name",
					labels: ["SECTOR"],
				},
			},
		}, // Tableau
	},
};
