import { Map } from "../../../../interfaces/Utils";

const defaultMasks: Map<string> = {
	name_FR: "Nom 🇫🇷",
	name_EN: "Nom 🇬🇧",
	name: "Nom / Name",
	_all: "Partout",
	middle: "Secteur intermédiaire",
	upper: "Secteur économique",
	amount: "Montant",
	begin: "Début",
	duration: "Durée",
	end: "Fin",
	level: "Niveau",
	marketing_manager: "Régie Marketing",
	news: "Source",
	officialisation: "Date d'officialisation",
	season: "Saison",
	status_EN: "Statut",
	status_FR: "Status",
	visibility_EN: "Visibility",
	visibility_FR: "Visibilité",
};

export default defaultMasks;
