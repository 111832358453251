export const SET_VUE = "SET_VUE";
export const SET_DATA = "SET_DATA";
export const SET_VUE_AND_DATA = "SET_VUE_AND_DATA";
export const SET_SUGGESTIONS = "SET_SUGGESTIONS";
export const SET_SORTING = "SET_SORTING";
export const SET_SEARCH = "SET_SEARCH";
export const SET_LOADING = "SET_LOADING";
export const SET_ACCEPTED_SUGGESTION = "SET_ACCEPTED_SUGGESTION";
export const SET_ERROR = "SET_ERROR";

export type Actions =
	| typeof SET_VUE
	| typeof SET_DATA
	| typeof SET_SEARCH
	| typeof SET_SUGGESTIONS
	| typeof SET_LOADING
	| typeof SET_SORTING
	| typeof SET_VUE_AND_DATA
	| typeof SET_ACCEPTED_SUGGESTION
	| typeof SET_ERROR
	;
