import moment from "moment";
import { Map, Maybe, RSetter } from "../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "./fetch";
import {reduce as _reduce} from 'lodash'

export function Callbackize<T, U>(f: (t: T) => U, v: T): () => U {
	return () => f(v);
}

export function fallbackCopyTextToClipboard(
	text: string,
	options?: { onOk?: () => void; onErr?: (err: any | boolean) => void }
) {
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand("copy");
		if (successful && options?.onOk) {
			options.onOk();
		} else if (!successful && options?.onErr) {
			options.onErr(false);
		}
	} catch (err) {
		if (options?.onErr) {
			options.onErr(err);
		}
	}

	document.body.removeChild(textArea);
}

export async function copyTextToClipboard(
	text: string,
	options?: { onOk?: () => void; onErr?: (err: any | boolean) => void }
) {
	console.log(options);
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	await navigator.clipboard.writeText(text).then(
		function () {
			if (options?.onOk) {
				options.onOk();
			}
		},
		function (err) {
			if (options?.onErr) {
				options?.onErr(err);
			}
		}
	);
}

// Merge creates a new object with values of `a` and any key/values of `b` that
// `a` was missing
export function merge(a: Map<any>, b: Map<any>): Map<any> {
	const ka = Object.keys(a);
	const n = { ...b };
	for (let k in a) {
		const v = a[k];
		const opp = n[k];
		if (
			v !== null &&
			typeof v === "object" &&
			!Array.isArray(v) &&
			opp !== undefined
		) {
			const item = { ...opp };
			for (let lk in v) {
				const le = v[lk];
				item[lk] = le;
			}
			n[k] = item;
		} else {
			n[k] = v;
		}
	}
	return n;
}

export function dedup<T>(slice: T[]): T[] {
	if (slice.length < 2) {
		return slice;
	}

	const n: T[] = [slice[0]];
	for (let i = 1; i < slice.length; i++) {
		const e = slice[i];
		if (n.indexOf(e) === -1) {
			n.push(e);
		}
	}
	return n;
}

export function waitDeletion(
	docType: string,
	userId: string,
	id: string,
	onEnd: () => void
) {
	let to: Maybe<NodeJS.Timeout> = undefined;
	function clearAndMove() {
		if (to !== undefined) {
			clearInterval(to);
		}
		onEnd();
	}
	to = setInterval(async () => {
		const url = new URL(`${ROOT_API_URL}/api/v1/fetch/${docType}`);
		url.searchParams.append("id", id);
		const headers = withAuthorization(userId, newHeaders());
		const r = await fetch(url.href, { headers: headers });
		if (!r.ok) {
			clearAndMove();
		} else {
			const {
				data: { total },
			} = await r.json();
			if (total === 0) {
				clearAndMove();
			}
		}
	}, 500);
}

export function pushFront(key: string, slice: string[]): string[] {
	if (slice.length < 2) {
		return slice;
	}

	const index = slice.indexOf(key);
	if (index === -1) {
		return slice;
	}

	const o = slice.splice(index, 1);
	return [o[0], ...slice];
}

export function map<T, U>(m: Map<T>, f: (k: string, v: T) => U): U[] {
	return Object.keys(m).map(k => {
		const v = m[k];
		return f(k, v);
	})
}

export function reduce<T, U>(m: Map<T>, f: (k: string, v: T) => [U, boolean]): U[] {
	return Object.keys(m).reduce((acc, cur) => {
		const e = m[cur];
		const [v, ok] = f(cur, e);
		return ok ? [...acc, v] : acc;
	}, [] as U[]);
}

function inject(m: Map<any>, elem: any, k: string): Map<any> {
	const flt = flatten_(elem);
	if ("__value" in flt) {
		m[k] = flt.__value;
	} else {
		Object.keys(flt).forEach((key) => {
			const value = flt[key];
			m[`${k}.${key}`] = value;
		});
	}
	return m;
}

function flatten_(e: any): ({ __value: any } | Map<any>) {
	if (
		e === null || e === undefined || typeof e === "string" ||
		moment.isMoment(e) || Array.isArray(e)
	) {
		return ({ __value: e });
	}

	const keys = Object.keys(e);
	if (keys.length === 0) {
		return ({ __value: e });
	}

	return keys.reduce((acc, cur) => inject(acc, e[cur], cur), {} as Map<any>);
}

export function flatten(o: Map<any>): Map<any> {
	return Object
		.keys(o)
		.reduce(
			(acc, cur) => inject(acc, o[cur], cur),
			{} as Map<any>
		);
}
