// import { useState } from "react";

import { Button, Input, Modal, Row, Typography, Checkbox, Col } from "antd";

// import SelectNewsTank from "../tools/SelectNewsTank";

import "../../css/projects/list.css";

// const { Text } = Typography;

// const checkboxOptionsUse: string[] = ["Rubrique Data", "Article"];
// const checkboxOptionsState: string[] = ["Brouillon", "Publié"];

function ListHeader() {
	// const [nts, setNT] = useState<string[]>();
	// const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	// const showModal = () => {
	// 	setIsModalVisible(true);
	// };

	// #region Modal handlers
	// const handleOk = () => {
	// 	setIsModalVisible(false);
	// };

	// const handleCancel = () => {
	// 	setIsModalVisible(false);
	// };
	// #endregion

	return (
		<Row className="project-list-header" align="middle">
			{/* <Col flex="auto">
				<Input
					className="projects-input"
					placeholder="Rechercher un projet"
					addonAfter={<SelectNewsTank nts={nts} setNT={setNT} />}
				/>
				<Button type="link" onClick={showModal} style={{ marginLeft: 8 }}>
					Filtres avancés
				</Button>
				<Modal
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={[
						<Button key="back" onClick={handleCancel}>
							Annuler
						</Button>,
						<Button key="submit" type="primary" onClick={handleOk}>
							Appliquer
						</Button>,
					]}
				>
					<div className="filters-groups">
						<Text strong>Usage destiné</Text>
						<Checkbox.Group
							style={{
								marginLeft: 8,
								marginTop: 4,
								marginBottom: 16,
								width: "100%",
							}}
							options={checkboxOptionsUse}
							defaultValue={["Rubrique Data", "Article"]}
							onChange={() => console.log("ok")}
						/>
					</div>
					<div className="filters-groups">
						<Text strong>État d&apos;avancement</Text>
						<Checkbox.Group
							style={{
								marginLeft: 8,
								marginTop: 4,
								width: "100%",
							}}
							options={checkboxOptionsState}
							defaultValue={["Publié", "Brouillon"]}
							onChange={() => console.log("ok")}
						/>
					</div>
				</Modal>
			</Col> */}
			<Col>
				<Button type="primary" href="/visualisations/projects/new">
					Nouveau projet
				</Button>
			</Col>
		</Row>
	);
}

export default ListHeader;
