import { Row, Typography, Tooltip, Space, Button } from "antd";

import { EyeOutlined, SettingOutlined, EditOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface IndicatorActionsProps {
	indicatorId: string;
	lastUpdate: string;
}

function IndicatorActions(props: IndicatorActionsProps) {
	const { indicatorId, lastUpdate } = props;

	return (
		<Row align="middle" justify="end">
			{/* <Text type="secondary" style={{ fontSize: "80%" }}>
				{`Dernière mise à jour le ${lastUpdate} à ${heure} par ${auteur}.`}
			</Text> */}
			<Space>
				{/* <Tooltip placement="bottomRight" title="Aperçu rubrique Data">
					<Button
						className="discrete-link"
						type="link"
						size="small"
						href={`/visualisations/vue/preview/${indicatorId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<EyeOutlined />
					</Button>
				</Tooltip> */}
				<Tooltip placement="bottomRight" title="Éditer l'indicateur">
					<Button
						className="discrete-link"
						type="link"
						size="small"
						href={`/indicators/add/${indicatorId}`}
					>
						<EditOutlined />
					</Button>
				</Tooltip>
				<Tooltip placement="bottomRight" title="Administrer l'indicateur">
					<Button
						className="discrete-link"
						type="link"
						size="small"
						href={`/indicators/edit/${indicatorId}`}
					>
						<SettingOutlined />
					</Button>
				</Tooltip>
			</Space>
		</Row>
	);
}

export default IndicatorActions;
