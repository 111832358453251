import { CloseOutlined } from "@ant-design/icons";
import { Row, Button, Select, Space, Form } from "antd";
import { uniqueId } from "lodash";
import { dataTypeMasks } from ".";
import { Map } from "../../../../interfaces/Utils";
import { Clause, DataType } from "../../../../model/filters";

import { calcOperations } from "../FilterConditionSelector";
import FilterInput from "./FilterInput";

const { Option } = Select;

interface FilterOrProps {
	dataTypePairs: Map<DataType>;
	name: number | number[];
	disabled?: boolean;
	value?: Clause;
	add?: () => void;
	remove?: () => void;
}

function wrapName(
	name: number | number[]
): (k: string | number) => number | string | (string | number)[] {
	return function (k: string | number) {
		return Array.isArray(name) ? [...name, k] : [name, k];
	};
}

export function FilterOr(props: FilterOrProps) {
	const { dataTypePairs, name, value, add, remove, disabled } = props;
	const field = value?.field;
	const type_ = value?.field ? dataTypePairs[value.field] : undefined;
	const keys = Object.keys(dataTypePairs);
	const wrapper = wrapName(name);

	return (
		<Row justify="space-between" align="middle" style={{ marginBottom: 8 }}>
			<Space direction="horizontal">
				<Form.Item name={wrapper("include")}>
					<Select
						bordered={false}
						size="small"
						style={{ width: 100 }}
						placeholder="inclure ou exclure"
					>
						<Option value="include">Inclure</Option>
						<Option value="exclude">Exclure</Option>
					</Select>
				</Form.Item>
				<Form.Item name={wrapper("field")}>
					<Select
						bordered={false}
						size="small"
						style={{ width: 220 }}
						placeholder="Sélectionner un champ"
						allowClear
					>
						{keys.map((v) => {
							const masked = dataTypeMasks[v] ?? v;
							return (
								<Option key={uniqueId("option-for-filter-or-")} value={v}>
									{masked}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				{type_ ? (
					<Form.Item name={wrapper("op")}>
						<Select
							bordered={false}
							size="small"
							style={{ width: 220 }}
							placeholder="Sélectionner une condition"
						>
							{calcOperations(type_)?.map((e, i) => (
								<Option value={e.value} key={i}>
									{e.label}
								</Option>
							)) ?? "Aucun opérateur disponible pour cette colonne"}
						</Select>
					</Form.Item>
				) : null}
				{type_ && value?.op ? (
					<Form.Item name={wrapper("value")}>
						<FilterInput
							dataType={type_}
							op={value.op}
							field={field}
						/>
					</Form.Item>
				) : null}
			</Space>
			<span>
				<Button
					type="link"
					size="small"
					disabled={disabled}
					onClick={() => add?.()}
				>
					OU
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => remove?.()}
					icon={<CloseOutlined />}
				/>
			</span>
		</Row>
	);
}
