import { Row } from "antd";
import { capitalize } from "lodash";
import { getLocalisedName } from "../titleFormatter";
import { Column, Related, Relation } from "./interface";

export function formatRelation(rel: Relation): string {
	const s = rel.direction === "from" ? "->" : "<-";
	return `${capitalize(rel.type)} (${s})`
}

const titleCol: Column = {
	key: "title",
	title: "Titre",
	dataIndex: "title",
};

const labelCol: Column = {
	key: "label",
	title: "Label",
	dataIndex: "type",
	render: (v: string) => getLocalisedName("fr", v),
};

const relationCol: Column = {
	key: "relations",
	title: "Relation(s)",
	render: (_: any, n: Related) => {
		if (n.relations.length === 0) {
			return "-";
		} else if (n.relations.length === 1) {
			return formatRelation(n.relations[0])
		} else {
			const rows = n.relations.map(r => <Row>{formatRelation(r)} </Row>)
			return (<>{rows}</>);
		}
	},
};

export {
	titleCol,
	labelCol,
	relationCol,
};