import explorer from "../../../../lib/explorer";
import { ColumnProps } from "../columns";

// const colorVar = "#9254de";

const linkInternalHandler = (props: ColumnProps, i: number) => {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}
	const rem = k.slice(1);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		editable: true,
		key: i,
		// width: renderArg?.width ?? "",
		width: "",
		// sorter: sortHandler(dataIndex),
		render: (value: any) => {
			const name = k.length > 1 ? explorer(rem, value) : value;
			if (renderArg !== undefined && "to" in renderArg) {
				// const to = renderArg["to"];
				if (name === "") {
					return "";
				}
				return name;
				//! TODO: Render it with a <a href> instead of a Link, which causes error outside of the router as it causes the widget to crash
				// return <Link
				// 	style={{ color: colorVar }}
				// 	key={`link-${name}`}
				// 	to={`${to}${name}`}
				// >
				// 	{name}
				// </Link>
			}
			return name;
		},
	};
};

export default linkInternalHandler;
