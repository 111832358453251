import ReactDOM from "react-dom";
import { StrictMode } from "react";

import "./css/root.scss";

import App from "./App";
import inject, { isWidget, testWidget, renderWidget, widgetId, tokenId } from "./components/Widget/widgetInjection";

function renderApp() {
	const root = document.getElementById("gedata-root");

	if (!root) {
		console.error("no root found for React!");
		return;
	}

	ReactDOM.render(
		<StrictMode>
			<App />
		</StrictMode>,
		root
	);
}

if (isWidget) {
	inject();
} else if (testWidget) {
	renderWidget();
} else {
	renderApp();
}
