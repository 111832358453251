import { Button } from "antd";
import { useDispatch } from "react-redux";
import { CloseAction } from "../../../../store/sidebar";

interface CloseButtonProps {
	onOk?: () => void,
}

//! TODO: Could be moved elsewhere and parameterised as necessary as it is just
// a generalised button to close the sidebar calling the general store
export default function CloseButton(props: CloseButtonProps) {
	const { onOk } = props;
	const dispatch = useDispatch();
	function onClick() {
		dispatch(CloseAction)
		onOk?.();
	}

	return (
		<Button
			type="primary"
			onClick={onClick}
		>
			Fermer
		</Button>
	);
}
