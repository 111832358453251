import { Map, Maybe, RSetter } from "../../../../interfaces/Utils";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../../lib/fetch";
import { AggregationResponse, NodeFetch } from "../../interface";

export interface AggsCountResponse {
	took: number;
	data: Map<string>;
}

interface TrlFetch {
	searchParams: Map<string[]>;
	labels: string[];
	fetched: NodeFetch;
	setFetched: RSetter<NodeFetch>;
	userId?: string;
}

export function nodefetcher(
	labels: string[],
	fetched: NodeFetch,
	setFetched: RSetter<NodeFetch>,
	userId: string,
	size?: number,
	page?: number,
	searchParams?: Map<any[]>
) {
	const { src } = fetched;

	const url = new URL(`${ROOT_API_URL}/neo/index`);
	if (size) {
		url.searchParams.append("size", size.toString(10));
	} else if (fetched?.size && fetched.size !== 20) {
		url.searchParams.append("size", fetched.size.toString(10));
	} else {
		url.searchParams.append("size", "20");
	}
	if (page) {
		url.searchParams.append("page", (page - 1).toString(10));
	} else if (fetched?.page && fetched.page !== 1) {
		url.searchParams.append("page", fetched.page.toString(10));
	}
	if (fetched.sortCount !== undefined) {
		url.searchParams.append("_sort_count", `${fetched.sortCount}`);
	} else if (fetched.sort) {
		url.searchParams.append("sort_key", fetched.sort.key);
		url.searchParams.append("sort_asc", (fetched.sort.asc ?? true).toString());
	} else if (labels.indexOf("Sector") !== -1) {
		url.searchParams.append("sort_key", "name_FR");
		url.searchParams.append("sort_asc", "true");
	}

	labels
		.filter((l) => !l.startsWith("IDX_"))
		.forEach((l) => url.searchParams.append("label", l));

	Object.keys(src).forEach((k) => {
		if (k === "_all") {
			return;
		}
		url.searchParams.append("key", k);
		src[k].forEach((e) => {
			url.searchParams.append("value", e);
		});
	});

	src._all?.forEach((e) => url.searchParams.append(`params[_all]`, e));

	if (searchParams) {
		Object.keys(searchParams).forEach((k) => {
			searchParams[k].forEach((e) => {
				url.searchParams.append(k, e);
			});
		});
	}
	const headers = withAuthorization(userId, newHeaders());
	fetch(url.href, { headers: headers }).then(async (r0) => {
		if (!r0.ok) {
			const message = `#${r0.status}: ${r0.statusText}`;
			console.error(message);
			return;
		}
		const {
			data: { values, total },
		}: {
			data: {
				values: Map<any>[];
				total: number;
			};
		} = await r0.json();
		const newFetched = {
			...fetched,
			values: values,
			count: total,
			page: page ?? fetched.page,
			size: size ?? fetched.size,
		};
		setFetched(newFetched);
	});
}

function trlfetch(props: TrlFetch): (page?: number, size?: number) => void {
	const { searchParams, labels, fetched, setFetched, userId } = props;
	return (page?: number, size?: number) => {
		if (size && size !== fetched.size) {
			// Nothing to do here
			return;
		}
		if (!userId) {
			// TODO: better error handling
			return;
		}
		nodefetcher(labels, fetched, setFetched, userId, size, page, searchParams);
	};
}

export function fetchIdxData(
	userId: Maybe<string>,
	label: Maybe<string>,
	onResp: (r: Response) => void | Promise<void>,
) {
	if (!userId || !label) {
		return;
	} // should use onError to send error message
	const headers = withAuthorization(userId, newHeaders());
	const url = new URL(`${ROOT_API_URL}/neo/clean/spec`);
	url.searchParams.append("label", label);
	fetch(url.href, { headers: headers }).then(onResp);
}

export function updateDocument(
	userId: string,
	uid: string,
	labels: string[],
	props: Map<any>,
	onOk: () => void,
	onErr?: (code: number, body: ReadableStream<Uint8Array> | null) => void
) {
	const url = new URL(`${ROOT_API_URL}/neo/index`);
	const headers = withAuthorization(userId, newHeaders());
	const label = labels.join("__");
	const body = JSON.stringify({ uid: uid, label: label, props: props });
	fetch(url.href, { headers: headers, method: "PUT", body: body }).then((r) => {
		if (!r.ok) {
			// TODO: write better error handling
			const message = `${r.status} - ${r.statusText}`;
			console.error(message);
			if (onErr) onErr(r.status, r.body);
			return;
		}
		onOk();
	});
}

export async function fetchSuggs(
	userId: string,
	key: string,
	label: string,
	s: string
): Promise<AggregationResponse> {
	const url = new URL(`${ROOT_API_URL}/neo/index/sugg`);
	url.searchParams.append("label", label);
	url.searchParams.append("key", key);
	url.searchParams.append("value", s);
	const headers = withAuthorization(userId, newHeaders());
	return fetch(url.href, { headers: headers }).then(async (r) => {
		const v: AggregationResponse = await r.json();
		return v;
	});
}

export default trlfetch;
