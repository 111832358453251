import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteComponentProps, useHistory } from "react-router";

import Layout from '../Layout';
import { LoginState, MinimalLoginState } from "../../store/login/interface";

// #region Store connection
type StateProps = LoginState;
function mapStateToProps({ login }: MinimalLoginState): StateProps {
	return login;
}
const connector = connect(mapStateToProps);
// #endregion

// type AuthRouteProps = ConnectedProps<typeof connector>;
interface AuthRouteProps extends ConnectedProps<typeof connector> {
	component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
	errorElement?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
	path?: string,
	redirection?: string,
	exact?: boolean,
	strict?: boolean,
	withLayout?: boolean,
}

function AuthRoute(props: AuthRouteProps) {
	const { userId, path, component, redirection, withLayout, } = props;
	const h = useHistory();

	if (!userId) {
		return (<Redirect to={{
			pathname: redirection ?? "/login",
			state: {
				from: h.location.pathname,
			},
		}} />);
	}

	const c = (<Route path={path} component={component} exact strict />);
	if (!!withLayout)
		return (<Layout>{c}</Layout>)
	return c;
}

export default connector(AuthRoute);
