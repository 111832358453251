import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Map } from "../../../interfaces/Utils";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../lib/fetch";
import { LoginState } from "../../../store/login/interface";
import { CloseAction, SidebarState } from "../../../store/sidebar";
import { IndicatorSpec, NodeFetch, Node } from "../interface";
import { defaultNodeFetch } from "./LabelPage";

export interface CurrentStore {
	login: LoginState;
	sidebar: SidebarState;
}

export type EditStore = NodeFetch & {
	loading?: boolean;
	spec?: IndicatorSpec;
	selectedNodes: Node[];
	fetched: NodeFetch;
};

export type CurrentEditStore = CurrentStore & {
	edit: EditStore;
	login: LoginState;
};

interface EditPayload {
	loading?: boolean;
	spec?: IndicatorSpec;
	selectedNodes?: Node[];
	fetched?: NodeFetch;
}

type ThunkEditAction = ThunkAction<void, CurrentEditStore, null, Action<any>>;

export const defaultEditStore: EditStore = initialState();

export const EDIT_SET_LOADING = "EDIT_SET_LOADING";
export const EDIT_SET_SPEC = "EDIT_SET_SPEC";
export const EDIT_SET_SELNODES = "EDIT_SET_SELNODES";
export const EDIT_SET_FETCHED = "EDIT_SET_FETCHED";
export const EDIT_SET_FETCHED_AND_SEL = "EDIT_SET_FETCHED_AND_SEL";

export type EditActions =
	| typeof EDIT_SET_LOADING
	| typeof EDIT_SET_SPEC
	| typeof EDIT_SET_SELNODES
	| typeof EDIT_SET_FETCHED
	| typeof EDIT_SET_FETCHED_AND_SEL;

export interface EditAction {
	type: EditActions;
	payload?: EditPayload;
}

export function loadingAction(v: boolean): EditAction {
	return {
		type: EDIT_SET_LOADING,
		payload: {
			loading: v,
		},
	};
}

export function specAction(spec: IndicatorSpec): EditAction {
	return {
		type: EDIT_SET_SPEC,
		payload: {
			spec: spec,
		},
	};
}

export function selectedNodesAction(nodes: Node[]): EditAction {
	return {
		type: EDIT_SET_SELNODES,
		payload: {
			selectedNodes: nodes,
		},
	};
}

export function fetchedAction(fetched: NodeFetch): EditAction {
	return {
		type: EDIT_SET_FETCHED,
		payload: {
			fetched: fetched,
			loading: false,
		},
	};
}

export function fetchedAndSelAction(fetched: NodeFetch, sel?: Node[]): EditAction {
	return {
		type: EDIT_SET_FETCHED_AND_SEL,
		payload: {
			fetched: fetched,
			loading: false,
			selectedNodes: sel ?? [],
		},
	};
}

function initialState(): EditStore {
	return {
		src: {},
		values: [],
		selectedNodes: [],
		fetched: defaultNodeFetch,
	};
}

export function ThunkFetchNode(
	labels: string[],
	page?: number,
	size?: number
): ThunkEditAction {
	return async function (dispatch, getState) {
		const {
			login: { userId },
			edit: { fetched },
		} = getState();
		if (!userId) {
			// !TODO: alert user id error
			dispatch(loadingAction(false));
			return;
		}

		dispatch(loadingAction(true));
		const { src } = fetched;
		if (!userId) return;

		const url = new URL(`${ROOT_API_URL}/neo/index`);
		if (size) {
			url.searchParams.append("size", size.toString(10));
		} else if (fetched?.size && fetched.size !== 20) {
			url.searchParams.append("size", fetched.size.toString(10));
		} else {
			url.searchParams.append("size", "20");
		}
		if (page) {
			url.searchParams.append("page", (page - 1).toString(10));
		} else if (fetched?.page && fetched.page !== 1) {
			url.searchParams.append("page", fetched.page.toString(10));
		}
		if (fetched.sort) {
			url.searchParams.append("sort_key", fetched.sort.key);
			url.searchParams.append("sort_asc", (fetched.sort.asc ?? true).toString());
		} else if (labels.indexOf("Sector") !== -1) {
			url.searchParams.append("sort_key", "name_FR");
			url.searchParams.append("sort_asc", "true");
		}

		labels
			.filter((l) => !l.startsWith("IDX_"))
			.forEach((l) => url.searchParams.append("label", l));

		Object.keys(src).forEach((k) => {
			url.searchParams.append("key", k);
			src[k].forEach((e) => {
				url.searchParams.append("value", e);
			});
		});

		const headers = withAuthorization(userId, newHeaders());
		fetch(url.href, { headers: headers }).then(async (r0) => {
			if (!r0.ok) {
				const message = `#${r0.status}: ${r0.statusText}`;
				console.error(message);
				console.log(message);
				return;
			}
			const {
				data: { values, total },
			}: {
				data: {
					values: Map<any>[];
					total: number;
				};
			} = await r0.json();
			const newFetched = {
				...fetched,
				values: values,
				count: total,
				page: page ?? fetched.page,
				size: size ?? fetched.size,
			};
			dispatch(fetchedAction(newFetched));
		});
	};
}

export function ThunkReindex(labels: string[]): ThunkEditAction {
	return async function (dispatch, getState) {
		dispatch(loadingAction(true));
		const {
			login: { userId },
		} = getState();
		if (!userId) {
			// TODO: signal user ID is invalid
			console.log("no user id");
			return;
		}

		const url = new URL(`${ROOT_API_URL}/neo/index`);
		const headers = withAuthorization(userId, newHeaders());
		const filteredLabels = labels.filter((l) => !l.match(/^IDX_/));
		if (!filteredLabels || filteredLabels.length < 1) {
			// TODO: alert user somehow
			return;
		}
		const label = filteredLabels[0];
		const body = JSON.stringify({ label: label });
		fetch(url.href, { method: "POST", headers: headers, body: body }).then((r) => {
			if (!r.ok) {
				// ALERT USER STH WENT WRONG
				const message = `COULD NOT RE-INDEX: ${r.status} - ${r.statusText}`;
				console.error(message);
				console.log(message);
				return;
			}
			setTimeout(() => dispatch(ThunkFetchNode(labels)), 2000);
		});
	};
}

export function ThunkMerge(
	nodes: Node[],
	ref: React.MutableRefObject<Map<() => string>>,
	labels: string[],
	userId?: string
): ThunkEditAction {
	return async function (dispatch) {
		// no payload, nothing to do.
		if (nodes.length < 1) {
			dispatch(CloseAction);
			return;
		}

		if (!userId) {
			// TODO: invalid user ID, fix it
			return;
		}

		dispatch(loadingAction(true));
		const props = Object.keys(ref.current).reduce((acc, k) => {
			const f = ref.current[k];
			const v = f();
			return {
				...acc,
				[k]: v,
			};
		}, {});

		const payload: {
			uids: string[];
			props: Map<any>;
			labels: string[];
			indicator: string;
		} = {
			uids: nodes.map((n) => n.attributes.uid),
			props: props,
			labels: nodes[0].labels,
			indicator: nodes[0].labels.find((l) => l.startsWith("IDX_")) ?? "",
		};
		const url = new URL(`${ROOT_API_URL}/neo/node`);
		const headers = withAuthorization(userId || "", newHeaders());
		const body = JSON.stringify(payload);

		fetch(url.href, { headers: headers, method: "POST", body: body }).then(
			async (r) => {
				if (!r.ok) {
					// TODO: invalid
					console.error(`COULD NOT MERGE: ${r.status} - ${r.statusText}`);
				} else {
					dispatch(CloseAction);
					dispatch(selectedNodesAction([]));
					dispatch(ThunkReindex(labels));
				}
			}
		);
	};
}

export function editReducer(state: EditStore, action: EditAction) {
	if (state === undefined) {
		state = initialState();
	}

	if (action === undefined) {
		return state;
	}

	const { type } = action;
	switch (type) {
		case EDIT_SET_LOADING: {
			const loading = action.payload?.loading;
			if (loading === undefined) {
				return state;
			}
			return {
				...state,
				loading: loading,
			};
		}
		case EDIT_SET_SPEC: {
			const spec = action.payload?.spec;
			if (!spec) {
				return state;
			}
			return {
				...state,
				spec: spec,
			};
		}
		case EDIT_SET_SELNODES: {
			const selnodes = action.payload?.selectedNodes;
			if (!selnodes) {
				return state;
			}
			return {
				...state,
				selectedNodes: selnodes,
			};
		}
		case EDIT_SET_FETCHED: {
			const fetched = action.payload?.fetched;
			const loading = action.payload?.loading;
			if (!fetched) {
				return state;
			}
			return {
				...state,
				fetched: fetched,
				loading: loading ?? state.loading,
			};
		}
		case EDIT_SET_FETCHED_AND_SEL: {
			const fetched = action.payload?.fetched;
			const loading = action.payload?.loading;
			const selectedNodes = action.payload?.selectedNodes ?? [];
			if (!fetched || !selectedNodes) {
				return state;
			}
			return {
				...state,
				fetched: fetched,
				selectedNodes: selectedNodes,
				loading: loading ?? state.loading,
			};
		}
		default:
			return state;
	}
}

export default {};
