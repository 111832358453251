import {
	FilterValue,
	SorterResult,
	TableCurrentDataSource,
	TablePaginationConfig,
} from "antd/lib/table/interface";

import { Map, RSetter } from "../../../interfaces/Utils";
import Search, { newSorting } from "../../../model/search";
import { sortingLens } from "../lenses";
import { OnChangeEvents } from "./interface";

function genOnChange(search: Search, updateSearch: RSetter<Search>) {
	const onChangeEvents: OnChangeEvents = {
		sort: function sort(
			_pagination: TablePaginationConfig,
			_filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Map<any>> | SorterResult<Map<any>>[],
		) {
			if ("length" in sorter) {
				return;
			}

			const { order } = sorter;
			const key = (sorter.column ?? ({} as Map<any>))["_dataIndex"];
			const newSort = newSorting(key, order);
			updateSearch(sortingLens.set(search, newSort));
		},
	};

	return function onChange(
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Map<any>> | SorterResult<Map<any>>[],
		extra: TableCurrentDataSource<Map<any>>
	) {
		const { action } = extra;
		const handler = onChangeEvents[action];
		if (handler) {
			handler(pagination, filters, sorter, extra);
		}
	};
}

export default genOnChange;
