import { Select } from "antd";
import { useState } from "react";

import _uniqueId from "lodash/uniqueId";
import { Map } from "../../../../interfaces/Utils";

interface RelatedNode {
	Labels: string[];
	Props: { uid: string } & Map<any>;
}
interface Relation {
	Type: string;
	Props: Map<any>;
}

interface CompleteRelation {
	Node: RelatedNode;
	Rel: Relation;
}

type RelList = CompleteRelation[];

export function renderSponsor(rec: {
	name: string;
	uid: string;
	_from: RelList;
	_to: RelList;
}) {
	const [state, setState] = useState({
		buf: "",
		results: [] as any[],
	});
	const options: { label: string; value: string; key: string }[] = [];
	const values: string[] = [];
	const doublons: number[] = [];
	if (rec._from) {
		for (let i = 0; i < rec._from.length; i++) {
			const rel = rec._from[i];
			if (rel.Rel.Type !== "WORKS_IN" || doublons[rel.Node.Props.uid]) {
				continue;
			}
			doublons[rel.Node.Props.uid] = true;
			options.push({
				label: rel.Node.Props.name_FR,
				value: rel.Node.Props.uid,
				key: _uniqueId("option-"),
			});
			values.push(rel.Node.Props.uid);
		}
	}

	return (
		<Select
			style={{ width: "100%" }}
			mode="multiple"
			value={values}
			options={options}
			onSearch={function onSearch(value: string) {
				console.log(value);
			}}
			// onSelect={function(value: string, option: any) {
			//     console.log(value, option);
			// }}
			onSelect={function () {
				console.log(...(arguments as any as any[]));
			}}
		/>
	);
}

export default {
	renderSponsor: renderSponsor,
};
