import explorer from "../../../../lib/explorer";
import { ColumnProps } from "../columns";

const variationHandler = (props: ColumnProps, i: number) => {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}
	const rem = k.slice(1,);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex, _type: type, _data_type: data_type,
		editable: true,
		key: i,
		align: "center",
		// width: renderArg?.width ?? "",
		width: "",
		render: (value: any, record: any) => {
			const data = k.length > 1 ? explorer(rem, value) : value;
			let first;
			let second;
			if (renderArg?.keys && renderArg.keys.beg && renderArg.keys.end) {
				const firstKey = renderArg.keys.beg;
				const secondKey = renderArg.keys.end;
				first = record[firstKey];
				second = record[secondKey];
			}
			if (!first && !second) {
				first = explorer(renderArg.dataIndex, data[renderArg?.values?.first]);
				second = explorer(renderArg.dataIndex, data[renderArg?.values?.second]);
			}
			const errValue = renderArg?.errValue;
			if (errValue === undefined) {
				return <span style={{ color: "red" }}>ERROR</span>;
			}
			if (
				first === undefined ||
				first === errValue ||
				second === undefined ||
				second === errValue
			) {
				return "missing";
			}
			let result: number;
			switch (renderArg?.operation) {
				case "ratio":
					result = (second / first) * 100 - 100;
					break;
				case "sub":
				default:
					result = second - first;
			}

			const threshold = renderArg?.threshold !== undefined ? renderArg.threshold : 0;
			const colorPlus = renderArg?.colorPlus ?? "#52c41a";
			const colorMinus = renderArg?.colorMinus ?? "#f5222d";
			const nprec = renderArg?.precision ?? 0;
			const prec = 10 ** nprec;
			const res = ((nprec > 0) ? Math.round(result * prec) / prec : Math.round(result));
			const resSuffix = res + ((renderArg?.suffix !== undefined) ? ' ' + renderArg.suffix : '');
			const resString = (res > 0 && renderArg?.showSign) ? '+' + resSuffix : resSuffix;
			return (
				<span
					style={{
						color: res > threshold ? colorPlus : colorMinus,
						fontWeight: "bold",
					}}
				>
					{resString}
				</span>
			);
		},
	};
};

export default variationHandler;
