import { Row, Col, Typography, } from 'antd';

import "../../css/tools/UsageStats.css";
import { inBetween } from '../../lib/array';
import { parseAndFormatDate } from '../Projects/tools';

const { Text } = Typography;

interface UsageStatsProps {
	created: string;
	authors: string[];
}

function UsageStats(props: UsageStatsProps) {
	const { created, authors } = props;
	return (
		<div className="wrapper">
			<Row className="wrapper">
				<div className="date-displayers">
					<div>
						<Row style={{ marginBottom: 4, }}>
							<Col
								flex="80px"
							>
								<Text type="secondary">Créé le</Text>
							</Col>
							<Col
								flex="calc(50% - 80px)"
								style={{ display: "flex", justifyContent: "start" }}
							>
								<Text>{parseAndFormatDate(created)}</Text>
							</Col>
						</Row>
						<Row>
							<Col
								flex="80px"
							>
								<Text type="secondary">Par</Text>
							</Col><Col
								flex="calc(50% - 80px)"
								style={{ display: "flex", justifyContent: "start" }}
							>
								<Text>{inBetween(authors, " - ")}</Text> 
							</Col>
						</Row>
					</div>
				</div>
				{/* REACTIVATE */}
				{/* <div className="date-displayers">
					<div>
						<Row style={{ marginBottom: 4, }}>
							<Col
								flex="80px"
							>
								<Text type="secondary">Mis à jour le</Text>
							</Col>
							<Col
								flex="calc(50% - 80px)"
								style={{ marginLeft: 8, display: "flex", justifyContent: "start" }}
							>
								<Text>2 mars 2022</Text>
							</Col>
						</Row>
						<Row>
							<Col
								flex="80px"
							>
								<Text type="secondary">Par</Text>
							</Col>
							<Col
								flex="calc(50% - 80px)"
								style={{ marginLeft: 8, display: "flex", justifyContent: "start" }}
							>
								<Text>Clémence BERGON</Text>
							</Col>
						</Row>
					</div>
				</div> */}
			</Row>
		</div>
	)
};

export default UsageStats;