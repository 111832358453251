import _ from "lodash";

import { Button, Form, FormInstance, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { Clause, DataType, unsetClause } from "../../../../model/filters";
import { Map, Maybe } from "../../../../interfaces/Utils";
import { FilterOr } from "./FilterOr";
import { FieldData } from "rc-field-form/lib/interface";

interface FilterOrListerProps {
	childKey: number;
	name: number;
	dataTypePairs: Map<DataType>;
	remove: () => void;
}

function FilterOrLister(props: FilterOrListerProps) {
	const { childKey: key, name, dataTypePairs, remove } = props;
	return (
		<Space
			key={key}
			style={{
				backgroundColor: "#f9f0ff",
				width: 1050,
				// display: "flex",
				padding: "10px",
				borderRadius: "10px",
			}}
		>
			<Form.List name={name}>
				{(innerfields, { add: innerAdd, remove: innerRemove }) => (
					<>
						{innerfields.map(({ key, name: innerName }, i, slice) => (
							<Form.Item name={innerName} key={key}>
								<FilterOr
									dataTypePairs={dataTypePairs}
									name={innerName}
									remove={() => innerRemove(innerName)}
									add={() => innerAdd(unsetClause)}
									disabled={i !== slice.length - 1}
								/>
							</Form.Item>
						))}
						<Space>
							{/* <Form.Item>
								<Button
									className="cross-button"
									type="link"
									onClick={() => innerAdd(unsetClause)}
									block
								>
									OU
								</Button>
							</Form.Item> */}
							<Form.Item>
								<Button
									shape="circle"
									type="primary"
									size="small"
									onClick={remove}
									icon={<DeleteOutlined />}
								/>
							</Form.Item>
						</Space>
					</>
				)}
			</Form.List>
		</Space>
	);
}

interface FilterAndListerProps {
	dataTypePairs: Map<DataType>;
}

function FilterAndLister(props: FilterAndListerProps) {
	const { dataTypePairs } = props;
	return (
		<Form.List name="ands">
			{(fields, { add, remove }) => (
				<Space direction="vertical">
					{fields.map(({ key, name }) => (
						<FilterOrLister
							childKey={key}
							name={name}
							dataTypePairs={dataTypePairs}
							remove={() => remove(name)}
						/>
					))}
					<Button
						className="cross-button"
						shape="circle"
						type="link"
						size="small"
						onClick={() => add([unsetClause])}
					>
						ET
					</Button>
				</Space>
			)}
		</Form.List>
	);
}

type andsPath = [string, number, number, string];

function deepCopy(oldValues: { ands: Clause[][]; }): { ands: Clause[][]; } {
	const { ands } = oldValues;
	return { ands: ands.map(c => c.map(cl => ({ ...cl }))), };
}

function onFieldChangeCurried(dataTypePairs: Map<DataType>, form: FormInstance<{ ands: Clause[][]; }>) {
	return function (val: FieldData) {
		const { name, value } = val;
		const path = (name as andsPath);
		const [_, inner, outer, field] = path;
		if (field === undefined) {
			return;
		}
		switch (field) {
			case "field": {
				const type = dataTypePairs[value];
				const oldValues = form.getFieldsValue();
				const old = oldValues["ands"][inner][outer];
				const newClause = {
					...old,
					field: value,
					type: type,
					op: undefined,
					value: undefined,
				};
				const newValues = deepCopy(oldValues);
				newValues["ands"][inner][outer] = newClause;
				form.setFieldsValue(newValues);
				break;
			}
			case "op": {
				const oldValues = form.getFieldsValue();
				const old = oldValues["ands"][inner][outer];
				const newClause = {
					...old,
					op: value,
					value: undefined,
				};
				const newValues = deepCopy(oldValues);
				newValues["ands"][inner][outer] = newClause;
				form.setFieldsValue(newValues);
				break;
			}
		}
	};
}

interface FilterWrapperProps {
	dataTypePairs: Map<DataType>;
	values?: Clause[][];
	closeRef?: React.MutableRefObject<(cls: Maybe<Clause[][]>) => void>;
	form: FormInstance<{ ands: Clause[][] }>;
}

function FilterWrapper(props: FilterWrapperProps) {
	const { dataTypePairs, values, form, closeRef } = props;

	const initialValues = {
		ands:
			values && values.length > 0
				? values.map((inner) =>
					inner.map((c) => ({
						...c,
						include: c.include ? "include" : "exclude",
					}))
				)
				: [[unsetClause]],
	};

	const onFieldChange = onFieldChangeCurried(dataTypePairs, form);
	return (
		<Form
			initialValues={initialValues}
			name={_.uniqueId("and-")}
			form={form}
			onFinish={(v) => {
				const clauses = v?.ands?.map((cls) =>
					cls?.map((cl) => ({
						...cl,
						include:
							typeof cl.include === "string"
								? cl.include !== "exclude"
								: cl.include,
					}))
				);
				closeRef?.current?.(clauses);
			}}
			layout="inline"
			onFieldsChange={(changed) => changed.forEach(onFieldChange)}
		>
			<FilterAndLister dataTypePairs={dataTypePairs} />
		</Form>
	);
}

export default FilterWrapper;
