import { Card, Typography, Input, Space } from "antd";
// import TextArea from "antd/lib/input/TextArea";
import {
	TableOutlined,
	BarChartOutlined,
	DotChartOutlined,
	PieChartOutlined,
	LineChartOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";

import { Map } from "../../../interfaces/Utils";
import TagViewGenerator from "../../tools/TagViewGenerator";
import { EditorialDates } from "../../../interfaces/editorial";
import { dateLine } from "../../Projects/Display";

import { dataTypeMasks } from "../StepCreate/Filters";

import { getConditionType } from "../StepCreate/conditionTypes";

import { Clause } from "../../../model/filters";

const { Meta } = Card;
const { Text } = Typography;

const defaultIcon = <QuestionCircleOutlined />;
const icons: Map<JSX.Element> = {
	table: <TableOutlined className="HomeInfographics-avatar" />,
	"pie-chart": <PieChartOutlined className="HomeInfographics-avatar" />,
	"dot-chart": <DotChartOutlined className="HomeInfographics-avatar" />,
	"bar-chart": <BarChartOutlined className="HomeInfographics-avatar" />,
	"line-chart": <LineChartOutlined className="HomeInfographics-avatar" />,
};
const viewType: Map<string> = {
	table: "Tableau",
	"pie-chart": "Pie Chart",
	"dot-chart": "Dot Chart",
	"bar-chart": "Bar Chart",
	"line-chart": "Line Chart",
};

function DateInfo(props: { dates?: EditorialDates; author?: string; style?: any }) {
	const { dates, author, style } = props;
	if (!dates || !author) {
		return null;
	}

	const rstyle = {
		...(style ?? {}),
		fontSize: "80%",
	};

	return (
		<div>
			<Text type="secondary" style={rstyle}>
				{dateLine(dates, [author], "column")}
			</Text>
		</div>
	);
}

function getIcon(type: string): JSX.Element {
	return icons[type] ?? defaultIcon;
}

const renderConditions = (bloc: Array<Clause>) => (
	<Space
		style={{
			backgroundColor: "#f9f0ff",
			// width: 1050,
			display: "flex",
			flexDirection: "column",
			padding: "10px",
			borderRadius: "10px",
		}}
	>
		{bloc.map((e: Filter, j: number) => (
			<>
				<Input.Group compact>
					<Input
						style={{ width: "30%", color: "rgba(0, 0, 0, 0.85)" }}
						value={dataTypeMasks[e.field]}
						disabled
					/>
					<Input
						style={{ width: "30%", color: "rgba(0, 0, 0, 0.85)" }}
						value={
							getConditionType(e.type)
								?.filter((c) => c.value === e.op)
								?.shift()?.label
						}
						disabled
					/>
					{/* <TextArea
                        autoSize */}
					<Input
						style={{ width: "40%", color: "rgba(0, 0, 0, 0.85)" }}
						value={
							e.field.includes(".date_")
								? new Date(e.value).toLocaleDateString()
								: e.value
						}
						disabled
					/>
				</Input.Group>
				{bloc.length > j + 1 ? <div>OU</div> : null}
			</>
		))}
	</Space>
);

export const renderFilters = (filters: Array<Array<Clause>> | undefined) =>
	filters && filters.length > 0 ? (
		<>
			<div
				style={{
					fontWeight: "bold",
					marginTop: "5px",
					marginBottom: "auto",
				}}
			>
				Filtre(s) actif(s) :
			</div>
			{filters.map((bloc, i) => (
				<Space style={{ display: "flex", flexDirection: "column" }}>
					{renderConditions(bloc)}
					{filters && filters.length > i + 1 ? <div>ET</div> : null}
				</Space>
			))}
		</>
	) : (
		<div
			style={{
				fontWeight: "bold",
				marginTop: "5px",
				marginBottom: "auto",
			}}
		>
			Filtre actif : Aucun
		</div>
	);

interface MiniPreviewProps {
	id: string;
	title: string;
	description?: string;
	indicator: string;
	nt: string;
	type: string;
	isStatic?: boolean;
	isDefault?: boolean;
	selected?: string;
	setSelected?: (id: string) => void;
	dates?: EditorialDates;
	author?: string;
	actions?: JSX.Element[];
	filters?: Array<Array<Clause>>;
}

function MiniPreview(props: MiniPreviewProps) {
	const {
		title,
		description,
		type,
		isStatic,
		isDefault,
		id,
		selected,
		setSelected,
		dates,
		author,
		actions,
		filters,
	} = props;
	const isSelected = id === selected;
	const actionsFinal = [
		<DateInfo dates={dates} author={author} style={{ width: "420px" }} />,
		<div />,
		...actions,
	];

	return (
		<Card
			className="MiniPreview-card"
			size="small"
			hoverable={setSelected !== undefined}
			onClick={setSelected ? () => setSelected(id) : undefined}
			bodyStyle={{
				height: "100%",
			}}
			style={{
				border: isSelected ? "3px solid #d3adf7" : "",
				minWidth: 500,
				width: "calc(50% - 8px)",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
			cover={
				<span style={{ position: "relative", minWidth: 500 }}>
					<span style={{ position: "absolute", right: 12, top: 12 }}>
						{isDefault === true ? (
							<TagViewGenerator type="default" />
						) : undefined}
						<TagViewGenerator
							type={isStatic === true ? "static" : "dynamic"}
						/>
					</span>
					{/* <PreviewCarousel arrow={false} height={150} width={300} /> */}
				</span>
			}
			// actions={actionsFinal}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					height: "100%",
				}}
			>
				<Meta
					avatar={getIcon(type)}
					title={title || "Titre manquant"}
					description={description || "Description manquante."}
				/>
				{renderFilters(filters)}
				<div
					style={{
						display: "flex",
						borderTop: "1px solid #f0f0f0",
						justifyContent: "space-between",
						alignItems: "center",
						paddingTop: "10px",
						marginTop: "10px",
					}}
				>
					{actionsFinal.map((action) => action)}
				</div>
			</div>
		</Card>
	);
}

export default MiniPreview;
