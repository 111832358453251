import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";

import { Avatar, Menu } from "antd";

import "../../css/layout/UserMenu.css";
import { LoginState, LOGOUT } from "../../store/login/interface";

const { SubMenu } = Menu;

const colorVar = "#faad14";
const lighterColorVar = "#fffbe6";

// #region connecter
type StateProps = LoginState;
interface DispatchProps {
	doLogout: () => void;
}

const mapStateToProps = ({ login }: { login: LoginState }): StateProps => login;
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	doLogout: () => dispatch({ type: LOGOUT }),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

interface UserMenuProps extends ConnectedProps<typeof connector> {
	collapsed: boolean;
}

function UserMenu(props: UserMenuProps) {
	const { userData, collapsed, doLogout } = props;
	const f = userData?.firstname ?? "John";
	const l = userData?.lastname ?? "Doe";
	const initiales = `${f.charAt(0)}${l.charAt(0)}`;
	const fullname = `${f} ${l}`;
	const disconnectColor = { color: colorVar };
	const router = useHistory();
	return (
		<Menu
			mode={collapsed ? "vertical" : "inline"}
			className="UserMenu"
			theme="light"
			defaultOpenKeys={[]}
		>
			<SubMenu
				className="UserMenu-submenu"
				icon={
					<Avatar
						className="UserMenu-avatar"
						style={{
							minWidth: 40,
							color: colorVar,
							backgroundColor: lighterColorVar,
							border: `1px solid ${colorVar}`,
						}}
						size="large"
					>
						{initiales}
					</Avatar>
				}
				key="1"
				title={
					<span>
						{collapsed ? null : (
							<span className="UserMenu-fullname">{fullname}</span>
						)}
					</span>
				}
			>
				{/* REACTIVATE */}
				{/* <Menu.Item className="UserMenu-menu-item" key="10" onClick={() => router.push("/profile")}>
					<span className="userMenu-menu-item">Profil</span>
				</Menu.Item> */}
				{/* REACTIVATE */}
				{/* <Menu.Item className="UserMenu-menu-item" key="11">
					<span className="userMenu-menu-item">Paramètres</span>
				</Menu.Item> */}
				<Menu.Item className="UserMenu-menu-item" key="12" onClick={doLogout}>
					<span className="userMenu-menu-item" style={disconnectColor}>
						Se déconnecter
					</span>
				</Menu.Item>
			</SubMenu>
		</Menu>
	);
}

export default connector(UserMenu);
