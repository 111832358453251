import { Map } from "../../../../interfaces/Utils"

const enTranslations: Map<string> = {
    "Organisation": "Organisation",
    "Partenaire": "Sponsor",
    "Type": "Type",
    "Visibilité": "Visibility",
    "Fin": "End",
    "Activité": "Activity",
    "Championnat": "League",
    "Début": "Begin",
    "Durée (saisons)": "Duration",
    "En savoir +": "News link",
    "Montant (M€/saison)": "Amount",
    "Niveau": "Level",
    "Officialisation": "Publicised",
    "Régie marketing": "Marketing Agency",
    "Statut": "Status",
};

export default enTranslations;
