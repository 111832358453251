import {
	DeleteOutlined,
	EditOutlined,
	QuestionCircleOutlined,
	TableOutlined,
} from "@ant-design/icons";
import { Button, Card, Popconfirm, Row, Typography } from "antd";

import { Maybe, RSetter } from "../../../interfaces/Utils";
import { ProjectBuilderData, ViewAndUsed } from "./interfaces";
import { removeVue } from "./vueHandlers";

import { dataTypeMasks } from "../../Infographics/StepCreate/Filters";

import { getConditionType } from "../../Infographics/StepCreate/conditionTypes";
import { renderFilters } from "../../Infographics/Home/MiniPreview";

const { Title, Text } = Typography;

interface ViewProps {
	viewAndUsed: ViewAndUsed;
	projectId: Maybe<string>;
	userId: string;
	state: ProjectBuilderData;
	setState: RSetter<ProjectBuilderData>;
}

function View(props: ViewProps) {
	const { viewAndUsed, projectId, userId, state, setState } = props;
	const { usedIn, view } = viewAndUsed;
	const { type, uid: id, info } = view;
	let title: string | undefined;
	let description: string | undefined;
	if (info) {
		title = info.private.title;
		description = info.private.description;
	}
	const plural = usedIn > 2 ? "s" : "";
	const onClick = removeVue(projectId ?? "", id, userId, state, setState);
	const renderConditions = (bloc: Array<object>) =>
		bloc.map((e: object, j: number) => (
			<div>
				{dataTypeMasks[e.field]}{" "}
				{
					getConditionType(e.type)
						?.filter((c) => c.value === e.op)
						?.shift()?.label
				}{" "}
				{e.field.includes(".date_")
					? new Date(e.value).toLocaleDateString()
					: e.value}
				{bloc.length > j + 1 ? <div>OU</div> : null}
			</div>
		));
	return (
		<Card
			style={{
				width: 710,
				marginBottom: 8,
			}}
			actions={[
				<>
					{/* Editer la vue */}
					<Button
						href={`/visualisations/vue/edit/${id}?projectId=${projectId}`}
						type="link"
					>
						<EditOutlined />
						Éditer
					</Button>
				</>,
				<>
					{/* Retirer de la liste */}
					{projectId !== "946c2c99-dad5-4b16-a187-382d869dcc8d" &&
					projectId !== "8f43fd4e-05e4-49a8-97aa-a623309e708a" ? (
						<Popconfirm
							title="Retirer la vue du projet ?"
							onConfirm={onClick}
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
							cancelText="Annuler"
						>
							<Button type="link">
								<DeleteOutlined />
								Retirer
							</Button>
						</Popconfirm>
					) : null}
				</>,
			]}
		>
			<Row align="middle">
				{type === "table" ? (
					<TableOutlined
						style={{
							height: 32,
							width: 32,
							color: "#9254de",
							marginRight: 8,
						}}
					/>
				) : (
					type
				)}
				<Title level={5}>{title || "Aucun titre donné"}</Title>
			</Row>
			<Text type="secondary">{description || "Aucune description donnée"}</Text>
			{renderFilters(view.filters)}
			{/* <Button type="link" size="small" style={{ width: "100%" }}>
				{usedIn > 1
					? `Utilisée dans ${usedIn - 1} autre${plural} projet${plural}`
					: "Utilisée uniquement dans ce projet"}
			</Button> */}
		</Card>
	);
}

export default View;
