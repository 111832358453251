import { connect, ConnectedProps } from "react-redux";

import { Result as AntResult } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";

import { RootState } from "../../../store";
import { ComponentName } from "../../../store/sidebar";
import NodeEdit from "../../Indicators/Label/NodeEdit";
import NodeMerge from "../../Indicators/Label/NodeMerge";
import PropEdit from "./PropEdit";
import LineEdit from "./LineEdit";

export const SiderComponents = Object.freeze({
	PROP_EDIT: "prop-edit",
	NODE_EDIT: "node-edit",
	NODE_MERGE: "node-merge",
	LINE_EDIT: "line-edit",
});

const sp = SiderComponents;

// #region Store connection
interface StateProps {
	rightSider?: boolean;
	component?: ComponentName;
	data?: any;
}

const mapStateToProps = ({
	sidebar: { collapsed, component, data },
}: RootState): StateProps => ({
	rightSider: collapsed,
	component: component,
	data: data,
});
const connector = connect(mapStateToProps);
// #endregion

type SidebarProps = ConnectedProps<typeof connector>;

function selectComponent(component?: string, data?: any): React.ReactNode {
	switch (component) {
		case sp.PROP_EDIT:
			return <PropEdit data={data} />;
		case sp.NODE_EDIT:
			return <NodeEdit data={data} />;
		case sp.NODE_MERGE:
			return <NodeMerge data={data} />;
		case sp.LINE_EDIT:
			return <LineEdit data={data} />;
		default:
			return <></>;
		// return (
		// 	// <AntResult
		// 	// 	icon={<SmileOutlined />}
		// 	// 	title="Vos données sont en cours de chargement."
		// 	// />
		//     </>
		// );
	}
}

function Sidebar(props: SidebarProps) {
	const { rightSider, component, data } = props;

	return <>{selectComponent(component, data)}</>;
}

export default connector(Sidebar);

export { PropEdit };
