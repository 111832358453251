import { useEffect, useState } from "react";
import { capitalize } from "lodash";

import { Button, Select, Space, Table, Typography } from "antd";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../../lib/fetch";
import { Map, Maybe } from "../../../../interfaces/Utils";
import { Node, NodeAndContext, NodeWithRelations } from "../../interface";
import getFormatter, { getLocalisedName, LabelTitle } from "../titleFormatter";
import { labelCol, titleCol } from "./columns";
import { Column, Related } from "./interface";

const { Text } = Typography;

// #region columns generator

function columnsGenerator(params: {
	attributes?: string[];
	omit?: string[];
	additional?: Column[];
}) {
	const { attributes, omit, additional } = params;
	const columns: Column[] = [];
	attributes
		?.filter((attr) => (omit || []).indexOf(attr) === -1)
		.forEach((attr) =>
			columns.push({
				title: capitalize(attr),
				dataIndex: ["attributes", attr],
				key: attr,
			})
		);

	additional?.forEach((add) => columns.push(add));
	columns.push({
		title: "Actions",
		key: "operation",
		width: 200,
		render: () => (
			<Space>
				<Button type="link" className="nicer-link" disabled>
					Ouvrir
				</Button>
			</Space>
		),
	});
	return columns;
}

// #endregion

function removeDuplicates<T>(arrays: T[][]): T[] {
	const n: T[] = [];
	for (let i = 0; i < arrays.length; i++) {
		const arr = arrays[i];
		if (!arr || arr.length == 0) continue;
		for (let j = 0; j < arr.length; j++) {
			const elem = arr[j];
			if (n.indexOf(elem) === -1) n.push(elem);
		}
	}
	return n;
}

function pushArray(
	m: Map<Related>
): (direction: string) => (node: NodeWithRelations) => void {
	return function (direction: string) {
		return function ({ node, relation: { type } }: NodeWithRelations) {
			if (type === "DEPENDS_ON") {
				return;
			}

			const { uid } = node.attributes;
			if (!uid) return;

			const labels = node.labels.filter((l) => !l.match(/^IDX_/)).join(" - ");
			const title = getFormatter(labels)(node);
			const n = m[uid];
			const rel = { type: type, direction: direction };
			m[uid] = n
				? { ...n, relations: [...n.relations, rel] }
				: { title: title, type: labels, relations: [rel] };
		};
	};
}

function pushToRels(rels: Map<Node[]>) {
	return function (e: NodeWithRelations) {
		if (e.relation.type === "DEPENDS_ON") {
			return;
		}

		const k = e.node.labels
			.filter((value) => !value.startsWith("IDX_"))
			.sort()
			.join(" - ");
		const ex = rels[k];
		if (ex !== undefined) {
			ex.push(e.node);
		} else {
			rels[k] = [e.node];
		}
	};
}

async function fetchRelated(
	userId: Maybe<string>,
	uid: string
): Promise<
	Maybe<{
		related: Map<Related>;
		newRelationner: Map<Node[]>;
	}>
> {
	if (!userId) {
		return;
	}

	const url = new URL(`${ROOT_API_URL}/neo/node`);
	url.searchParams.append("from", "true");
	url.searchParams.append("to", "true");
	url.searchParams.append("page", "0");
	url.searchParams.append("size", "20");
	url.searchParams.append("uid", uid);
	const headers = withAuthorization(userId, newHeaders());
	const r = await fetch(url.href, { headers: headers });
	if (!r.ok) {
		// TODO: handle error
		console.error(
			`error fetching counts: code: ${r.status} - message: ${r.statusText}`
		);
		return;
	}
	const body: { data: NodeAndContext[] } = await r.json();
	const rels: Map<Node[]> = {};
	const lpusher = pushToRels(rels);
	body.data.forEach(({ from, to }) => {
		from?.forEach(lpusher);
		to?.forEach(lpusher);
	});
	const { from, to } = body.data[0];
	const m: Map<Related> = {};
	const pusher = pushArray(m);
	from?.forEach(pusher("from"));
	to?.forEach(pusher("to"));
	return { related: m, newRelationner: rels };
}

interface RelationsDisplayerProps {
	node: Node;
	userId?: string;
}

function RelationsDisplayer(props: RelationsDisplayerProps) {
	const {
		node: {
			attributes: { uid },
		},
		userId,
	} = props;
	const [related, setRelated] = useState<Maybe<Map<Related>>>();
	const [relationner, setRelationner] = useState<Maybe<Map<Node[]>>>();
	const [paging, setPaging] = useState<{
		current: 1;
		pageSize: 20;
		total: 0;
	}>();

	useEffect(() => {
		if (!userId || !uid) {
			return;
		}
		fetchRelated(userId, uid).then((data) => {
			if (!data) {
				return;
			}
			const { related, newRelationner } = data;
			setRelated(related);
			setRelationner(newRelationner);
		});
	}, [uid]);

	if (!userId) {
		return <h1>Des autorisations manquent...</h1>;
	}

	const cols = columnsGenerator({ additional: [titleCol, labelCol] });

	return (
		<>
			<Text strong>Données liées</Text>
			<Table
				columns={cols}
				dataSource={related ? Object.keys(related).map((k) => related[k]) : []}
				size="small"
				pagination={
					related ? (Object.keys(related).length <= 20 ? false : paging) : false
				}
			/>

			{Object.keys(relationner ?? {}).map((k) => {
				console.log(k);
				const value = (relationner ?? {})[k];
				const ds = value.reduce<Map<number>>((acc, cur) => {
					const title = getFormatter(k)(cur);
					if (acc[title] === undefined) {
						return {
							...acc,
							[title]: 1,
						};
					}
					return {
						...acc,
						[title]: acc[title] + 1,
					};
				}, {});

				if (k === "Sector") {
					return (
						<div>
							<Text>{getLocalisedName("fr", k) ?? k}</Text>
							<br />
							<Select
								style={{ width: "100%" }}
								mode="multiple"
								options={Object.keys(ds).map((k) => ({
									label: k,
									value: k,
								}))}
								defaultValue={Object.keys(ds)}
								onSelect={(v) => {
									console.log(v);
								}}
							/>
						</div>
					);
				}
				const dataSource = [];
				for (const k in ds) {
					const n = ds[k];
					dataSource.push({ title: `${k} (${n})` });
				}
				return (
					<>
						<Text>{getLocalisedName("fr", k) ?? k}</Text>
						<Table
							showHeader={false}
							columns={[titleCol]}
							dataSource={dataSource}
							size="small"
							pagination={false}
						/>
					</>
				);
			})}
		</>
	);
}

export default RelationsDisplayer;
