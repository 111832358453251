import { Maybe } from "../interfaces/Utils";

export function capitalize(s: Maybe<string>): string {
	if (!s || s === "") return "";
	return s.charAt(0).toLocaleUpperCase() + s.slice(1,).toLocaleLowerCase();
}

export const slugify = (s: string): string => s
	.toLocaleLowerCase()
	.normalize("NFD")
	.replace(/[\u0300-\u036f]/g, "-")
	.replace(/ /g, "");

export const wrap = (s: string | string[]): string[] =>
	typeof s === "string" ? [s] : s;