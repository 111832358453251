import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popover, Space, Typography, Tooltip } from "antd";
import { isArray } from "lodash";
import { newsTanks } from "../../../Layout/Sidebar/LineEdit/handlers/select-newstank";
import { Callbackize } from "../../../../lib/functions";
import { Item } from "../interface";
import { KeyParent } from "./interfaces";

function addEditActionsCol(
	columns: any[],
	editingKey: React.Key,
	edit: (record: Item) => void,
	dup: (record: Item) => void,
	remove: (record: Item) => void,
	roots?: KeyParent[],
	isEditing?: boolean
): any[] {
	const actionCol = {
		title: "Actions",
		dataIndex: "operation",
		fixed: "left",
		width: 80,
		render: (_: any, record: Item) => {
			const disabled = isEditing || editingKey !== "";
			return (
				<span
					key="operation"
					// className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first"
					// style={{ position: "sticky", right: "0px" }}
				>
					<Space style={{ width: "100%", justifyContent: "center" }}>
						<Typography.Link
							disabled={disabled}
							onClick={Callbackize(edit, record)}
						>
							<Popover content="éditer">
								<EditOutlined />
							</Popover>
						</Typography.Link>
						<Typography.Link
							disabled={true /** Always disabled right now */ || disabled}
							onClick={Callbackize(dup, record)}
						>
							{/* <Popover content="dupliquer">
								<CopyOutlined />
							</Popover> */}
						</Typography.Link>
						<Typography.Link
							disabled={disabled}
							onClick={Callbackize(remove, record)}
						>
							<Popover content="supprimer">
								<DeleteOutlined />
							</Popover>
						</Typography.Link>
					</Space>
					<Space style={{ width: "100%", justifyContent: "center" }}>
						{isArray(record?.newstank)
							? record?.newstank?.map((nt) => (
									<Tooltip title={nt.name}>
										<span
											style={{
												height: "10px",
												width: "10px",
												borderRadius: "50%",
												backgroundColor: newsTanks.filter(
													(n) => n.name === nt.name
												)[0]?.color,
												display: "inline-block",
											}}
										/>
									</Tooltip>
							  ))
							: null}
					</Space>
				</span>
			);
		},
	};
	return (
		roots?.reduce(
			(acc, cur) => {
				const rd = cur.children.reduce((inacc, incur) => {
					const path = `${cur.prefix}.${incur.name}`;
					const e = columns.find((col) => col._dataIndex === path);
					if (!e) {
						console.log(`column "${incur.mask}" (${path}) not found !`);
						return inacc;
					}
					return [...inacc, e];
				}, [] as any[]);
				return [...acc, ...rd];
			},
			[actionCol] as any[]
		) ?? columns
	);
}

export default addEditActionsCol;
