import { message, Select } from "antd";

import { Map, RSetter } from "../../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../lib/fetch";
import { Node, NodeFetch } from "../../interface";
import { updateDocument } from "../common/linefetch";

import msOptions from "./meta_sectors.json";

const { Option, OptGroup } = Select;

function removeDiacritics(str: string): string {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function getCurrent(attributes: Map<any>) {
	const { upper, middle } = attributes;
	if (!upper || !middle) {
		// console.log(
		// 	`missing upper (${upper}) or middle (${middle}) for ${node.attributes.name_FR}`
		// );
		return [];
	}
	const parent = msOptions.find((opt) => opt.title === upper);
	if (!parent) {
		return [];
	}

	const childIndex = parent.children.findIndex((child) => child === middle);
	if (childIndex === -1) {
		return [];
	}
	return [`${parent.value}-${childIndex}`];
}

function updateFetched(
	newAttrs: Map<any>,
	index: number,
	fetched: NodeFetch,
	setFetched: RSetter<NodeFetch>
) {
	const newFetched = { ...fetched };
	const newValues = [...newFetched.values];
	newValues.splice(index, 1, newAttrs);
	newFetched.values = newValues;
	setFetched(newFetched);
}

function updateNode(
	uid: string,
	node: Node,
	newAttrs: Map<any>,
	labelList: string[],
	labels: string[],
	userId: string,
	fetched: NodeFetch,
	edited: number[],
	setEdited: RSetter<number[]>,
	setLoading: RSetter<boolean>
) {
	const url = new URL(`${ROOT_API_URL}/neo/node/edit/${uid}`);
	const body = JSON.stringify({
		labels: labelList,
		attrs: newAttrs,
	});

	const headers = withAuthorization(userId, newHeaders());
	fetch(url.href, { headers: headers, body: body, method: "POST" }).then((r) => {
		if (!r.ok) {
			// TODO: handle error properly
			console.error(
				`error fetching counts: code: ${r.status} - message: ${r.statusText}`
			);
			return;
		}
		updateDocument(
			userId,
			node.attributes.uid,
			labels.filter((l) => !l.match(/^IDX_/)),
			newAttrs,
			() => {
				const n = fetched.values.findIndex((f) => f.uid === node.attributes.uid);
				if (edited.indexOf(n) === -1) {
					setEdited([...edited, n]);
				}
				setLoading(false);
			}
		);
	});
}

interface MetaSector {
	key: string;
	value: string;
	children?: string;
}

interface MetaSectorSelectorProps {
	node: Node;
	index: number;
	fetched: NodeFetch;
	setFetched: RSetter<NodeFetch>;
	edited: number[];
	setEdited: RSetter<number[]>;
	setLoading: RSetter<boolean>;
	labels: string[];
	userId: string | undefined;
}

function MetaSectorSelector(props: MetaSectorSelectorProps) {
	const {
		node,
		index,
		fetched,
		setFetched,
		edited,
		setEdited,
		setLoading,
		labels,
		userId,
	} = props;
	const current = getCurrent(node.attributes);

	function filterOption(
		value: string,
		option?: {
			key: string;
			label?: string;
			options?: MetaSector[];
		}
	) {
		if (!option) return false;
		const cleanVal = removeDiacritics(value.toLowerCase());
		return (
			(option.options?.findIndex((child) => {
				if (!child.children) {
					return false;
				}
				return (
					removeDiacritics(child.children.toLowerCase()).indexOf(cleanVal) !==
					-1
				);
			}) ?? -1) !== -1 ||
			(option.label !== undefined
				? removeDiacritics(option.label.toLowerCase()).indexOf(cleanVal) !== -1
				: false)
		);
	}

	function onChange(value: string, label: MetaSector) {
		if (value.length <= 0 || label === undefined) {
			return;
		}
		if (!userId) {
			message.error("Vous n'êtes pas authentifié.");
			return;
		}
		const {
			attributes: { uid },
			labels: labelList,
		} = node;
		const newAttrs = {
			...node.attributes,
			middle: label.children,
			upper: msOptions.find((e) => `${e.value}` === value.split("-")[0])?.title,
		};
		updateNode(
			uid,
			node,
			newAttrs,
			labelList,
			labels,
			userId,
			fetched,
			edited,
			setEdited,
			setLoading
		);
		updateFetched(newAttrs, index, fetched, setFetched);
	}

	return (
		<Select
			showSearch
			filterOption={filterOption as any}
			value={current as any}
			onChange={onChange as any}
			style={{ width: "100%" }}
			mode='multiple'
		>
			{msOptions.map((e) => {
				return (
					<OptGroup label={e.title}>
						{e.children.map((child, i) => {
							const id = `${e.value}-${i}`;
							return (
								<Option key={id} value={id}>
									{child}
								</Option>
							);
						})}
					</OptGroup>
				);
			})}
		</Select>
	);
}

export default MetaSectorSelector;
