import Vue, { VueData, Suggestions } from "../../model/vue";
import Search, { Sorting } from "../../model/search";
import {
	Actions,
	SET_DATA,
	SET_LOADING,
	SET_SEARCH,
	SET_SORTING,
	SET_SUGGESTIONS,
	SET_VUE,
} from "./actions";
import { Action } from "./interfaces";

function setAct<T>(type: Actions, key: string): (pl: T) => Action {
	return (pl: T) => ({ type: type, payload: { [key]: pl } });
}

export const SetVueAction = setAct<Vue>(SET_VUE, "vue");
export const SetDataAction = setAct<VueData>(SET_DATA, "data");
export const SetSuggestionsAction = setAct<Suggestions>(SET_SUGGESTIONS, "suggestions");
export const SetSortingAction = setAct<Sorting>(SET_SORTING, "sorting");
export const SetSearchAction = setAct<Search>(SET_SEARCH, "search");
export const SetLoadingAction = setAct<boolean>(SET_LOADING, "loading");
