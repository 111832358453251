import { Col, Divider, Row, Typography, Input, Button, Alert, Spin } from "antd";
import { useEffect, useState, useRef, MutableRefObject } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router";
import _uniqueId from "lodash/uniqueId";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../lib/fetch";
import { MinimalLoginState } from "../../../store/login/interface";

const { Paragraph, Title } = Typography;
function Translate() {
	const {
		login: { userData, userId },
	} = useStore<MinimalLoginState>().getState();
	const { field } = useParams<{ field: string }>();

	if (!userId) {
		return;
	}

	const values: MutableRefObject<string[]> = useRef([]);
	const [needTranslation, setNeedTranslation] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [mode, setMode] = useState("missing");
	function getMissingTranslations() {
		if (!userId) {
			return;
		}
		setLoading(true);
		setMode("missing");
		values.current = [];
		const url = new URL(`${ROOT_API_URL}/neo/missingtranslations/${field}`);
		const headers = withAuthorization(userId, newHeaders());
		fetch(url.href, { method: "GET", headers: headers }).then(async (r) => {
			if (!r.ok) {
				// ALERT USER STH WENT WRONG
				const message = `COULD NOT RECEIVE MISSING TRANSLATIONS`;
				console.error(message);
				console.log(message);
				return;
			}
			setNeedTranslation(Object.values(await r.json()));
			setLoading(false);
		});
	}
	function getTranslations() {
		if (!userId) {
			return;
		}
		setMode("all");
		setLoading(true);
		values.current = [];
		const url = new URL(`${ROOT_API_URL}/neo/translations/${field}`);
		const headers = withAuthorization(userId, newHeaders());
		fetch(url.href, { method: "GET", headers: headers }).then(async (r) => {
			if (!r.ok) {
				// ALERT USER STH WENT WRONG
				const message = `COULD NOT RECEIVE MISSING TRANSLATIONS`;
				console.error(message);
				console.log(message);
				return;
			}
			setNeedTranslation(Object.values(await r.json()));
			setLoading(false);
		});
	}
	useEffect(() => {
		getMissingTranslations();
	}, []);

	const headers = withAuthorization(userId, newHeaders());
	function unitSave(label: string, index: number) {
		if (values.current[index]) {
			const val = values.current[index].trim();
			setNeedTranslation(
				needTranslation.map((v, i) => {
					return i === index
						? { ...v, isSaving: true, en: val, empty: false }
						: v;
				})
			);
			if (val) {
				fetch(`${ROOT_API_URL}/neo/updatetranslation/${field}`, {
					headers: headers,
					method: "POST",
					body: JSON.stringify({ label: label, translation: val }),
				}).then(async (r) => {
					if (r.ok) {
						if (mode === "missing") {
							delete values.current[index];
							setNeedTranslation(
								needTranslation.filter((_, j) => {
									return j !== index;
								})
							);
						} else {
							setNeedTranslation(
								needTranslation.map((v, i) => {
									return i === index
										? { ...v, isSaving: false, en: val }
										: v;
								})
							);
						}
					}
				});
			}
		} else {
			setNeedTranslation(
				needTranslation.map((v, i) => {
					return i === index ? { ...v, empty: true } : v;
				})
			);
		}
	}
	let fieldTitle = "";
	switch (field) {
		case "contractstatus":
			fieldTitle = "le champ status";
			break;
		case "contractvisibility":
			fieldTitle = "le champ visibilité";
			break;
		case "contracttype":
			fieldTitle = "les types de contrats";
			break;
		case "sector":
			fieldTitle = "les secteurs";
			break;
		default:
	}
	// TODOMANOLO
	// Affichage du nbr de contrats affectés (pour contract type et sector on a tj 1 car il ne retourne pas le nbr de contrat)
	// Corriger erreurs TS
	// eslint-disable-next-line consistent-return
	return (
		<>
			<Row>
				{mode === "missing" ? (
					<>
						<Title level={3}>Traductions manquantes pour {fieldTitle}</Title>
						<Button
							key="use"
							type="primary"
							style={{ marginLeft: "10px" }}
							onClick={getTranslations}
						>
							Voir toutes les traductions pour ce champ
						</Button>{" "}
					</>
				) : (
					<>
						<Title level={3}>Traductions pour {fieldTitle}</Title>
						<Button
							key="use"
							type="primary"
							style={{ marginLeft: "10px" }}
							onClick={getMissingTranslations}
						>
							Voir les traductions manquantes pour ce champ
						</Button>
					</>
				)}
			</Row>
			<Divider style={{ margin: 0 }} />
			<Col span={10}>
				{loading ? (
					<Spin />
				) : (
					<>
						<Row gutter={0} justify="space-between">
							<Col span={8}>
								<Paragraph strong>Francais</Paragraph>
							</Col>
							<Col span={10}>
								<Paragraph strong>Anglais</Paragraph>
							</Col>
							<Col span={4}>
								<Paragraph strong>Action</Paragraph>
							</Col>
							<Divider style={{ margin: 0 }} />
						</Row>
						{needTranslation.length ? (
							needTranslation
								// .sort((a, b) => {
								// 	return parseInt(a.nbr) > parseInt(b.nbr) ? -1 : 1;
								// })
								.map((_, i) => {
									return (
										<Row
											gutter={0}
											justify="space-between"
											key={_uniqueId("label-row-")}
										>
											<Col span={8}>
												<span>{needTranslation[i].fr}</span>
											</Col>
											<Col span={10}>
												<span>
													<Input
														defaultValue={
															needTranslation[i].en
														}
														onChange={(e) => {
															values.current[i] =
																e.target.value;
														}}
														disabled={
															needTranslation[i].isSaving
														}
														style={
															needTranslation[i].empty
																? {
																		border: "1px solid red",
																  }
																: {}
														}
													/>
												</span>
											</Col>
											<Col span={4}>
												{needTranslation[i].isSaving ? (
													<Spin />
												) : (
													<Button
														key="use"
														type="primary"
														onClick={() => {
															unitSave(
																needTranslation[i].fr,
																i
															);
														}}
													>
														Sauvegarder
													</Button>
												)}
											</Col>
											{/* <Col>{needTranslation[i].nbr}</Col> */}
											<Divider style={{ margin: 4 }} />
										</Row>
									);
								})
						) : (
							<p>Il n'y a plus rien à corriger ici</p>
						)}
					</>
				)}
			</Col>
		</>
	);
}

export default Translate;
