import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyTextToClipboard } from "../../lib/functions";
import { classesForInjection, TOKEN_GENERATOR_TEMPLATE } from "../Widget/widgetInjection";

const { Paragraph } = Typography;

interface IntegrationCodeProps {
	id: string;
}

export function toHighlightF(id: string) {
	return `<div class="${classesForInjection}" data-id="${id}" data-token="${TOKEN_GENERATOR_TEMPLATE}"></div>`;
}

export function codeInlinedAndMultiline(id: string): [string, string] {
	return [
		toHighlightF(id),
		`<div
	class="${classesForInjection}"
	data-id="${id}"
	data-token="${TOKEN_GENERATOR_TEMPLATE}"
>
</div>`,
	];
}

function IntegrationCode(props: IntegrationCodeProps) {
	const { id } = props;
	const [showCode, setShowCode] = useState(false);
	const toHighlight = toHighlightF(id);

	return (
		<>
			<Paragraph
				onClick={() => setShowCode(!showCode)}
				copyable={{ tooltips: ["Copier", "Copié !"], text: toHighlight }}
				style={{ width: "100%", cursor: "pointer" }}
			>
				<span style={{ width: 20, marginRight: 5 }}>
					{showCode ? <DownOutlined /> : <RightOutlined />}
				</span>
				{showCode ? "Masquer" : "Afficher"} le code source servant à insérer la 
				vue dans un article.
			</Paragraph>
			{showCode && (
				<div
					onClick={() =>
						copyTextToClipboard(toHighlight, {
							onOk: () => message.success("Code copié !"),
							onErr: (err) =>
								message.error(`Le code n'a pas pu être copié (${err})`),
						})
					}
					style={{
						cursor: "pointer",
						maxWidth: 710,
					}}
				>
					<SyntaxHighlighter
						language="html"
						style={{
							...docco,
							overflowX: "scroll",
						}}
					>
						{codeInlinedAndMultiline(id)[1]}
					</SyntaxHighlighter>
				</div>
			)}
			<Paragraph copyable={{ text: id }}>
				Un problème ? Merci de joindre l'identifiant du projet concerné dans votre requête sur redmine.
			</Paragraph>
		</>
	);
}

export default IntegrationCode;
