import { Map } from "../../../../interfaces/Utils";
import { Key } from "../../../../model/vue";
import { Checkable } from "../interface";

function genFirstCols(
	defaultCols: string[],
	keys: Map<Key>,
	excludes: string[]
): Checkable[] {
	const checked: Checkable[] = defaultCols.map((e) => ({ checked: true, key: e }));
	const unchecked: Checkable[] = Object.keys(keys)
		.filter((k) => excludes.indexOf(k) === -1)
		.reduce<{ title: string; key: string }[]>((acc, cur) => {
			const { title } = keys[cur];
			return defaultCols.indexOf(cur) !== -1
				? acc
				: [...acc, { title: title, key: cur }];
		}, [])
		.sort(({ title: aTitle }, { title: bTitle }) => aTitle?.localeCompare(bTitle))
		.map(({ key }) => ({ checked: false, key: key }));
	return [...checked, ...unchecked];
}

export default genFirstCols;
