import { Steps, Button, message } from 'antd';
import React, { useState } from 'react';
import { RSetter } from '../../interfaces/Utils';
import { Callbackize } from '../../lib/functions';

const { Step } = Steps;

// #region step interface
export interface DescStatePair<T> {
	state: T,
	setState: (React.Dispatch<React.SetStateAction<T>> | ((v: T) => void)),
	onSuccess: () => void,
}

export interface StepProps {
	title: string,
	description?: string,
	content: React.ReactNode
}

interface StepperProps<T> {
	steps: (pair: DescStatePair<T>) => StepProps[],
	step?: number;
	desc: T,
	setDesc: RSetter<T>,
	successMessage: string,
	errorMessage: string,
	onFinish?: () => void,
}
// #endregion

function Stepper<T>({
	step, steps, desc, setDesc, successMessage, errorMessage,
	onFinish,
}: StepperProps<T>) {
	const baseCurrent = step ?? 0;
	const [current, setCurrent] = useState(baseCurrent);

	const next = Callbackize(setCurrent, current + 1);
	const prev = Callbackize(setCurrent, current - 1);

	const realSteps = steps({ state: desc, setState: setDesc, onSuccess: next, });
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between"
			}}
		>
			<div>
				<Steps
					style={{
						padding: "0px 24px"
					}}
					current={current}
				>
					{realSteps.map(item => (
						<Step
							key={item.title}
							description={item.description}
							title={item.title}
						/>
					))}
				</Steps>
				<div
					style={{
						paddingTop: 36,
						height: "100%",
						paddingBottom: 36,
					}}
				>
					{realSteps[current].content}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				{current === 0 && (
					<span>
						<Button type="link">
							Enregistrer brouillon
						</Button>
						<Button size="large" style={{ margin: '0 8px' }} onClick={prev}>
							Retour au projet
						</Button>
					</span>
				)}
				{current > 0 && (
					<span>
						<Button type="link">
							Enregistrer brouillon
						</Button>
						<Button size="large" style={{ margin: '0 8px' }} onClick={prev}>
							Précédent
						</Button>
					</span>
				)}
				{current === realSteps.length - 1 && (
					<Button
						size="large"
						type="primary"
						onClick={onFinish ?? (() => message.success(successMessage))}
					>
						Générer l'infographie
					</Button>
				)}
				{current < realSteps.length - 1 && (
					<span>
						{/* <Button type="link">Enregistrer brouillon</Button> */}
						<Button size="large" type="primary" onClick={next}>
							Suivant
						</Button>
					</span>
				)}
			</div>
		</div>
	)
}

export default Stepper;