import { ExportOutlined } from "@ant-design/icons";
import explorer from "../../../../lib/explorer";
import { ColumnProps } from "../columns";

const colorVar = "#008a46";

const linkExternalHandler = (props: ColumnProps, i: number) => {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type, lang } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}

	const rem = k.slice(1);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		editable: true,
		key: i,
		align: "center",
		// width: renderArg?.width ?? "",
		width: "",
		// sorter: sortHandler(dataIndex),
		render: (value: any) => {
			const link = k.length > 1 ? explorer(rem, value) : value;
			const { mask, defaultValue, hideDefault } = renderArg ?? {};

			if (!link || (!!hideDefault && !!defaultValue && link === defaultValue)) {
				return "";
			}

			if (renderArg !== undefined && !!mask) {
				return (
					<a
						style={{ display: "flex", justifyContent: "center" }}
						key={`link-${link}`}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div style={{ color: colorVar, paddingRight: 10 }}>
							{lang === "en" ? "link" : mask}
						</div>
						<ExportOutlined style={{ fontSize: 16 }} />
					</a>
				);
			}
			return "";
		},
	};
};

export default linkExternalHandler;
