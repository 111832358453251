import moment from "moment";

import { frenchFormat, frenchParse } from "../../interfaces/editorial";
import { EmptyProject } from "../Projects/interface";

import TagViewGenerator from "./TagViewGenerator";

interface StatusTagProps {
    project?: EmptyProject;
}

function StatusTag(props: StatusTagProps) {
    const { project } = props;
    if (!project) {
        return null;
    }

    const published = project.dates?.published;
    const pub = frenchParse(published?.date)?.isBefore(moment.now()) ?? false;
    return pub ? (
        <TagViewGenerator type="published" />
    ) : (
        <TagViewGenerator type="draft" />
    );
}

export default StatusTag;