import { Button } from "antd";

import _ from "lodash";
import { ROOT_API_URL } from "../../../../lib/fetch";

interface ExportCSVProps {
    title: string;
}

function ExportCSV(props: ExportCSVProps) {
    const { title } = props;
    const filename = `Template - ${title}.xlsx`;
    return (
        <Button
            download={filename}
            href={`${ROOT_API_URL}/static/templates/${filename}`}
        >
            Télécharger le template
        </Button>
    );
}

export default ExportCSV;
