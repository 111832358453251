import moment from "moment";
import { parseAndFormatDate } from "../components/Projects/tools";
import { Maybe } from "./Utils";

export interface Authored {
	author: string;
	date: string;
}

export type ExtendedDate =
	| string
	| number
	| Authored
	| [string, number];

export interface EditorialDates {
	created: string;
	published?: Authored
	edited?: Authored;
}

export const frenchFormat = "DD/MM/YYYY[T]HH:mm:ss";
export function frenchParse(t: Maybe<string>): Maybe<moment.Moment> {
	if (!t) {
		return undefined;
	}
	const parsed = moment.parseZone(t, frenchFormat);
	if (!parsed.isValid()) {
		return undefined;
	}
	return parsed.subtract({hour: 2});
}

export function ExtendedToString(ex: ExtendedDate): string {
	if (typeof ex === "string" || typeof ex === "number") {
		return parseAndFormatDate(ex);
	}
	if (
		Array.isArray(ex) &&
		ex.length === 2 &&
		typeof ex[0] === "string" &&
		typeof ex[1] === "number"
	) {
		const [author, date] = ex;
		return `le ${ExtendedToString(date)} par ${author}`;
	}
	if ("author" in ex) {
		const { date, author } = ex;
		return `le ${ExtendedToString(date)} par ${author}`;
	}
	return "erreur";
}
