import Vue, { VueData, Suggestions, View as MView } from "../../model/vue";
import Search, { Sorting, SearchParam } from "../../model/search";
import { Actions, SET_VUE_AND_DATA } from "./actions";
import { Map } from "../../interfaces/Utils";

export interface SearchParamsObj {
	key: string;
	value: SearchParam;
}

export interface Action {
	type: Actions;
	payload: {
		loading?: boolean;
		vue?: Vue;
		data?: VueData;
		suggestions?: Suggestions;
		sorting?: Sorting;
		_all?: SearchParam;
		param?: SearchParamsObj;
		search?: Search;
		err?: any;
	};
}

export function setVueAndData(vue: Vue, data: VueData): Action {
	return {
		type: SET_VUE_AND_DATA,
		payload: {
			vue: vue,
			data: data,
		},
	};
}

export interface WidgetState {
	vue?: Vue;
	data?: VueData;
	search?: Search;
	loading?: boolean;
	suggestions?: Suggestions;
	err?: any;
}

export interface TimedResponse<T, U> {
	took: number;
	data: T;
	fetched?: U;
}

export interface Fetched<T> {
	data: T;
	total: number;
}

export interface DeepFetchedGet<T> {
	data: T;
	cnt: number;
}

export type FetchedR<T, U> = TimedResponse<Fetched<T>, U>;

export interface Ordering {
	key: string;
	asc: boolean;
}

export type DataResponse = TimedResponse<
	{
		count: number;
		hits: Map<any>[];
	},
	never
>;

export type View = MView;
// export interface View {
// 	default_columns: string[];
// 	comment: string;
// 	doc_type: string;
// 	idx: string;
// 	news_tank: string;
// 	parent_uid: string;
// 	slug: string;
// 	sorting: Ordering;
// 	title: string;
// 	type: string;
// 	uid: string;
// 	params: Map<any>;
// }

export type VueResponse = TimedResponse<Fetched<View[]>, never>;

export type IdxResponse = TimedResponse<
	Fetched<
		{
			default_columns?: string[];
			default_ordering?: Ordering;
			default_view: string;
			descriptions?: {
				public: string;
				private: string;
			};
			icon?: { url: string; width: number; height: number };
			id: string;
			indices: { es: string; neo: string };
			key: Map<any>;
			params?: Map<any>;
			ops: { neo: any; es: any };
			publication: { official: string; from: string; to: string };
			last_update: string;
			nt: string;
			slug: string;
			title: string;
			type: string;
		}[]
	>,
	never
>;
