export type Visibility = "default" | "hidden" | "removed";

export const visibilityOptions = [
	{ label: "Affichée", value: "default" },
	{ label: "Masquée", value: "hidden" },
	{ label: "Non consultable", value: "removed" },
];

export interface ColumnProps {
	title: string;
	value: string;
	maskKey: string;
	visibility: Visibility;
}
