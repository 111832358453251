import moment from "moment";
import React from "react";

import { BaseAction } from ".";

import { IndicatorSpec } from "../components/Indicators/interface";
import { VueTriple } from "../components/Infographics/interface";
import { EditorialDates } from "../interfaces/editorial";
import { Maybe } from "../interfaces/Utils";
import { Clause, Filter } from "../model/filters";
import { Sorting } from "../model/search";
import { DataKey, getInfo, MetaAccess, MetaData, MetaInfo, MetaKey, updateMaybeBoth, updateMaybeInfo, updateMaybeMeta, updateMaybeMetaData, View } from "../model/vue";

export const INFO_SET_PROPS = "INFO_SET_PROPS";
export const INFO_SET_EXPAND_KEYS = "INFO_SET_EXPAND_KEYS";
export const INFO_SET_PROPS_AND_EXPAND_KEYS = "INFO_SET_PROPS_AND_EXPAND_KEYS";
export const INFO_SET_CHECKED_KEYS = "INFO_SET_CHECKED_KEYS";
export const INFO_SET_DESCRIPTION = "INFO_SET_DESCRIPTION";
export const INFO_SET_INFO = "INFO_SET_INFO";
export const INFO_SET_META = "INFO_SET_META";
export const INFO_SET_DYNAMIC = 'INFO_SET_DYNAMIC';
export const INFO_SET_ID = "INFO_SET_ID";
export const INFO_SET_VUE = "INFO_SET_VUE";
export const INFO_SET_VUE_AND_STEP = "INFO_SET_VUE_AND_STEP";
export const INFO_SET_TYPE = "INFO_SET_TYPE";
export const INFO_SET_STEP = "INFO_SET_STEP";
export const INFO_SET_VUE_TRIPLE = "INFO_SET_VUE_TRIPLE";
export const INFO_SET_EDIT_VUE = "INFO_SET_EDIT_VUE";
export const INFO_SET_LOADING = "INFO_SET_LOADING";
export const INFO_SET_SORTING = "INFO_SET_SORTING";

export const INFO_FILTER_TITLE = "FILTER_TITLE";
export const INFO_FILTER_CLAUSES = "FILTER_CLAUSES";
export const INFO_FILTER_SET = "FILTER_SET";

export type InfoActions =
	| typeof INFO_SET_PROPS
	| typeof INFO_SET_EXPAND_KEYS
	| typeof INFO_SET_PROPS_AND_EXPAND_KEYS
	| typeof INFO_SET_CHECKED_KEYS
	| typeof INFO_SET_DESCRIPTION
	| typeof INFO_SET_ID
	| typeof INFO_SET_VUE
	| typeof INFO_SET_VUE_AND_STEP
	| typeof INFO_SET_TYPE
	| typeof INFO_SET_STEP
	| typeof INFO_SET_VUE_TRIPLE
	| typeof INFO_SET_EDIT_VUE
	| typeof INFO_SET_LOADING
	| typeof INFO_FILTER_TITLE
	| typeof INFO_FILTER_CLAUSES
	| typeof INFO_FILTER_SET
	| typeof INFO_SET_INFO
	| typeof INFO_SET_META
	| typeof INFO_SET_DYNAMIC
	| typeof INFO_SET_SORTING
	;

export interface InfoPayload {
	props?: IndicatorSpec;
	expanded?: React.Key[];
	checked?: React.Key[];
	desc?: InfDescription;
	id?: string;
	vue?: View;
	type?: {
		value: string;
		step: number;
	};
	title?: string;
	clauses?: Clause[][];
	filter?: Filter;
	step?: number;
	vueTriple?: VueTriple;
	loading?: boolean;
	info?: {
		access: MetaAccess;
		key: MetaKey;
		value: string;
	};
	meta?: {
		key: DataKey;
		value: string;
	};
	// dynamic?: boolean;
	static?: moment.Moment;
	sorting?: Sorting;
}

export type InfoAction = BaseAction<InfoActions, InfoPayload>;

export interface InfDescription {
	doc_type?: string;
	uid?: string;
	parent_uid?: string;
	slug?: string;
	title?: string;
	type?: string;
	news_tank?: string;
	comment?: string;
	default_columns?: string[];
	filtered_columns?: string[];
	search_params?: any;
	idx?: string;
	dates?: EditorialDates;
	info?: MetaInfo;
	// dynamic?: boolean;
	static?: moment.Moment | string;
	meta?: MetaData;
	filters?: Clause[][];
	sorting?: Sorting
}

export interface InfoState {
	desc: InfDescription;
	props?: IndicatorSpec;
	expanded: React.Key[];
	checked: React.Key[];
	id?: string;
	vueTriple?: VueTriple;
	// vue?: View;
	filter?: Filter;
	step?: number;
	loading?: boolean;
}

export interface MinimalInfoState {
	info: InfoState;
}

const defaultInfDesc: InfDescription = {
	title: "",
	news_tank: "football-eu",
	comment: "",
};

const initialState: InfoState = {
	desc: defaultInfDesc,
	expanded: [],
	checked: [],
};

export function buildInfoAction(type: InfoActions, payload: InfoPayload): InfoAction {
	return {
		type: type,
		payload: payload,
	};
}

export function setDescription(desc?: InfDescription): InfoAction {
	return {
		type: INFO_SET_DESCRIPTION,
		payload: { desc: desc },
	};
}

export function setId(id?: string): InfoAction {
	return {
		type: INFO_SET_ID,
		payload: { id: id },
	};
}

// export function setVue(vue?: View): InfoAction {
// 	return {
// 		type: INFO_SET_VUE,
// 		payload: { vue: vue },
// 	};
// }

export function setVueAndStep(vue: View, step: number, triple: VueTriple): InfoAction {
	return {
		type: INFO_SET_VUE_AND_STEP,
		payload: {
			vue: vue,
			step: step,
			vueTriple: triple,
		} as InfoPayload,
	} as InfoAction;
}

export function infoSetTriple(triple?: VueTriple): InfoAction {
	return {
		type: INFO_SET_VUE_TRIPLE,
		payload: { vueTriple: triple },
	};
}

export function filterActionTitle(title?: string): InfoAction {
	return {
		type: INFO_FILTER_TITLE,
		payload: { title: title },
	};
}

export function filterActionSet(filter: Filter): InfoAction {
	return {
		type: INFO_FILTER_SET,
		payload: { filter: filter },
	};
}

export function setType(type: string, step: number): InfoAction {
	return {
		type: INFO_SET_TYPE,
		payload: { type: { value: type, step: step } },
	};
}

export function setStep(step: number): InfoAction {
	return {
		type: INFO_SET_STEP,
		payload: { step: step },
	};
}

export function setClauses(clauses: Clause[][]): InfoAction {
	return {
		type: INFO_FILTER_CLAUSES,
		payload: { clauses: clauses },
	};
}

export function setInfo(access: MetaAccess, key: MetaKey, value: string): InfoAction {
	return {
		type: INFO_SET_INFO,
		payload: {
			info: {
				access: access,
				key: key,
				value: value,
			},
		},
	};
}

export function setMeta(key: DataKey, value: string): InfoAction {
	return {
		type: INFO_SET_META,
		payload: {
			meta: {
				key: key,
				value: value,
			},
		},
	};
}

export function setDynamic(staticV?: moment.Moment): InfoAction {
	return {
		type: INFO_SET_DYNAMIC,
		payload: {
			// dynamic: dynamic,
			static: staticV,
		},
	};
}

function infoReducer(gstate: Maybe<InfoState>, action: InfoAction): InfoState {
	const state = gstate ?? initialState;
	if (!action) {
		return state;
	}

	const { expanded, props, checked, desc } = initialState;
	const { type, payload } = action;

	switch (type) {
		case INFO_SET_PROPS: {
			return { ...state, props: payload?.props ?? props };
		}
		case INFO_SET_EXPAND_KEYS: {
			return { ...state, expanded: payload?.expanded ?? expanded };
		}
		case INFO_SET_PROPS_AND_EXPAND_KEYS: {
			return {
				...state,
				expanded: payload?.expanded ?? expanded,
				props: payload?.props ?? props,
			};
		}
		case INFO_SET_CHECKED_KEYS: {
			return { ...state, checked: payload?.checked ?? checked };
		}
		case INFO_SET_DESCRIPTION: {
			return { ...state, desc: payload?.desc ?? desc };
		}
		case INFO_SET_ID: {
			return { ...state, id: payload?.id };
		}
		// case INFO_SET_VUE: {
		// 	return { ...state, id: payload?.id, vue: payload?.vue }
		// }
		case INFO_SET_VUE_AND_STEP: {
			return {
				...state,
				desc: payload?.vue ?? {},
				step: payload?.step,
				vueTriple: payload?.vueTriple,
			};
		}
		case INFO_SET_TYPE: {
			return {
				...state,
				step: payload?.type?.step,
				desc: {
					...state.desc,
					type: payload?.type?.value,
				},
			};
		}
		case INFO_SET_STEP: {
			return { ...state, step: payload?.step };
		}
		case INFO_FILTER_SET: {
			return { ...state, filter: payload?.filter };
		}
		case INFO_FILTER_CLAUSES: {
			return {
				...state,
				filter: {
					...state.filter,
					clauses: payload?.clauses,
				},
			};
		}
		case INFO_SET_VUE_TRIPLE: {
			return {
				...state,
				vueTriple: payload?.vueTriple,
			};
		}
		case INFO_FILTER_TITLE: {
			return {
				...state,
				filter: {
					...state.filter,
					title: payload?.title,
				},
			};
		}
		case INFO_SET_EDIT_VUE: {
			return {
				...state,
				desc: payload?.desc ?? {},
				vueTriple: payload?.vueTriple,
				checked: payload?.checked ?? [],
				props: payload?.props,
				expanded: payload?.expanded ?? [],
				step: payload?.step,
				loading: payload?.loading,
				filter: {
					clauses: payload?.desc?.filters,
				},
			};
		}
		case INFO_SET_LOADING: {
			return {
				...state,
				loading: payload?.loading,
			};
		}
		case INFO_SET_INFO: {
			const info = payload?.info;
			if (!info) {
				return state;
			}

			const { access, value, key } = info;

			return {
				...state,
				desc: {
					...state.desc,
					info: updateMaybeBoth(state.desc.info, access, key, value),
				},
			};
		}
		case INFO_SET_META: {
			const meta = payload?.meta;
			if (!meta) {
				return state;
			}
			const { key, value } = meta;
			return {
				...state,
				desc: {
					...state.desc,
					meta: updateMaybeMetaData(state.desc.meta, key, value),
				},
			};
		}
		case INFO_SET_DYNAMIC: {
			return {
				...state,
				desc: {
					...state.desc,
					// dynamic: payload?.dynamic,
					static: payload?.static,
				},
			};
		}
		case INFO_SET_SORTING: {
			return {
				...state,
				desc: {
					...state.desc,
					sorting: payload?.sorting
				}
			}
		}
		default:
			return state;
	}
};

export { initialState as initialInfoState };

export default infoReducer;
