import { noop } from "lodash";
import _uniqueId from "lodash/uniqueId";
import { createElement, useState } from "react";

import {
	Button,
	Card,
	Col,
	Divider,
	Row,
	Space,
	Spin,
	Tooltip,
	Typography,
	Modal,
} from "antd";
import {
	InfoCircleOutlined,
	ThunderboltTwoTone,
	EditTwoTone,
	CheckCircleTwoTone,
	SyncOutlined,
	FieldTimeOutlined,
} from "@ant-design/icons";

import { labels as labelsMapping } from "../../tools/mappings";
import { IndicatorData, NeoIndicator } from "./interface";
import { Maybe } from "../../../interfaces/Utils";

import "../../../css/indicators/management.css";
import "../../../css/tools/elements.css";
import reIndex from "../Label/common/reindex";

import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../lib/fetch";

const { Paragraph } = Typography;

const cleanables = ["Sector"];

interface ActionButtonProps {
	text: string;
	disabled?: boolean;
	href: string;
	icon: typeof EditTwoTone;
}

function ActionButton(props: ActionButtonProps) {
	const { href, text, disabled, icon } = props;

	const iconElement = createElement(icon, {
		twoToneColor: "#9254de",
		style: { marginRight: 3 },
	});
	const disabled_ = disabled ?? false;
	return (
		<Button
			className="discrete-link"
			type="link"
			href={disabled_ ? undefined : href}
			disabled={disabled_}
		>
			{iconElement}
			{text}
		</Button>
	);
}

interface CardStructureProps {
	id: string;
	userId: Maybe<string>;
	cleanliness: Maybe<IndicatorData>;
	labels: Maybe<string[][]>;
	indicator: NeoIndicator;
}

function CardStructure(props: CardStructureProps) {
	const { id, userId, cleanliness, labels, indicator } = props;

	if (!cleanliness) {
		return <Spin>Chargement de données supplémentaires</Spin>;
	}

	let tmp: any[] = [];
	labels?.forEach((l) => {
		const name = l.sort().join(".");
		const label = l.filter((item) => !item.match(/^IDX_/));
		tmp.push({
			id: name,
			label: label[0] ? labelsMapping[label[0]] ?? label[0] : name,
			state: "refreshing",
		});
	});
	if (indicator.idx_label === "IDX_SPT_DEALTRACKER") {
		// Si dealtracker
		tmp.push({ id: "Dealtracker", label: "Dealtracker", state: "waiting" });
	} else {
		tmp.push({ id: "Commune", label: "Communes", state: "refreshing" });
	}
	const [refreshing, setRefreshing] = useState<any[]>(tmp);
	function refreshAll() {
		let temp = labels ?? [];
		if (indicator.idx_label !== "IDX_SPT_DEALTRACKER") {
			// On refresh les communes systématiquement car elles sont a part et gérées par aucun refresh sinon
			temp = [["Commune"]];
		}
		const data = {
			count: temp?.length,
			got: [] as string[][],
		};
		// console.log("Debut de rafraichissement de", temp);
		// Met à  jour l'index dealtracker complet via données neo
		async function updateAll() {
			if (!userId) return;
			tmp = tmp.map((v) => {
				return v.id === "Dealtracker" ? { ...v, state: "refreshing" } : v;
			});
			setRefreshing(tmp);
			const url = new URL(`${ROOT_API_URL}/neo/update/dealtracker`);
			const headers = withAuthorization(userId, newHeaders());
			await fetch(url.href, { method: "GET", headers: headers }).then(async (r) => {
				if (!r.ok) {
					const message = `COULD NOT UPDATE dealtracker`;
					console.error(message);
					// console.log(message);
				} else {
					tmp = tmp.map((v) => {
						return v.id === "Dealtracker" ? { ...v, state: "refreshed" } : v;
					});
					setRefreshing(tmp);
					// console.log("all is refreshed !");
				}
			});
		}
		function update() {
			const l = temp?.pop();
			if (l) {
				const name = l.sort().join(".");
				// console.log(`Reindexation de ${name} commencée.`);
				reIndex(
					() => {
						if (
							data.got.findIndex(
								(e) => JSON.stringify(l) === JSON.stringify(e)
							) === -1
						) {
							data.got.push(l);
						}
						tmp = tmp.map((v) => {
							return v.id === name ? { ...v, state: "refreshed" } : v;
						});
						setRefreshing(tmp);
						if (data.got.length === data.count) {
							// console.log(`${name} est à jour`);
							if (indicator.idx_label === "IDX_SPT_DEALTRACKER") {
								updateAll();
							} else {
								// console.log("all is refreshed !");
							}
						} else {
							// console.log(`${name} est à jour`);
							update();
						}
					},
					l,
					noop,
					userId
				)();
			}
		}
		update();
	}

	const ignoreList = ["done"];
	const [modalOpened, setModalOpened] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);
	return (
		<Card
			className="card-structure"
			title={
				<Row justify="space-between">
					<span>
						Structure de l&apos;indicateur
						<Tooltip
							placement="right"
							title="Représente les entités distinctes d'une population de données."
						>
							<InfoCircleOutlined
								style={{ marginLeft: 6, color: "rgba(0, 0, 0, 0.45" }}
							/>
						</Tooltip>
					</span>
					<Button onClick={() => setModalOpened(true)}>
						Rafraichir les données
					</Button>
				</Row>
			}
			// REACTIVATE
			// extra={
			// 	<Button type="default" href={`/indicators/edit/spec/${id}`}>
			// 		Paramétrer la structure
			// 	</Button>
			// }
		>
			<Modal
				title="Confirmation"
				visible={modalOpened}
				okButtonProps={
					refreshing.filter((v) => {
						return v.state !== "refreshed";
					}).length
						? { disabled: true }
						: {}
				}
				cancelButtonProps={modalConfirm ? { disabled: true } : {}}
				closable={false}
				cancelText="Annuler"
				okText="Ok"
				onCancel={() => {
					setModalOpened(false);
				}}
				onOk={() => {
					setModalOpened(false);
					setModalConfirm(false);
				}}
			>
				{modalConfirm ? (
					<div>
						{refreshing.map((v) => {
							return (
								<Row
									gutter={0}
									justify="space-between"
									key={_uniqueId("label-row-")}
								>
									<Col span={10}>{v.label}</Col>
									<Col span={5}>
										{/* eslint-disable-next-line no-nested-ternary */}
										{v.state === "refreshing" ? (
											<SyncOutlined
												spin
												style={{ color: "blue" }}
											/>
										) : v.state === "refreshed" ? (
											<CheckCircleTwoTone twoToneColor="#52c41a" />
										) : (
											<FieldTimeOutlined
												style={{ color: "orange" }}
											/>
										)}
									</Col>
									<Divider style={{ margin: 0 }} />
								</Row>
							);
						})}
					</div>
				) : (
					<div style={{ textAlign: "center" }}>
						<p>
							Rafraichir les données va ralentir le chargement des tableaux
							pendant quelque temps (~5 minutes)
						</p>
						<Button
							onClick={() => {
								setModalConfirm(true);
								refreshAll();
							}}
						>
							Confirmer
						</Button>
					</div>
				)}
			</Modal>
			<div>
				{/* <Row justify="space-between">
					<Col span={10}>Indicateur global</Col>
					<Col>
						<span style={{ fontWeight: 'bold' }}>{cleanliness.global} occurrences</span>
					</Col>
					<Col>
						<div style={{ width: 170 }}>
							<Progress percent={Math.trunc(cleanliness.global)} />
						</div>
					</Col>
				</Row> */}
				<Row gutter={0} justify="space-between">
					<Col span={10}>
						<Paragraph strong>Nature des données</Paragraph>
					</Col>
					<Col>
						<Paragraph strong>Actions</Paragraph>
					</Col>
				</Row>
				{cleanliness.labels
					.sort((a, b) => a.count - b.count)
					.reverse()
					.map(({ labels, count }) => {
						let title = labels
							.filter((val) => !/[A-Z]{3}_/g.test(val))
							.join(" - ");
						const cleanable = cleanables.indexOf(title) !== -1;
						title = labelsMapping[title] ?? title;
						// if (title === "ContractType") {
						// 	return;
						// }
						return (
							<Row
								gutter={0}
								justify="space-between"
								key={_uniqueId("label-row-")}
								style={{
									fontWeight: title === "Contrats" ? "bold" : undefined,
								}}
							>
								<Col span={10}>
									<span style={{ marginRight: 5 }}>{title}</span>
									{count >= 0 ? `(${count})` : undefined}
								</Col>
								{/*
									<Col>
										{Object.keys(stats)
											.filter((k) => ignoreList.indexOf(k) === -1)
											.filter((k) => {
												return k === "_avg";
											})
											.map((k) => {
												const value = stats[k];
												return (
													<div
														style={{ width: 170 }}
														key={_uniqueId("stats-")}
													>
														<Progress percent={Math.trunc(value)} />
													</div>
												);
											})}
									</Col> 
								*/}
								<Col>
									{/* REACTIVATE */}
									{/*
										<Link
											to={{
												pathname: "/indicators/label/edit",
												search: labels.map(l => `label=${l}`).join("&"),
											}}
											className="discrete-link"
										>
											<EditTwoTone twoToneColor="#9254de" style={{ marginRight: 3 }} />
											Éditer
										</Link>
									*/}
									<Space>
										{title === "Contrats" ? null : (
											<ActionButton
												href={`/indicators/label/clean?${labels
													.map((l) => `label=${l}`)
													.join("&")}`}
												text="Nettoyer"
												disabled={!cleanable}
												icon={ThunderboltTwoTone}
											/>
										)}
										<ActionButton
											href={`/indicators/label/edit?${labels
												.map((l) => `label=${l}`)
												.join("&")}`}
											text="Éditer"
											// disabled
											icon={EditTwoTone}
										/>
									</Space>
								</Col>
								<Divider style={{ margin: 4 }} />
							</Row>
						);
					})}
			</div>
		</Card>
	);
}

export default CardStructure;
