import _uniqueId from "lodash/uniqueId";
import { ColumnProps } from "../columns";

function actionsHandler(props: ColumnProps, i: number) {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type } = props;

	return {
		title: title,
		// editable: true,
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		key: i,
		// width: renderArg?.width ?? "",
		width: "",
		// sorter: sortHandler(dataIndex),
		render: (_: any, _record: any) => {
			const types = renderArg?.type;
			return types === undefined ? (
				""
			) : (
				<span>
					{types.map((_type: string) => (
						<div key={_uniqueId("actions-")}></div>
					))}
				</span>
			);
		},
	};
}

export default actionsHandler;
