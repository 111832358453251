import _uniqueId from "lodash/uniqueId";

import { Select } from "antd";

import { Map } from "../../../../interfaces/Utils";
import { Node } from "../../interface";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../lib/fetch";
import { safeIntCast } from "../../../../lib/safeCast";
import { NodeMergeState, SortedData } from "./interface";

export function mergeNodes(
	main: string,
	others: string[],
	props: Map<any>,
	userId: string,
	labels: string[]
): Promise<Response> {
	const url = new URL(`${ROOT_API_URL}/neo/node`);
	const body = JSON.stringify({
		main: main,
		others: others,
		props: props,
		labels: labels,
	});
	const headers = withAuthorization(userId, newHeaders());
	return fetch(url.href, { headers: headers, body: body, method: "POST" });
}

export function setSelected(state: NodeMergeState): (value: string) => NodeMergeState {
	return function (value: string): NodeMergeState {
		const { data } = state;

		if (!data) {
			return state;
		}

		return {
			...state,
			data: {
				...data,
				selected: value,
			},
		};
	};
}

export function sortUnsortedData(a: SortedData, b: SortedData): number {
	const { count: acount } = a;
	const { count: bcount } = b;
	return bcount - acount;
}

export function NodeToSorted(mapper: (e: Node) => string): (n: Node, index: number, array: Node[]) => SortedData {
	return function (n: Node): SortedData {
		const { attributes: { _from, _to, uid } } = n;
		const count = safeIntCast(_from) + safeIntCast(_to);
		const title = mapper(n);
		return { title: title, count: count, uid: uid };
	};
}

export function SortedToOption(
	value: SortedData,
	_index: number,
	_array: SortedData[]
): JSX.Element {
	const { title, count, uid } = value;
	return (
		<Select.Option value={uid} key={_uniqueId("attr-merge-tuple-")}>
			<span
				style={{
					overflow: "visible",
					textOverflow: "clip",
					whiteSpace: "normal",
				}}
			>
				{`${title} (${count})`}
			</span>
		</Select.Option>
	);
}

export function saveAction(
	selected: string,
	mergeData: Node[],
	userId: string,
	labels: string[],
	callback?: (r: Response) => void
): () => void {
	return function () {
		const main = selected;
		const others = mergeData.map((n) => n.attributes.uid);
		const props = mergeData.find((n) => n.attributes.uid === selected)?.attributes;
		if (!others || !props) {
			//!TODO: Raise an error here
			return;
		}

		const prm = mergeNodes(main, others, props, userId, labels);
		if (callback) {
			prm.then(callback);
		}
	};
}
