import { Card, Space, Steps, Typography } from "antd";

import { PieChartTwoTone, DatabaseTwoTone, CodeTwoTone } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Step } = Steps;

const intro = {
	data1: `La section `,
	data2: ` vous permettra dans un premier temps de gérer vos données et créer, éditer et nettoyer vos indicateurs.`,
	viz1: `De la section `,
	viz2: `, vous pourrez alors créer tableaux et graphiques via des projets de publication.`,
	export1: `C'est terminé ! Importez le `,
	export2: ` d'un projet dans vos articles sur Gecored !`,
};

// #region Titles
function DataTitle() {
	return (
		<Space direction="horizontal" align="start">
			<Title
				level={5}
				style={{
					color: "#22075e",
					width: "100%",
				}}
			>
				{`Préparez et gérez vos `}
				<span style={{ color: "#9254de" }}>données</span>
			</Title>
		</Space>
	);
}

function VizTitle() {
	return (
		<Space direction="horizontal" align="start">
			<Title
				level={5}
				style={{
					color: "#22075e",
					width: "100%",
					textAlign: "center",
				}}
			>
				{`Créez vos `}
				<span style={{ color: "#d3adf7" }}>visualisations</span>
			</Title>
		</Space>
	);
}

function ExportTitle() {
	return (
		<Space direction="horizontal" align="start">
			<Title
				level={5}
				style={{
					color: "#22075e",
					width: "100%",
					textAlign: "center",
				}}
			>
				{`Importez vos créations dans `}
				<span style={{ color: "#faad14" }}>Gecored</span>
			</Title>
		</Space>
	);
}
// #endregion

function Explainer() {
	return (
		<Card
			title="Pour bien commencer"
			style={{
				width: "100%",
				marginRight: 10,
			}}
		>
			<Steps direction="vertical" size="small" current={2}>
				<Step
					title={<DataTitle />}
					description={
						<span>
							{intro.data1}
							<DatabaseTwoTone
								twoToneColor="#9254de"
								style={{ marginRight: 3 }}
							/>
							<Text strong type="secondary">
								Données
							</Text>
							{intro.data2}
						</span>
					}
				/>
				<Step
					title={<VizTitle />}
					description={
						<span>
							{intro.viz1}
							<PieChartTwoTone
								twoToneColor="#9254de"
								style={{ marginRight: 3 }}
							/>
							<Text strong type="secondary">
								Visualisations
							</Text>
							{intro.viz2}
						</span>
					}
				/>
				<Step
					title={<ExportTitle />}
					description={
						<span>
							{intro.export1}
							<CodeTwoTone
								twoToneColor="#9254de"
								style={{ marginRight: 3 }}
							/>
							<Text strong type="secondary">
								code source
							</Text>
							{intro.export2}
						</span>
					}
					status="finish"
				/>
			</Steps>
		</Card>
	);
}

export default Explainer;
