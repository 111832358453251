import { AllowOrigin, ROOT_API_URL, newHeaders, withAuthorization } from "../../../../lib/fetch";

// updateSpec dispatches a network call to update the spec cleanliness value.
// On success it reloads the window.
export function updateSpec(uid: string, userId: string) {
	const url = new URL(`${ROOT_API_URL}/neo/clean/prop/${uid}`);
	const headers = ({
		...AllowOrigin,
		Authorization: userId,
	});
	fetch(url.href, { headers, method: 'PATCH', })
		.then(r => { if (r.ok) window.location.reload(); });
}

export function saveProps(userId: string, uid: string, data: Record<string, string>, dispatch401: (code: number, message: string) => void): () => void {
	const url = new URL(`${ROOT_API_URL}/neo/clean/prop/${uid}`);
	const headers = withAuthorization(userId, newHeaders());
	const init: RequestInit = {
		headers,
		method: "PUT",
		body: JSON.stringify(data),
	};

	return () => fetch(url.href, init).then(async r => {
		if (!r.ok) {
			dispatch401(r.status, r.statusText);
		} else {
			window.location.reload();
		}
	});
}