import { MutableRefObject, useRef } from "react";
import _uniqueId from "lodash/uniqueId";

import { Button, FormInstance, message, Row, Space } from "antd";

import _ from "lodash";

import { useDispatch } from "react-redux";
import { KeyDescription } from "./interfaces";
import { Map, Maybe, RSetter } from "../../../../interfaces/Utils";
import { EditState, Item } from "../interface";
import AddData from "./AddData";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../lib/fetch";
import { TimedResponse } from "../../../../store/widget/interfaces";

import { OpenAction } from "../../../../store/sidebar";

export interface ActionPayload {
	created: Map<any>[];
	edited: Map<any>[];
	deleted: Map<any>[];
}

interface ActionKeys {
	createdK: React.Key[];
	editedK: React.Key[];
	deletedK: React.Key[];
}

export interface ErrOrId {
	error?: string;
	id?: string;
}

export interface ActionResponse {
	created: ErrOrId[];
	edited: ErrOrId[];
	deleted: ErrOrId[];
}

interface ExporterError {
	code: number;
	message: string;
}

export interface ExporterReturn {
	error?: ExporterError;
	result?: Maybe<ActionResponse>;
}

export async function direxport(
	userId: Maybe<string>,
	id: string,
	newData: ActionPayload
): Promise<ExporterReturn> {
	if (!userId) {
		return {
			error: {
				code: -1,
				message: !userId
					? "il manque la userId"
					: "il manque les clefs spécialisées",
			},
		};
	}
	const url = new URL(`${ROOT_API_URL}/neo/node/new`);
	const headers = withAuthorization(userId, newHeaders());
	const method = "POST";

	const { created, deleted, edited } = newData;
	if (!(created.length > 0 || deleted.length > 0 || edited.length > 0)) {
		message.warning("Aucune modification à sauvegarder.");
		return {
			result: undefined,
		};
	}
	const body = JSON.stringify({ indicator: id, data: newData });
	const init: RequestInit = { headers: headers, body: body, method: method };
	const r = await fetch(url.href, init);
	if (r.ok) {
		const b: TimedResponse<ActionResponse, never> = await r.json();
		// !TODO: do the diff and inform user and clean the list
		return {
			result: b.data,
		};
	}
	return {
		error: {
			code: r.status,
			message: await r.text(),
		},
	};
}

interface HeaderButtonsProps {
	userId: Maybe<string>;
	id: string;
	// form: FormInstance<any>;
	keys: KeyDescription[];
	state: EditState;
	total: number;
	size: number;
	current: number;
	// setState: RSetter<EditState>;
	cols: { title: string; dataIndex: string }[];
	descs: Map<{ data_type: string; title: string }>;
	arrays: Map<string>;
	requiredValues: string[];
	title: string;
}

function HeaderButtons(props: HeaderButtonsProps) {
	const {
		id,
		userId,
		state,
		total,
		size,
		current,
		cols,
		descs,
		arrays,
		requiredValues,
		title,
	} = props;
	const { editingKey } = state;

	const editing = !!editingKey;

	const dispatch = useDispatch();
	return (
		<Row key={_uniqueId("header-buttons-")} justify="space-between" align="top">
			<Space
				style={{ marginBottom: 12, marginTop: 6, marginLeft: 4 }}
				align="start"
			>
				<div>
					{`Eléments ${(current - 1) * size + 1} à ${
						current * size
					} sur ${total}`}
				</div>
				<AddData
					id={id}
					userId={userId}
					headers={cols}
					keys={descs}
					// editState={editState}
					editingKey={editingKey}
					arrays={arrays}
					requiredValues={requiredValues}
					title={title}
				/>
				<Button
					type="primary"
					disabled={editing /* right now, no new data addition available */}
					onClick={() => {
						const payload = { record: [], idx: { id: id, title: title } };
						dispatch(OpenAction("line-edit", payload));
					}}
				>
					Ajouter une ligne
				</Button>
			</Space>
		</Row>
	);
}

export default HeaderButtons;
