type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | undefined;

export interface DLState {
	collapsed: boolean;
	breakpoint: Breakpoint;
	collapsedWidth: string | number | undefined;
	windowWidth: number;
	siderWidth: number;
}

export default function defaultState(): DLState {
	const { innerWidth: windowWidth } = window;
	let breakpoint: Breakpoint;
	let collapsedWidth: string;
	let collapsed: boolean;
	let siderWidth: number;

	if (windowWidth < 576) {
		breakpoint = "sm";
		collapsedWidth = "0";
		collapsed = true;
		siderWidth = windowWidth;
	} else if (windowWidth < 992) {
		breakpoint = "lg";
		collapsedWidth = "0";
		collapsed = true;
		siderWidth = 232;
	} else if (windowWidth < 1200) {
		breakpoint = "lg";
		collapsedWidth = "80";
		collapsed = true;
		siderWidth = 232;
	} else {
		breakpoint = "xl";
		collapsedWidth = "80";
		collapsed = false;
		siderWidth = 232;
	}

	return {
		collapsed: collapsed,
		breakpoint: breakpoint,
		collapsedWidth: collapsedWidth,
		siderWidth: siderWidth,
		windowWidth: windowWidth,
	};
}
