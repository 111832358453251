import { Button, Result } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { parse } from "qs";
import { useHistory, useLocation } from "react-router";

import { Maybe } from "../../interfaces/Utils";

interface MustHaveParamProps {
	paramKey: string;
	subTitle: string;
	children: (param: string) => JSX.Element;
}

function MustHaveParam(props: MustHaveParamProps) {
	const {goBack} = useHistory();
	const { children, subTitle, paramKey } = props;
	const param: Maybe<string> = parse(useLocation().search, {
		ignoreQueryPrefix: true,
	})[paramKey] as unknown as any;

	if (!param) {
		return (
			<Result
				icon={<WarningOutlined />}
				title="Attention"
				subTitle={subTitle}
				extra={
					<Button type="primary" onClick={goBack}>
						Retour
					</Button>
				}
			/>
		);
	}

	return children(param);
}

export default MustHaveParam;
