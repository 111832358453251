import { useState } from "react";

import { Card, Row, Typography, Col, Skeleton, Button } from "antd";

import { Map, Maybe } from "../../../interfaces/Utils";
import { FlagDescription, NeoIndicator } from "./interface";

import EditableParagraph from "../../tools/EditableParagraph";
import ComingSoonModal from "../../tools/ComingSoonModal";
import UsageStats from "../../tools/UsageStats";

import "../../../css/indicators/management.css";
import indicatorData, { IndicatorMoreData } from "./indicatorMoreData";

const { Title, Paragraph } = Typography;

const lengthLimitedStr = "Merci de renseigner une description pour permettre à chacun de comprendre les usages et enjeux du ";

interface CardInformationsProps {
	id: string;
	userId: string;
	indicator: NeoIndicator;
	flags: Maybe<FlagDescription[]>;
}


const defaultIndicatorMore: IndicatorMoreData = {
	authors: [],
	created: "01/01/2017",
	population: "non définie",
};

function CardInformations(props: CardInformationsProps) {
	const { id, userId, indicator } = props;
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const {
		authors, created, population,
	} = indicatorData[indicator.idx_label] ?? defaultIndicatorMore;
	const showModal = () => {
		setIsModalVisible(true);
	};
	const img = null;
	const rawImg = img ?? <Skeleton.Image style={{ height: 150, width: 180, marginBottom: 12 }} />;
	return (
		<Card className="card-info management-cards" title="Informations">
			<Row style={{ marginBottom: 12 }}>
				{/* REACTIVATE */}
				{/* <Col flex="200px">
					<Row justify="center">
						{rawImg}
					</Row>
					<Row justify="center">
						<Button
							onClick={showModal}
							size="small"
						>
							Charger un nouveau logo
						</Button>
						<ComingSoonModal
							isModalVisible={isModalVisible}
							setIsModalVisible={setIsModalVisible}
							timePeriod="cet hiver"
						/>
					</Row>
				</Col> */}
				{/* REACTIVATE */}
				{/* <Col span={16} style={{ marginLeft: 16, paddingBottom: 32 }}> */}
				{/* <Col span={16} style={{ marginLeft: 16 }}> */}
				<Col span={16}>
					<Title
						// editable={{ tooltip: 'Éditer', }}
						level={3}
					>
						{indicator.title}
					</Title>
					{/* TODO: update these values */}
					<UsageStats created={created} authors={authors} />
					{/* REACTIVATE */}
					{/* <div>
						<Text type="secondary">
							Utilisé {
								<Text underline type="secondary">77 fois</Text>
							} dont la dernière dans {
								<Text underline type="secondary">Un titre de projet assez long pour donner l'illusion</Text>
							} le {
								<Text type="secondary">1 janvier 2022</Text>
							} par {
								<Text type="secondary">Alexandre DUMAS</Text>
							}.
						</Text>
					</div> */}
				</Col>
			</Row>
			{/* REACTIVATE */}
			{/* <EditableParagraph
				value={indicator?.description}
				title={indicator?.title}
				id={id}
				userId={userId}
				defaultText={lengthLimitedStr}
				propKey="description"
				propTitle="Description interne"
				tooltip="Cette description est destinée à un usage interne. Elle permettra à chacun de comprendre les usages et enjeux de l'indicateur."
				min={3}
				max={5}
			/> */}
			{indicator?.description}
			<Title level={5} style={{ marginTop: 18 }}>
				Population principale de référence pour cet indicateur : {population}
			</Title>
			{/* REACTIVATE */}
			{/* <Row>
				<Title level={5}>Contributeurs</Title>
			</Row>
			<Row>
				{indicator?.contributors ? indicator?.contributors.join(", ") : "Pas de nouvelle contribution depuis la création."}
			</Row> */}
			{/* REACTIVATE */}
			{/* <Row style={{ marginTop: 12 }}>
				<Title level={5}>À traiter</Title>
			</Row>
			<Row>
				Rien à signaler.
				{flagsElements}
				<Tag style={{ marginBottom: 6 }} color="orange" key="Fr manquant">
					Français manquant
				</Tag>
				<Tag style={{ marginBottom: 6 }} color="purple" key="En missing">
					English missing
				</Tag>
				<Tag style={{ marginBottom: 6 }} color="geekblue" key="En attente de validation">
					En attente de validation
				</Tag>
				<Tag style={{ marginBottom: 6 }} color="red" key="Incomplet">
					Incomplet
				</Tag>
			</Row> */}
		</Card>
	)
}

export default CardInformations;