import { Action } from "@reduxjs/toolkit";
import cookies from "js-cookie";
import moment from "moment";
import { ThunkAction } from "redux-thunk";
import { Maybe } from "../../interfaces/Utils";

import { ROOT_API_URL } from '../../lib/fetch';
import {
	LOGIN, LoginAction, LoginActions, LoginData, LoginState, LOGIN_FAILED,
	LOGIN_SET_LOADING, LOGIN_SET_NEWSTANKS, LOGIN_SUCCESSFUL, LOGOUT,
	NewstanksInfo, UNAUTHORIZED, USER_COOKIE_KEY,
} from "./interface";

function toLoginState(v: any): Maybe<LoginData> {
	const id = v?.id;
	const {
		email,
		newstanks,
		prenom: firstname,
		nom: lastname,
		hasAccess,
	} = v?.user_data ?? {};
	if (!!id && !!email && !!firstname && !!lastname) {
		return {
			userId: id,
			userData: {
				email: email,
				newstanks: newstanks,
				firstname: firstname,
				lastname: lastname,
				hasAccess: hasAccess ?? false,
			},
		};
	}
	return undefined;
}

function initialState(): LoginState {
	const userId = cookies.get(USER_COOKIE_KEY);
	return { userId: userId };
}

function loginFailed(code: number, message?: string): LoginState {
	return { loginError: { code: code, message: message }, loading: false };
}

export const logoutAction: LoginAction = { type: LOGOUT };
export const loginOkAction = (data: LoginData): LoginAction => ({
	type: LOGIN_SUCCESSFUL,
	payload: { data: data },
});
export const loginSetLoading = (loading: boolean): LoginAction => ({
	type: LOGIN_SET_LOADING,
	payload: { loading: loading },
});
export const loginFailedAction = (code: number, message?: string): LoginAction => ({
	type: LOGIN_FAILED,
	payload: { reason: { code: code, message: message } },
});

type ThunkLoginAction = ThunkAction<void, LoginState, null, Action<LoginActions>>;

interface LoginFormProps {
	login: string;
	password: string;
	remember: boolean;
}

export function ThunkLogin(props: LoginFormProps): ThunkLoginAction {
	const { login, password, remember } = props;
	return async (dispatch) => {
		dispatch(loginSetLoading(true));
		try {
			fetch(`${ROOT_API_URL}/auth/login`, {
				headers: {
					"Access-Control-Allow-Origin": "*",
				},
				method: "POST",
				body: JSON.stringify({
					login: login,
					password: password,
					remember: remember,
				}),
			}).then(async (r) => {
				const { status } = r;
				if (status === 200) {
					const j = await r.json();
					const e = toLoginState(j);
					dispatch(!!e
						? loginOkAction(e)
						: loginFailedAction(500, "internal server error")
					);
				} else if (status === 401) {
					cookies.remove(USER_COOKIE_KEY);
					dispatch(loginFailedAction(status));
				} else {
					const j = await r.json();
					const err = "error" in j
						? j.error
						: UNAUTHORIZED;
					dispatch(loginFailedAction(status, err));
				}
			});
		} catch (e: any) {
			dispatch({ type: LOGIN_FAILED, payload: { reason: e.toString() } });
		}
	};
}

export function setNewstanksAction(nts: NewstanksInfo[]): LoginAction {
	return {
		type: LOGIN_SET_NEWSTANKS,
		payload: {
			nts: nts,
		},
	};
}

export function ThunkUserData(userId: string): ThunkLoginAction {
	return async (dispatch) => {
		try {
			fetch(`${ROOT_API_URL}/auth/sess/${userId}`, {
				headers: {
					"Access-Control-Allow-Origin": "*",
				},
				method: "POST",
			}).then(async (r) => {
				const { status } = r;
				if (status === 200) {
					const j = await r.json();
					const e = toLoginState(j);
					if (!!e) {
						dispatch(loginOkAction(e));
					} else {
						cookies.remove(USER_COOKIE_KEY);
						loginFailedAction(500, "internal server error");
					}
				} else if (status === 401) {
					cookies.remove(USER_COOKIE_KEY);
					dispatch(loginFailedAction(status, UNAUTHORIZED));
				} else {
					cookies.remove(USER_COOKIE_KEY);
					try {
						const j = await r.json();
						const err = ("error" in j)
							? j.error
							: UNAUTHORIZED;
						dispatch(loginFailedAction(status, err));
					} catch (e) {
						dispatch(
							loginFailedAction(
								status,
								`Votre session a expiré (CODE: ${status})`
							)
						);
					}
				}
			});
		} catch (e: any) {
			cookies.remove(USER_COOKIE_KEY);
			dispatch(loginFailedAction(e.toString()));
		}
	};
}


function loginReducer(state: LoginState, action: LoginAction) {
	if (state === undefined) {
		state = initialState();
	}
	if (action === undefined) {
		return state;
	}
	const { type } = action;
	switch (type) {
		case LOGIN: {
			return state;
		}
		case LOGIN_SET_LOADING: {
			const loading = action?.payload?.loading;
			if (loading === undefined) {
				return loginFailed(500, "invalid payload");
			}
			return { ...state, loading: loading };
		}
		case LOGIN_SUCCESSFUL: {
			const { payload } = action;
			if (!payload) {
				return loginFailed(500, "empty payload");
			}
			const { data, remember } = payload;
			if (!!data) {
				// save in the cookies
				cookies.set(USER_COOKIE_KEY, data.userId, {
					path: "/",
					expires: remember ? undefined : moment().add(7, "day").toDate(),
				});
				return { ...data, loading: false };
			}
			return loginFailed(500, "no resulting id");
		}
		case LOGIN_FAILED: {
			const { payload } = action;
			if (!payload?.reason) {
				return loginFailed(500, "empty payload");
			}
			const {
				reason: { code, message },
			} = payload;
			// UNAUTHORIZED
			if (code === 401) {
				cookies.remove(USER_COOKIE_KEY);
			}
			return loginFailed(code, message);
		}
		case LOGOUT: {
			cookies.remove(USER_COOKIE_KEY);
			return initialState();
		}
		case LOGIN_SET_NEWSTANKS: {
			const nts = action.payload?.nts;
			if (!nts) {
				return state;
			}

			return { ...state, newstanks: nts } as LoginState;
		}
		default: {
			return state;
		}
	}
	return state;
}

export default loginReducer;
