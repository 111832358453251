import { Select } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { Clause, DataType } from "../../../model/filters";
import { MinimalInfoState, setClauses as setClausesAction } from "../../../store/infographics";

import { getConditionType } from "./conditionTypes";

const { Option } = Select;

//#region Store connexion
interface StateProps {
	clauses?: Clause[][];
}

function mapStateToProps(state: MinimalInfoState): StateProps {
	const { info: { filter } } = state;
	return {
		clauses: filter?.clauses,
	};
}

interface DispatchProps {
	setClauses: (clauses: Clause[][]) => void;
}
function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setClauses: function setClauses(clauses: Clause[][]) {
			dispatch(setClausesAction(clauses));
		},
	};
}
const connector = connect(mapStateToProps, mapDispatchToProps);
//#endregion

interface FilterConditionSelectorProps extends ConnectedProps<typeof connector> {
	indices: [number, number];
}

export function calcOperations(dataType?: DataType) {
	return (!dataType)
		? undefined
		: getConditionType(dataType);
}

function FilterConditionSelector(props: FilterConditionSelectorProps) {
	const { indices: [outer, inner], clauses, setClauses } = props;
	const clause = clauses ? clauses[outer][inner] : undefined;
	const { type: dataType, op } = (clause ?? {});
	const def = calcOperations(dataType);
	const options = def?.map((e, i) => <Option
		value={e.value}
		key={i}
	>
		{e.label}
	</Option>
	) ?? "Aucun opérateur disponible pour cette colonne";

	function setOp(op: string) {
		const cls = [...(clauses ?? [])];
		const holder = [...cls[outer]];
		const current = holder[inner];
		holder[inner] = {
			...current,
			op: op,
			value: op === current.op ? current.value : undefined,
		};
		cls[outer] = holder;
		setClauses(cls);
	}

	return (
		<Select
			bordered={false}
			size="small"
			value={op}
			style={{ width: 220 }}
			placeholder="Sélectionner une condition"
			onChange={setOp}
		>
			{options}
		</Select>
	)
}

export default connector(FilterConditionSelector);
