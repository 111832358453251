import { Upload, Space } from "antd";

import { Maybe, RSetter } from "../../../interfaces/Utils";
import { ProjectBuilderData } from "./interfaces";
import UploadButton from "./UploadButton";
import View from "./View";

interface ViewsUploadProps {
	state: ProjectBuilderData;
	setState: RSetter<ProjectBuilderData>;
	projectId: Maybe<string>;
	userId: string;
}

function ViewsUpload(props: ViewsUploadProps) {
	const { state, setState, projectId, userId } = props;
	const views = !state.views?.length
		? []
		: state.views.map((v) => (
				<View
					viewAndUsed={v}
					projectId={projectId}
					userId={userId}
					state={state}
					setState={setState}
				/>
		  ));

	return (
		<Space>
			{views.length > 0 ? (
				views
			) : (
				<Space>
					<UploadButton project={state.project} userId={userId} action="new" />
					<UploadButton project={state.project} userId={userId} action="select" />
				</Space>
			)}
		</Space>
	);
}

export default ViewsUpload;
