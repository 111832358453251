import { useStore } from "react-redux";
import { combineReducers } from "@reduxjs/toolkit";
import { useHistory, useLocation } from "react-router";

import { Button, Result } from "antd";

import _ from "lodash";
import qs from "qs";

import { Map } from "../../../interfaces/Utils";

import LabelAnalyzer from "./LabelAnalyzer";
import NodeListMerge from "./NodeMerge/NodeListMerge";
import { NodeFetch } from "../interface";
import { editReducer } from "./store";
import sidebarReducer from "../../../store/sidebar";
import loginReducer from "../../../store/login";
import NodeListEdit from "./NodeEdit/NodeListEdit";
import { Callbackize } from "../../../lib/functions";

// #region flatttenParseQ
export function flattenParseQ(q: qs.ParsedQs): Map<string[]> {
	return Object.keys(q).reduce((acc, cur) => {
		const value = q[cur];
		if (!value) {
			return acc;
		}
		if (Array.isArray(value)) {
			const arr = value
				.map((e) => (typeof e !== "string" ? "" : e))
				.filter((e) => !!e);
			const v = acc[cur];
			return {
				...acc,
				[cur]: v ? [...v, ...arr] : arr,
			};
		}
		if (typeof value !== "string") {
			return acc;
		}
		const v = acc[cur];
		return {
			...acc,
			[cur]: v ? [...v, value] : [value],
		};
	}, {} as Map<string[]>);
}
// #endregion

// #region Fetching
export const defaultNodeFetch: NodeFetch = {
	src: {},
	values: [],
	page: 1,
	size: 10,
};
// #endregion

function LabelPage() {
	const { push: navigate } = useHistory();
	const loc = useLocation();
	const { search, pathname } = loc;
	const clean: boolean = _.last(pathname.split("/")) === "clean";
	const realLocation = search.startsWith("?") ? search.replace("?", "") : search;
	const q = qs.parse(realLocation);
	const searchParams = flattenParseQ(q);
	const labels = searchParams.label;
	const currentStore = useStore();
	currentStore.replaceReducer(
		combineReducers({
			login: loginReducer,
			sidebar: sidebarReducer,
			edit: editReducer,
		})
	);

	if (!labels || labels.length < 1) {
		const onResultClick = Callbackize(navigate, "/indicators/list");
		return (
			<Result
				status="warning"
				title="Il nous manque des éléments pour afficher vos données."
				extra={
					<Button type="primary" key="console" onClick={onResultClick}>
						Retourner à la gestion des données
					</Button>
				}
			/>
		);
	}

	const inner = clean ? (
		<NodeListMerge labels={labels} searchParams={searchParams} />
	) : (
		<NodeListEdit labels={labels} searchParams={searchParams} />
	);

	return (
		<div style={{ width: "calc(100%" }}>
			{/* <LabelAnalyzer labels={labels} /> */}
			{inner}
		</div>
	);
}

export default LabelPage;
