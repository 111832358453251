import Widget from "../Widget";

import "../../css/newstanklayout/index.css";

const NewsTankPreview = () => {
	const nt = "ftb";
	const color = "#008a46";
	const label = "Football";

	return (
		<div className="NewsTankLayout">
			<div className="NewsTankLayout-body">
				<div className="NewsTankLayout-header">
					<div className="NewsTankLayout-logo">
						<img width={260} height={75} src={`/logo_${nt}.png`} alt={`News-Tank-${label}-logo`} />
					</div>
					<div
						className="NewsTankLayout-breadcrumbs"
						style={{
							borderTop: `6px solid ${color}`,
							borderBottom: `6px solid ${color}`,
						}}
					>
						<span style={{ color: color }}>
							Accueil
						</span>
						<span className="NewsTankLayout-breadcrumbs-separators">
							{"//"}
						</span>
						<span>Data</span>
						<span className="NewsTankLayout-breadcrumbs-separators">
							{"//"}
						</span>
						<span>Deal tracker sponsoring</span>
					</div>
				</div>
				<div className="NewsTankLayout-iframe">
					<Widget website="Football" lang='en' />
				</div>
			</div>
		</div>
	)
}

export default NewsTankPreview;