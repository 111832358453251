import { InputNumber } from "antd";
import { useState } from "react";
import { Maybe } from "../../../../../interfaces/Utils";
import { RenderArg, RenderValueMRef, SingleArg } from "./interface";

function parseValue(data: any, args: SingleArg): Maybe<number> {
    let v: Maybe<number> = undefined;

    if (typeof data === "number") {
        v = data;
    }

    if (args !== undefined && v === args?.defaultValue) {
        if (args.onDefault === 'hide') {
            v = undefined
        } else if (args.onDefault === 'replace') {
            if (typeof args.replace === 'number') {
                v = args.replace;
            } else {
                v = undefined;
            }
        }
    }
    return v;
}

export default function renderer(
    key: string,
    data: any,
    args: SingleArg,
    disabled: false,
    rs: RenderValueMRef,
): JSX.Element {
    const [value, setValue] = useState(parseValue(data, args));
    rs.current[key] = () => value;
    return <InputNumber
        value={value}
        disabled={disabled}
        onChange={v => setValue(v)}
    />;
}