
export const TYPE_STRING = "string";
export const TYPE_NUMBER = "number";
export const TYPE_DATE = "date";
export const TYPE_BOOLEAN = "boolean";
export const types = [TYPE_STRING, TYPE_NUMBER, TYPE_DATE, TYPE_BOOLEAN,];

export const typeDescriptions = Object.freeze({
	TYPE_STRING: "STRING",
	TYPE_NUMBER: "NUMBER",
	TYPE_DATE: "DATE",
	TYPE_BOOLEAN: "BOOLEAN",
});

export const PRIO_REQUIRED = "REQUIRED";
export const PRIO_IMPORTANT = "IMPORTANT";
export const PRIO_INFO = "INFO";
export const PRIO_OPTIONAL = "OPTIONAL";

export const priorities = [PRIO_REQUIRED, PRIO_IMPORTANT, PRIO_INFO, PRIO_OPTIONAL];

export type Priority =
	| typeof PRIO_REQUIRED
	| typeof PRIO_IMPORTANT
	| typeof PRIO_INFO
	| typeof PRIO_OPTIONAL
	;

export const prioDescriptions = Object.freeze({
	PRIO_REQUIRED: "REQUIRED",
	PRIO_IMPORTANT: "IMPORTANT",
	PRIO_INFO: "INFO",
	PRIO_OPTIONAL: "OPTIONAL",
});
