import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import _uniqueId from "lodash/uniqueId";

import { Row, Col, Card, Typography, Skeleton, message } from "antd";

import { setType as setTypeAction } from "../../../store/infographics";
import { Map } from "../../../interfaces/Utils";
import "../../../css/visualisations/create.css";

const { Title } = Typography;
const { Meta } = Card;

// #region tabs
interface InfCreateTab {
	key: string;
	tab: string;
}

const tabs: InfCreateTab[] = [
	{
		key: "latest",
		tab: "Les derniers créés",
	},
	{
		key: "most-used",
		tab: "Les plus utilisées",
	},
];
// #endregion

const typeMapping: Map<string> = {
	// ''
	"Tableau simple": "table",
};

//#region store connexion
interface DispatchProps {
	setType: (type: string, step: number) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setType: function (type: string, step: number) {
			dispatch(setTypeAction(type, step));
		},
	};
}

const connector = connect(null, mapDispatchToProps);

//#endregion

// #region props
interface visChild {
	title: string;
}

export interface VisSelector {
	idTable: string;
	visIcon: React.ReactNode;
	visTitle: string;
	visChildren: visChild[];
	selectAction?: () => void;
}

export type VisSelectorProps = VisSelector & ConnectedProps<typeof connector>;
// #endregion

function VisSelector(props: VisSelectorProps) {
	const { idTable, visIcon, visTitle, visChildren, selectAction, setType } = props;
	// const [isModalVisible, setIsModalVisible] = useState(false);
	const [active, setActive] = useState(false);
	// const dispatch = useDispatch();

	// const showModal = () => {
	//   setIsModalVisible(true);
	// };

	// const handleUse = () => {
	//   setIsModalVisible(false);
	// };

	// const handleCancel = () => {
	//   setIsModalVisible(false);
	// };

	return (
		<Col
			style={{
				minWidth: 680,
				// maxWidth: 1000,
				paddingRight: 16,
			}}
			flex="auto"
			id={idTable}
			className="vis-selector"
		>
			<Row align="middle" gutter={8}>
				<Col>{visIcon}</Col>
				<Col>
					<Title level={4}>{visTitle}</Title>
				</Col>
			</Row>
			<Row style={{ marginBottom: "1rem" }}>
				{visChildren.map(({ title }) => {
					const k = typeMapping[title] ?? title;
					return (
						<Card
							key={_uniqueId("vis-child-")}
							hoverable
							size="small"
							onClick={() => {
								if (k !== "table") {
									message.error(
										`${title} n'est pas encore implémenté. Bientôt.`
									);
								} else {
									setType(title, 1);
								}
							}}
							style={{
								marginRight: "0.5rem",
								width: 160,
								border: active ? "3px solid #d3adf7" : undefined,
							}}
							cover={<Skeleton.Image style={{ width: 160, height: 95 }} />}
						>
							<Meta
								description={
									<span
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											height: 40,
											wordWrap: "break-word",
										}}
									>
										{title}
									</span>
								}
							/>
						</Card>
					);
				})}
			</Row>
			{/* <Row>
				<Card
					style={{
						width: "100%",
					}}
					tabList={tabs}
					// onTabChange={onTabChange}
					// activeTabKey={activeTabKey}
					size="small"
				>
					<Space direction="horizontal" style={{ width: "100%", overflowX: "scroll", }}>
						{new Array(15).fill(0).map(_ => (
							<div key={_uniqueId("skelestonk-")}>
								<div onClick={showModal} style={{ width: 99, height: 80, marginRight: 8 }}>
									<Skeleton.Image style={{ width: 99, height: 80 }} />
								</div>
							</div>
						))}
						<Modal
							visible={isModalVisible}
							width={650}
							title={"La super vue"}
							onOk={handleUse}
							onCancel={handleCancel}
							footer={[
								<Button key="back" onClick={handleCancel}>
									Retour
								</Button>,
								<Button key="use" type="primary" onClick={handleUse}>
									Utiliser
								</Button>,
							]}
						>
							<InfographicsDisplay
								title={"Vis Selector PLACEHOLDER"}
								dates={{created: "01/01/2001"}}
								vueId={"1234"}
								authors={["Clémence Bergon"]}
							/>
						</Modal>
					</Space>
				</Card>
			</Row> */}
		</Col>
	);
}

export default connector(VisSelector);
