import React from "react";

import InfographicsNew from "./InfographicsNew";
import InfographicsEdit from "./InfographicsEdit";
import InfographicsSelect from "./InfographicsSelect";
import { Indicator } from "../Indicators/Management/interface";
import Vue, { VueData } from "../../model/vue";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { Maybe } from "../../interfaces/Utils";
import { PreviewDataPaylowd, VueTriple } from "./interface";

export function genSelectedKeys(p: { idx: string; data: any }): React.Key[] {
	const { idx, data } = p;
	const arr: React.Key[] = [idx];
	const { metas } = data;
	metas.forEach(
		({ labels, props }: { labels: string[]; props: { name: string }[] }) => {
			const mkey = `${idx}.${labels}`;
			arr.push(mkey);
			props.forEach(({ name }) => {
				const pkey = `${mkey}.${name}`;
				arr.push(pkey);
			});
		}
	);
	return arr;
}

export async function getPreviewData(
	userId: string,
	id: string,
	expandedKeys: React.Key[],
	unauth: () => void
): Promise<Maybe<PreviewDataPaylowd>> {
	const active = id;
	const headers = withAuthorization(userId, newHeaders());
	const url = new URL(`${ROOT_API_URL}/neo/clean/spec`);
	url.searchParams.append("idx", active);
	return fetch(url.href, { headers: headers })
		.then(async (r) => {
			if (r.status === 401) {
				unauth();
				return;
			}
			if (r.status !== 200) {
				console.error("fetch error:", r.status, r.statusText);
				return undefined;
			}
			const data = await r.json();
			const url = new URL(r.url);
			const idx = url.searchParams.get("idx");
			return idx ? { idx, data } : undefined;
		})
		.then((p) => {
			if (!p) return;
			const newExpandedKeys = [...expandedKeys];
			const newProps = p.data;
			const specs: React.Key = p.data.definition.title;
			newExpandedKeys.push(specs);
			return {
				props: newProps,
				expandedKeys: newExpandedKeys,
				data: p,
			};
		});
}

export async function getTriple(uid: string, userId: string): Promise<Maybe<VueTriple>> {
	const idxurl = new URL(`${ROOT_API_URL}/api/v1/idx/${uid}`);
	const dataurl = new URL(`${ROOT_API_URL}/api/v1/data`);
	dataurl.searchParams.append("uid", uid);
	const headers = withAuthorization(userId, newHeaders());

	return Promise.all([
		fetch(idxurl.href, { headers: headers }).then(async (r) => {
			if (!r.ok) {
				// TODO: Handle this error
				console.error("not ok");
				return;
			}
			return (await r.json()) as { vue: Vue; idx: Indicator };
		}),
		fetch(dataurl.href, { headers: headers }).then(async (r) => {
			if (!r.ok) {
				// TODO: Handle this error
				console.error("error in fetching data");
				return;
			}
			const {
				data,
			}: {
				data: VueData;
				took: number;
			} = await r.json();
			return data;
		}),
	]).then(([mb, data]: [Maybe<{ vue: Vue; idx: Indicator }>, Maybe<VueData>]) => {
		if (!mb || !data) {
			// TODO: Handle this error
			return;
		}
		const { vue, idx } = mb;
		return { vue: vue, idx: idx, data: data };
		// setData();
	});
}

export { InfographicsNew, InfographicsEdit, InfographicsSelect };
