import { useStore } from "react-redux";

import { Button, message, Row, Typography, Tooltip, Space } from "antd";
import { EditOutlined, EyeOutlined, CodeOutlined } from "@ant-design/icons";

import { copyTextToClipboard } from "../../lib/functions";
import { EditorialDates } from "../../interfaces/editorial";
import { codeSnippet } from "../Indicators/Management/CardAdmin";
import { Maybe } from "../../interfaces/Utils";
import { dateLine } from "./Display";
import { openInNewTab } from "./tools";

import "../../css/projects/list.css";

const { Text } = Typography;

interface ProjectActionsProps {
	projectId: string;
	dates: EditorialDates;
	authors: string[];
	newstank?: string;
}
function onErr(location: string): (err: any) => void {
	return (err: any) => {
		let msg: Maybe<string>;
		if (typeof err === "boolean") {
			msg = `${location} n'a pas pu être ajouté au presse-papier.`;
		} else {
			msg = `Erreur lors de la copie: ${err}.`;
		}
		message.error(msg);
	};
}

const onCodeErr = onErr("Le code");
const onIdErr = onErr("L'identifiant");

function ProjectActions(props: ProjectActionsProps) {
	const { projectId, dates, authors, newstank } = props;
	const userId: Maybe<string> = useStore().getState()?.login?.userId;

	function onCopyExport() {
		const text = codeSnippet(projectId);
		copyTextToClipboard(text, {
			onOk: () => {
				const exportMsg = "Le code a été copié dans le presse-papier !";
				message.success(exportMsg);
			},
			onErr: onCodeErr,
		});
	}

	return (
		<Row align="middle" justify="space-between">
			<Text type="secondary" style={{ fontSize: "80%" }}>
				{dateLine(dates, authors)}
			</Text>
			<Space>
				<Tooltip placement="bottomRight" title="Aperçu">
					<Button
						className="discrete-link"
						type="link"
						size="small"
						onClick={openInNewTab(userId, projectId, newstank)}
					>
						<EyeOutlined />
					</Button>
				</Tooltip>
				<Tooltip placement="bottomRight" title="Éditer le projet">
					<Button
						className="discrete-link"
						type="link"
						size="small"
						href={`/visualisations/projects/edit/${projectId}`}
					>
						<EditOutlined />
					</Button>
				</Tooltip>
				<Tooltip
					placement="bottomRight"
					title="Copier le code source à insérer dans l'article"
				>
					<Button
						className="discrete-link"
						type="link"
						size="small"
						onClick={onCopyExport}
					>
						<CodeOutlined />
					</Button>
				</Tooltip>
			</Space>
		</Row>
	);
}

export default ProjectActions;
