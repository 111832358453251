import React from "react";
import ftb from "../../Indicators/json/ftb.json";
import { Map, Maybe } from "../../../interfaces/Utils";
import { dataTypeMasks } from "./Filters";
import { ColumnProps, Visibility } from "./interfaces";
import { InfoState } from "../../../store/infographics";
import { DeepObject } from "../../../lib/array";

export const visibilities: Visibility[] = ["default", "hidden", "removed"];

function matchLabel(s: string): boolean {
	return !(s.match(/^[A-Z]{3}_/) && !s.startsWith("IDX_"));
}

function getVisibility(
	defCols: Maybe<string[]>,
	filtered: string[]
): (title: string) => Visibility {
	return function (title: string) {
		if (defCols && defCols?.findIndex((e) => e === title) !== -1) {
			return "default";
		}
		if (filtered.indexOf(title) !== -1) {
			return "removed";
		}
		return "hidden";
	};
}

export function processValue<T>(
	value: React.Key,
	filterMap: (
		title: string,
		value: string,
		maskKey: string,
		visibility?: Visibility
	) => Maybe<T>,
	visibility?: Visibility
): Maybe<T> {
	const s = `${value}`;
	const splt = s.split(".");
	if (splt.length != 3) {
		return undefined;
	}
	const [_, label, key] = splt;
	if (key.endsWith("uid")) {
		return undefined;
	}
	const rl = label.split(",").filter(matchLabel).sort().join(".");
	const rn = (ftb.labelsMap as Map<string>)[rl];
	if (rn) {
		const rl = `${rn}.${key}`;
		const rk = (ftb.conversions as Map<string>)[rl];
		if (rk) {
			const title = dataTypeMasks[rk];
			if (title) {
				return filterMap(title, s, rk, visibility);
			}
		}
	}
	return undefined;
}

export function getCols(
	cols: Maybe<string[]>,
	info: Maybe<InfoState>,
	savedCols?
): ColumnProps[] {
	if (!cols) {
		return [];
	}
	const checked = info?.checked ?? [];
	const filtered = info?.desc.filtered_columns ?? [];
	const default_ = info?.desc.default_columns ?? [];

	const fGetVis = getVisibility(cols, filtered);
	function updateValue(
		title: string,
		value: string,
		maskKey: string,
		visibility?: Visibility
	) {
		return {
			title: title,
			value: value,
			maskKey: maskKey,
			visibility: visibility ?? fGetVis(maskKey),
		};
	}

	function reducer(acc: ColumnProps[], cur: React.Key) {
		const value = processValue(cur, updateValue);
		return value ? [...acc, value] : acc;
	}
	// Si on a deja des colonnes de sauvegarder on prends cela plutot que le par defaut
	let temp = savedCols;
	if (savedCols && savedCols.length === 0) {
		temp = checked.reduce(reducer, []);
	}
	let sortedtemp = temp;
	if (info?.props?.definition.idx_label !== "IDX_SPT_DEALTRACKER") {
		sortedtemp = temp.sort((a, b) => {
			const apos = visibilities.indexOf(a.visibility);
			const bpos = visibilities.indexOf(b.visibility);
			return apos === bpos ? a.title.localeCompare(b.title) : apos - bpos;
		});
	}
	// Tri et visibilité par default en dur pour les colonnes du dealtracker
	if (
		info?.props?.definition.idx_label === "IDX_SPT_DEALTRACKER" &&
		filtered.length === 0 &&
		default_.length === 0
	) {
		const defaultOrdersAndVisibilities = {
			"contract.amount": {
				visibility: "hidden",
				order: 13,
			},
			"contract.duration": {
				visibility: "hidden",
				order: 12,
			},
			"contract.sponsorship_status.fr": {
				visibility: "default",
				order: 6,
			},
			"contract.sponsorship_status.en": {
				visibility: "default",
				order: 6,
			},
			"contract.end": {
				visibility: "default",
				order: 11,
			},
			"contract.begin": {
				visibility: "hidden",
				order: 10,
			},
			"contract.officialisation": {
				visibility: "default",
				order: 9,
			},
			"contract.marketing_manager": {
				visibility: "removed",
				order: 15,
			},
			"contract.news_link": {
				visibility: "hidden",
				order: 14,
			},
			"contract.season": {
				visibility: "hidden",
				order: 50,
			},
			"contract.visibility.fr": {
				visibility: "default",
				order: 8,
			},
			"contract.visibility.en": {
				visibility: "default",
				order: 8,
			},
			"contract.level": {
				visibility: "hidden",
				order: 7,
			},
			"contract.category.name.en": {
				visibility: "default",
				order: 5,
			},
			"contract.category.name.fr": {
				visibility: "default",
				order: 5,
			},
			"organization.name": {
				visibility: "default",
				order: 1,
			},
			"sponsor.sector.name.fr": {
				visibility: "hidden",
				order: 4,
			},
			"sponsor.sector.name.en": {
				visibility: "hidden",
				order: 4,
			},
			"sponsor.name": {
				visibility: "default",
				order: 3,
			},
			"league.name": {
				visibility: "hidden",
				order: 2,
			},
		};
		sortedtemp = temp.sort((a, b) => {
			return (defaultOrdersAndVisibilities.hasOwnProperty(a.maskKey)
				? defaultOrdersAndVisibilities[a.maskKey].order
				: 99) >=
				(defaultOrdersAndVisibilities.hasOwnProperty(b.maskKey)
					? defaultOrdersAndVisibilities[b.maskKey].order
					: 99)
				? 1
				: -1;
		});
		sortedtemp = sortedtemp.map((a) => {
			if (defaultOrdersAndVisibilities.hasOwnProperty(a.maskKey)) {
				a.visibility = defaultOrdersAndVisibilities[a.maskKey].visibility;
			}
			return a;
		});
	}
	let n = [];
	if (info?.props?.definition.idx_label !== "IDX_SPT_DEALTRACKER") {
		const dflts = sortedtemp.splice(0, default_.length);
		n = dflts.reduce((acc, cur) => {
			const n = [...acc];
			n[
				default_.indexOf(cur.maskKey) >= 0
					? default_.indexOf(cur.maskKey)
					: n.length
			] = cur;
			return n;
		}, new Array<ColumnProps>(dflts.length));
	}
	return [...n, ...sortedtemp];
}

export function sortCols(cols: ColumnProps[]): [string[], string[]] {
	const default_: string[] = [];
	const filtered: string[] = [];
	for (let i = 0; i < cols.length; i++) {
		const c = cols[i];
		const { visibility, maskKey } = c;
		switch (visibility) {
			case "default":
				default_.push(maskKey);
				break;
			case "removed":
				filtered.push(maskKey);
				break;
		}
	}
	return [default_, filtered];
}
