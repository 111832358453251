import { useState } from "react";

import { Button, Modal } from "antd";

import { Map } from "../../../interfaces/Utils";
import ColumnsSorter from "./columns/ColumnsSorter";
import { Checkable } from "./interface";
import { Callbackize } from "../../../lib/functions";

import { dataTypeMasks } from "../../Infographics/StepCreate/Filters";

interface MoreColumnsTexts {
	handle: string;
	defCols: string;
	allCols: string;
	submit: string;
	title: string;
}

const defaultTexts: MoreColumnsTexts = {
	handle: "Gérer les colonnes",
	defCols: "Affichage par défaut",
	allCols: "Toutes les colonnes",
	submit: "Valider",
	title: "Affichage des colonnes",
};

const localisedTexts: Map<MoreColumnsTexts> = {
	fr: defaultTexts,
	en: {
		title: "Columns",
		handle: "Columns",
		defCols: "Default columns",
		allCols: "All columns",
		submit: "Submit",
	},
};

interface MoreColumnsProps {
	default: Checkable[];
	current: Checkable[];
	onChange(v: Checkable[]): void;
	titlesMap?: Map<string>;
	color: string;
	ignored: string[];
	lang: string;
}

function MoreColumns(props: MoreColumnsProps) {
	const {
		default: default_,
		current,
		onChange,
		titlesMap,
		color,
		ignored,
		lang,
	} = props;
	// #region modal
	const [visible, setVisible] = useState(false);
	const currentFinal = current.filter(({ key }) => dataTypeMasks[key]);
	const defaultFinal = default_.filter(({ key }) => dataTypeMasks[key]);
	const [local, setLocal] = useState(currentFinal);
	const curSetVisible = (v: boolean) => () => setVisible(v);
	const showModal = curSetVisible(true);

	const handleOk = curSetVisible(false);
	const handleCancel = () => {
		handleOk();
		onChange(local);
	};

	const initialCols = defaultFinal.reduce<Checkable[]>(
		(acc, cur) => (ignored.indexOf(cur.key) !== -1 ? acc : [...acc, cur]),
		[]
	);

	const allCols = currentFinal.map(({ key }) => ({ key: key, checked: true }));

	const handleReset = Callbackize(setLocal, initialCols);
	const handleAllColls = Callbackize(setLocal, allCols);
	// #endregion
	const {
		title,
		handle,
		defCols: defColsText,
		allCols: allColsText,
		submit,
	} = localisedTexts[lang] ?? defaultTexts;
	return (
		<div className="gedata-modal-root">
			<Button
				type="primary"
				style={{ marginTop: "auto", marginBottom: "auto" }}
				onClick={showModal}
			>
				{handle}
			</Button>
			<Modal
				getContainer=".gedata-modal-root"
				title={title}
				visible={visible}
				onCancel={handleCancel}
				footer={[
					<Button key="reset" onClick={handleReset}>
						{defColsText}
					</Button>,
					<Button key="all-cols" onClick={handleAllColls}>
						{allColsText}
					</Button>,
					<Button type="primary" key="save" onClick={handleCancel}>
						{submit}
					</Button>,
				]}
			>
				<ColumnsSorter
					titles={titlesMap ?? {}}
					columns={local}
					onChange={setLocal}
				/>
			</Modal>
		</div>
	);
}

export default MoreColumns;
