import { Row, Col, Typography, Card } from "antd";

import Explainer from "./Explainer";
// REACTIVATE
// import UserCurrentProjects from './UserCurrentProjects';
// REACTIVATE
// import UserFavoriteIndicators from './UserFavoriteIndicators';

import "../../css/dashboard/dashboard.css";
import "../../css/tools/elements.css";

const { Title, Text } = Typography;

function HomeDashboard() {
	return (
		<div className="Dashboard">
			<Row justify="space-between" style={{ marginBottom: 10 }}>
				<Title
					level={1}
					style={{
						color: "#22075e",
						width: "100%",
						marginLeft: 12,
					}}
				>
					{`Bienvenue sur `}
					<div
						style={{
							display: "flex",
							justifyContent: "end",
							width: "100%",
						}}
					>
						<img
							className="full-logo"
							height={60}
							style={{
								marginRight: 12,
							}}
							src={window.location.origin + "/logo_gedata.svg"}
							alt="logo-gedata"
						/>
					</div>
				</Title>
			</Row>
			<Explainer />
			{/* REACTIVATE */}
			{/* <UserFavoriteIndicators /> */}
			{/* REACTIVATE */}
			{/* <UserCurrentProjects /> */}
		</div>
	);
}

export default HomeDashboard;
