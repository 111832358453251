import { Map, Node } from "../../../interfaces/Utils";

export const TitleFormat: Map<(e: Node) => string> = {
	Sector: function TitleFormatter(e: Node) {
		return `${e.attributes.name_FR} / ${e.attributes.name_EN}`;
	},
	Sponsor: function TitleFormatter(e: Node) {
		return `${e.attributes.name}`;
	},
	"Organization - Championship": function TitleFormatter(e: Node) {
		return `${e.attributes.name}`;
	},
	Organization: function TitleFormatter(e: Node) {
		return `${e.attributes.name}`;
	},
	ContractType: function TitleFormatter(e: Node) {
		return `${e.attributes.name_FR} / ${e.attributes.name_EN ?? "-"}`;
	},
	Contract: function TitleFormatter(e: Node) {
		return e.attributes.status_FR ?? e.attributes.status_EN;
	},
};

export const LabelTitle: Map<string> = {
	Sector: `Nom / Name`,
	Sponsor: "Partenaire",
	"Organization - Championship": "Championnat",
	"Championship - Organization": "Championnat",
	Organization: "Club",
	ContractType: "Type de contrat",
	Contract: "Contrat",
};

function defaultFormatter(e: Node): string {
	return e.attributes.uid;
}

function getFormatter(key: string): (e: Node) => string {
	return TitleFormat[key] ?? defaultFormatter;
}

const LabelNameMap: Map<{
	fr: string;
	en: string;
}> = {
	Sector: { fr: "Secteur d'activité", en: "Sector" },
	Contract: { fr: "Contrat", en: "Contract" },
	ContractType: { fr: "Type de contrat", en: "Contract type" },
	Sponsor: { fr: "Partenaire", en: "Sponsor" },
	Organization: { fr: "Club", en: "Club" },
	"Organization - Championship": { fr: "Championnat", en: "League" },
};

const NotFound = { fr: "n.c.", en: "N/A" };

export function getLocalisedName(lang: "en" | "fr", key: string): string {
	const value = LabelNameMap[key];
	if (value) {
		return value[lang] ?? NotFound[lang];
	}
	return NotFound[lang];
}

export default getFormatter;
