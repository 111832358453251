import { RSetter } from "../../../../interfaces/Utils";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../../lib/fetch";

function reIndex(
	fetchOnChange: () => void,
	labels: string[],
	setLoading: RSetter<boolean>,
	userId?: string
): () => void {
	return () => {
		if (!userId) {
			// TODO: signal user ID is invalid
			console.log("no user id");
			return;
		}
		setLoading(true);

		const url = new URL(`${ROOT_API_URL}/neo/index`);
		const headers = withAuthorization(userId, newHeaders());
		const filteredLabels = labels.filter((l) => !l.match(/^IDX_/));
		if (!filteredLabels || filteredLabels.length < 1) {
			// TODO: alert user somehow
			return;
		}
		const label = filteredLabels[0];
		const body = JSON.stringify({ label: label });
		fetch(url.href, { method: "POST", headers: headers, body: body }).then((r) => {
			if (!r.ok) {
				// ALERT USER STH WENT WRONG
				const message = `COULD NOT RE-INDEX: ${r.status} - ${r.statusText}`;
				console.error(message);
				console.log(message);
				return;
			}
			setTimeout(() => {
				fetchOnChange();
				setLoading(false);
			}, 2000);
		});
	};
}

export default reIndex;
