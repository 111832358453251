import { Tag } from "antd";

import getDomainColor from "../tools/getDomainColor";
import { Map, Maybe } from "../../interfaces/Utils";
import { remove } from "../../lib/array";
import { SearchParams } from "../../model/search";
import { formattedMoment } from "./Table/filters/DatePicker";
import { searchParamsLens } from "./lenses";

interface SearchDisplayerProps {
	titles: Map<Maybe<string>>;
	search: SearchParams;
	updateSearch(s: SearchParams): void;
}

export default function SearchDisplayer(props: SearchDisplayerProps) {
	const { search, updateSearch, titles } = props;
	return (
		<>
			{Object.keys(search).reduce<JSX.Element[]>((prev, key) => {
				const values = search[key];
				const ltags = values.map((e, i) => {
					function onClick() {
						updateSearch(
							searchParamsLens(key).set(search, remove(values, i))
						);
					}
					e = formattedMoment(e) ?? e;
					const content = key === "_all" ? e : `${titles[key] ?? key} : ${e}`;
					/* content = typeof content === "string"
						? content.length > 20
							? content.substring(0, 20) + "..."
							: content
						: content; */
					return (
						<Tag
							closable
							color={key === "_all" ? getDomainColor(true) : undefined}
							onClose={onClick}
						>
							{content}
						</Tag>
					);
				});
				return [...prev, ...ltags];
			}, [])}
		</>
	);
}
