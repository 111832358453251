import { Maybe } from "../interfaces/Utils";

export type DataType =
	| 'string'
	| 'number'
	| 'array:number'
	| 'array:string'
	| 'date'
	;

export interface Filter {
	title?: string;
	clauses?: Clause[][];
}

export const unsetClause: Clause = {
	field: undefined,
	type: undefined,
	include: "include",
	op: undefined,
	value: undefined,
};

export interface Clause {
	field?: string;
	type?: DataType;
	include?: boolean | string;
	op?: string;
	value?: any;
}

export function validClause(c: Clause): boolean {
	return c.field !== undefined
		&& c.type !== undefined
		&& c.op !== undefined
		&& c.value !== undefined;
}

export function getClause(clauses: Maybe<Clause[][]>, keys: [number, number]): Maybe<Clause> {
	if (!clauses) {
		return undefined;
	}
	const [outer, inner] = keys;
	const outerC = clauses[outer];
	return !outerC
		? undefined
		: outerC[inner];
}
