import { useState, useEffect, MutableRefObject } from "react";

import { Button, message } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";

import _ from "lodash";
import moment from "moment";

import ExportCSV from "./ExportCSV";

import { Map, RDSA } from "../../../../interfaces/Utils";
import { ActionPayload, direxport, ExporterReturn } from "./HeaderButtons";

async function saveData(
	data: any[],
	id: string,
	userId?: string
): Promise<ExporterReturn> {
	await direxport(userId, id, {
		edited: [],
		created: data,
		deleted: [],
	} as ActionPayload);
	setTimeout(function () {
		window.location.reload();
	}, 2000);
}

function validateDataType(s: string, data_type: string): [any, boolean] {
	if (s.length === 0) {
		return [undefined, true];
	}
	switch (data_type) {
		case "complete":
		case "keyword":
		case "string":
			return [s, true];
		case "integer": {
			return isNaN(+s) ? [undefined, false] : [parseInt(s), true];
		}
		case "float": {
			return isNaN(+s) ? [undefined, false] : [parseFloat(s), true];
		}
		case "date": {
			if (moment.isMoment(s)) {
				return [s, true];
			}
			const m = moment(s, ["YYYY/MM/DD", "DD/MM/YYYY"], true);
			return [m, m.isValid()];
		}
		default:
			return [s, true];
	}
}

interface HerePasteProps {
	id: string;
	userId?: string;
	headers: { title: string; dataIndex: string }[];
	keys: Map<{ data_type: string; title: string }>;
	setVisibleRef: MutableRefObject<RDSA<boolean>>;
	arrays: Map<string>;
	requiredValues: string[];
	title: string;
}
const arrayFields: { [key: string]: string } = {
	Domaine: "domaine",
	Newstank: "newstank",
};

function HerePaste(props: HerePasteProps) {
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const {
		keys,
		setVisibleRef,
		requiredValues,
		title,
		arrays: arraysMap,
		id,
		userId,
	} = props;
	const arrays = Object.keys(arraysMap).map((k) => arraysMap[k]);

	function paste(s: string) {
		let hasError = false;
		const text = s;
		const lines = text.split("\r\n");
		if (lines.length === 0) {
			message.warning("aucune donnée à ajouter.");
			return;
		}
		const table = lines.map((line) => line.split("\t"));
		const headerRow = table.splice(0, 1)[0];
		if (table.length === 0) {
			message.warning(`Il semblerait qu'il n'y a aucune ligne à ajouter.
Veuillez vérifier que vous coller aussi bien les lignes que les titres des colonnes`);
			return;
		}

		const ks = Object.keys(keys).reduce((acc, cur) => {
			const e = keys[cur];
			return {
				...acc,
				[e.title]: {
					data_type: e.data_type,
					key: cur,
				},
			};
		}, {} as Map<{ data_type: string; key: string }>);
		const datum: any[] = [];
		for (let j = 0; j < table.length; j++) {
			const line = table[j];
			const o: Map<any> = {};
			for (let i = 0; i < headerRow.length; i++) {
				const h = headerRow[i];
				const v = line[i];
				const e = ks[h];
				if (e === undefined) {
					message.error(
						`ligne ${
							j + 1
						}: donnée non prévue pour ${h}, donnée exclue de la ligne`
					);
					continue;
				}
				const rk = e.key;
				const required = requiredValues.findIndex((s) => rk === s) !== -1;
				let [value, valid] = validateDataType(v, e.data_type);
				if (!valid) {
					message.error(
						`ligne ${
							j + 1
						}: donnée invalide pour ${h}, donnée exclue de la ligne`
					);
					hasError = true;
					continue;
				} else if (value === undefined && required) {
					message.error(
						`ligne ${
							j + 1
						}: donnée manquante pour ${h}, donnée exclue de la ligne`
					);
					hasError = true;
					continue;
				}
				if (arrays.findIndex((s) => s === rk) !== -1) {
					value = [value];
				}
				if (!arrayFields[h]) {
					o[rk] = value;
				} else {
					o[arrayFields[h]] = [];
					value.split(",").forEach((v, i) => {
						o[arrayFields[h]][i] = { name: v };
					});
				}
			}
			o.key = _.uniqueId("pasted-data-");
			datum.push(o);
		}
		if (hasError) {
			message.warn(
				"Des erreurs ont été trouvées. Afin d'assurer la cohérence des données, merci de corriger ces erreurs et d'essayer à nouveau."
			);
			return;
		}
		message.info(`Nous allons ajouter ${table.length} lignes aux données.`);
		// setVisibleRef.current?.(false);
		setLoading(true);
		saveData(datum, id, userId);
	}

	useEffect(() => {
		function handler(e: Event) {
			const data = (e as ClipboardEvent)?.clipboardData?.getData("text");
			if (!data) {
				console.log("no data");
				return;
			}
			paste(data);
		}

		window.addEventListener("paste", handler);
		return () => window.removeEventListener("paste", handler);
	}, []);

	return (
		<div>
			{loading ? (
				<>
					<p>
						Ajout des données en cours{" "}
						<SyncOutlined spin style={{ color: "blue" }} />
					</p>
					<p>
						Suivant le nombre de lignes importées, cela peut prendre plusieurs
						minutes. (~1 ligne/seconde)
					</p>
				</>
			) : (
				<>
					<Button
						onClick={() => {
							navigator.clipboard.readText().then(paste);
						}}
					>
						Coller depuis le presse-papier
					</Button>
					<div>Ctrl+v votre sélection depuis Excel</div>
					<div>(cmd+v sous MacOs)</div>
					<div>
						<div>
							Clefs possibles (l'ordre n'a pas d'importance) :
							<span
								style={{ marginLeft: 10 }}
								onClick={() => setOpen(!open)}
							>
								{open ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
							</span>
						</div>
						<ul>
							{open
								? Object.keys(keys).map((h) => <li>{keys[h].title}</li>)
								: null}
						</ul>
					</div>
					<ExportCSV title={title} />
				</>
			)}
		</div>
	);
}

export default HerePaste;
