import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { Col, Checkbox, Row, Tag, Divider } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import getDomainColor from "../../../tools/getDomainColor";
import { Map } from "../../../../interfaces/Utils";
import { Displayable } from "../../../Indicators/DragTable/interface";
import { Checkable } from "../interface";

interface ColumnsSorterProps {
	titles: Map<string>;
	columns: Checkable[];
	onChange(v: Checkable[]): void;
}

interface WithId {
	id: string;
}

type DisplayWithId<T> = Displayable<T> & WithId;

function DraggableList<T>(props: { items: DisplayWithId<T>[]; onChange(v: T[]): void }) {
	const { items, onChange } = props;
	function onDragEnd(result: DropResult) {
		if (!result?.destination) return;
		const newItems = Array.from(items);
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);
		onChange(newItems.map((e) => e.value));
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{items.map(({ id, text }, index) => (
							<Draggable key={id} draggableId={id} index={index}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										{text()}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

function ColumnsSorter(props: ColumnsSorterProps) {
	const { titles, columns, onChange } = props;
	const selected: DisplayWithId<Checkable>[] = [];
	const unselected: string[] = [];
	const domainColor = getDomainColor(true);
	columns.forEach((e) => {
		const { checked } = e;
		if (checked) {
			const id = selected.length.toString(10);
			const i = selected.length;
			selected.push({
				id: id,
				text: () => {
					const { key } = e;
					const text = (
						<Checkbox
							value={key}
							checked={true}
							onChange={(_) => {
								const newElements = [
									...selected.map(({ value }) => value),
									...unselected.map((s) => ({
										key: s,
										checked: false,
									})),
								];
								newElements[i].checked = false;
								onChange(newElements);
							}}
						>
							{+id + 1}. {titles[key] ?? key}
						</Checkbox>
					);
					return (
						<Row>
							<Tag
								color={domainColor}
								style={{
									marginBottom: 5,
									width: "100%",
								}}
							>
								<MenuOutlined style={{ marginRight: 6 }} />
								{text}
							</Tag>
						</Row>
					);
				},
				value: e,
			});
		} else unselected.push(e.key);
	});

	return (
		<div style={{ maxHeight: "50vh", overflowY: "scroll", width: "100%" }}>
			<Row gutter={0}>
				<Col
					style={{
						width: "49%",
					}}
				>
					<DraggableList
						items={selected}
						onChange={(v) =>
							onChange([
								...v,
								...unselected.map<Checkable>((e) => ({
									key: e,
									checked: false,
								})),
							])
						}
					/>
				</Col>
				<Divider type="vertical" dashed style={{ height: 400 }} />
				<Col style={{ width: "47%" }}>
					{unselected.map((e, i) => {
						const text = (
							<Checkbox
								checked={false}
								value={e}
								onChange={(_) => {
									const [removed] = unselected.splice(i, 1);
									onChange([
										...selected.map(({ value }) => value),
										{ key: removed, checked: true },
										...unselected.map<Checkable>((s) => ({
											key: s,
											checked: false,
										})),
									]);
								}}
							>
								{titles[e] ?? e}
							</Checkbox>
						);
						return (
							<Row
								style={{
									marginBottom: 5,
									height: 24,
								}}
							>
								{text}
							</Row>
						);
					})}
				</Col>
			</Row>
		</div>
	);
}

export default ColumnsSorter;
