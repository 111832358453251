import { ReactNode, useEffect, useState } from "react";
import { Input, Typography, Form, Select, List, Card, Tag } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { names } from "./indicator_descriptor";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AndThen, ThunkFetch, OLD_API_URL } from "../../lib/fetch";
import { Node } from "../../interfaces/Utils";
import { capitalize } from "../../lib/strings";
import Stepper, { DescStatePair, StepProps } from "../tools/Stepper";

const { Title } = Typography;
const { Item: ListItem } = List;
const { Item } = Form;

interface IdxDescription {
	name: string;
	type: string; // reseau social, API, manuel
	NewsTank: string;
	comment: string; // comments
}

const defaultIdxDesc: IdxDescription = {
	name: "Twitter : clubs de Football",
	type: "Réseau social",
	NewsTank: "Football",
	comment: "",
};

const Descriptor = ({ state, setState, onSuccess }: DescStatePair<IdxDescription>) => {
	return (
		<Form
			style={{
				width: 732,
			}}
			name={"Indicateur"}
			layout="vertical"
			requiredMark={false}
			onFinish={(v) => {
				console.log(v);
				setState(v);
				onSuccess();
			}}
			initialValues={state}
		>
			<Title level={3}>Votre indicateur, dans les grandes lignes ?</Title>
			<Item
				label="Pour quel(s) News Tank ?"
				name="NewsTank"
				rules={[{ required: true, message: "News Tank requis" }]}
			>
				<Select mode="multiple" showArrow>
					{Object.keys(names).map((e) => (
						<Select.Option value={e}>{names[e]}</Select.Option>
					))}
				</Select>
			</Item>
			<Item
				label="Quel nom pour votre indicateur ?"
				name="name"
				tooltip={{
					title: "Ce nom servira à désigner l'indicateur en interne. Le nom affiché sur le front de News Tank sera géré à la création de chaque modélisation.",
					icon: <InfoCircleOutlined />,
				}}
				rules={[{ required: true, message: "Nom requis." }]}
			>
				<Input />
			</Item>
			<Item
				label="Quel type d'indicateur souhaitez-vous créer ?"
				name="type"
				rules={[{ required: true, message: "Type requis." }]}
			>
				<Select
					options={[
						{
							label: "Réseau social",
							value: "social-network",
						},
						{
							label: "API",
							value: "api",
						},
						{
							label: "Manuel",
							value: "manual",
						},
						{
							label: "Ne sait pas",
							value: "idk",
						},
						{
							label: "Catégorie absente de la liste ci-dessus",
							value: "missing-cat",
						},
					]}
				/>
			</Item>
			<Item
				label="Quel usage envisagez-vous pour votre indicateur ?"
				tooltip={{
					title: "Pour que l'équipe tech' puisse comprendre ce que vous souhaitez faire et adapter si besoin l'architecture des données.",
					icon: <InfoCircleOutlined />,
				}}
				name="comment"
			>
				<Input.TextArea />
			</Item>
			{/* <Item>
				<Button type='primary' htmlType='submit'>
					Valider
				</Button>
			</Item> */}
		</Form>
	);
};

// #region Labelizor connecctor
interface StateProps {}
interface DispatchProps {
	get(andThen: AndThen<string[][]>): void;
	getLabel(labels: string[], andThen: AndThen<NodesResp>): void;
}
const mapStateToProps = (): StateProps => ({});
const mapDispatchToProps = (
	reduxDispatch: ThunkDispatch<RootState, void, any>
): DispatchProps => ({
	get(andThen: AndThen<string[][]>) {
		reduxDispatch(
			ThunkFetch<string[][]>({
				url: new URL(OLD_API_URL + "/neo/label-combo"),
				andThen,
			})
		);
	},
	getLabel(labels: string[], andThen: AndThen<NodesResp>) {
		if (labels.length === 0) {
			return;
		}

		const url = new URL(OLD_API_URL + "/neo/label");
		labels.forEach((l) => url.searchParams.append("label", l));
		url.searchParams.append("strict", "true");
		reduxDispatch(ThunkFetch<NodesResp>({ url, andThen }));
	},
});

const LabelizorConnector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

interface LabelizorProps extends ConnectedProps<typeof LabelizorConnector> {}

interface NodesResp {
	count: number;
	nodes: Node[];
	page: number;
	size: number;
}

function Labelizor({ get, getLabel }: LabelizorProps) {
	const [labelsList, setLabelsList] = useState<string[][]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [nodes, setNodes] = useState<Node[]>([]);

	useEffect(() => {
		get((r) => setLabelsList(r.value ?? []));
	}, [get, setLabelsList]);

	useEffect(() => {
		if (labels.length === 0) {
			setNodes([]);
			return;
		}
		getLabel(labels, (r) => {
			setNodes(r.value?.nodes ?? []);
		});
	}, [labels, getLabel, setNodes]);

	const lbls: { nodes: ReactNode[]; labels: string[] }[] = [];
	for (let e of labelsList) {
		const labels: string[] = [];
		const tags: ReactNode[] = [];
		for (let s of e) {
			if (s.match(/^[A-Z]{3}$/)) continue;
			else if (s.match(/[A-Z]{3}_.*/)) {
				tags.push(
					<Tag
						color={s.match(/SPT_.*/) ? "#008A46" : "#00a9d2"}
						style={{ marginLeft: 12 }}
					>
						{s.replace(/[A-Z]{3}_/, "").replace(/_/g, " ")}
					</Tag>
				);
			} else labels.push(s);
		}
		if (labels.length === 0) continue;

		lbls.push({
			nodes: [labels.join(", "), ...tags],
			labels: e,
		});
	}

	return (
		<>
			<Title level={3}>Souhaitez vous intégrer des données existantes ?</Title>
			<div
				style={{
					width: "100%",
					display: "flex",
					margin: "32px 0",
				}}
			>
				<Card
					title="Labels métiers existants"
					style={{ width: "50%", height: 500, overflowY: "scroll" }}
				>
					<List
						dataSource={lbls}
						renderItem={({ nodes, labels }) => (
							<ListItem onClick={() => setLabels(labels)}>{nodes}</ListItem>
						)}
					/>
				</Card>
				<Card
					style={{
						width: "50%",
						height: 500,
						overflowY: "scroll",
						marginLeft: 24,
					}}
					title="Aperçu des données du label"
				>
					<List
						dataSource={nodes}
						renderItem={(e) => (
							<ListItem>
								<List.Item.Meta
									title={e.labels.join(" + ")}
									description={Object.keys(e.attributes)
										.filter((k) => k !== "uuid" && k !== "uid")
										.map((attrK) => (
											<p>
												{capitalize(attrK)}: {e.attributes[attrK]}
											</p>
										))}
								/>
							</ListItem>
						)}
					/>
				</Card>
			</div>
		</>
	);
}

const ConnectedLabelizor = LabelizorConnector(Labelizor);

const steps = ({
	state,
	setState,
	onSuccess,
}: DescStatePair<IdxDescription>): StepProps[] => [
	{
		title: "Définition de l'indicateur",
		content: <Descriptor state={state} setState={setState} onSuccess={onSuccess} />,
	},
	{
		title: "Données initiales 1/2",
		description: "Labels métiers.",
		content: <ConnectedLabelizor />,
	},
	{
		title: "Données initiales 2/2",
		description: "Labels référentiels.",
		content: <ConnectedLabelizor />,
	},
	{
		title: "Modèle de données",
		description: "Contraintes et relations.",
		content: "third-content",
	},
	{
		title: "Récapitulatif",
		description: "",
		content: "recap",
	},
];

function IndicatorsNew() {
	const [idxDesc, setIdxDesc] = useState(defaultIdxDesc);

	return (
		<Stepper
			steps={steps}
			desc={idxDesc}
			setDesc={setIdxDesc}
			successMessage="Votre indicateur a bien été créé !"
			errorMessage={"Nous n'avons pas pu créer votre indicateur."}
		/>
	);
}

export default IndicatorsNew;
