import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import Navigator, { TabData } from "../tools/Navigator";

function indicatorTabs(id: string): TabData[] {
	return [
		{
			key: "admin",
			label: "Administration",
			icon: <EditOutlined />,
			url: `/indicators/edit/${id}`,
		},
		{
			key: "edit",
			label: "Édition",
			icon: <SettingOutlined />,
			url: `/indicators/add/${id}`,
		},
	];
}

interface IndicatorsNavProps {
	current: "admin" | "edit";
	id: string;
}

function IndicatorNav(props: IndicatorsNavProps) {
	const { id, current } = props;
	const tabs = useMemo(() => indicatorTabs(id), [id]);
	return <Navigator defaultKey={current} tabs={tabs} />;
}

export default IndicatorNav;
