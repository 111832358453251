import { FC, useLayoutEffect, useState, createElement } from "react";

import { useLocation } from "react-router";

import { Layout as BaseLayout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { Lens } from "../../lib/lens";

import defaultState, { DLState } from "./state";
import AppMenu from "./AppMenu";
import AppLocationsMap from "./AppLocationsMaps";
import Sidebar from "./Sidebar";

import "../../css/layout/index.css";

const { Header, Sider, Content } = BaseLayout;

const collapsedLens: Lens<DLState, boolean> = {
	get: function ({ collapsed }) {
		return collapsed;
	},
	set: function (s, collapsed) {
		return { ...s, collapsed: collapsed };
	},
};

// If you want to have custom props, uncomment this line and add you own
// And comment the one after
// interface LayoutProps extends ConnectedProps<typeof connector>{ }
type LayoutProps = {};

const Layout: FC<LayoutProps> = function (props) {
	const { children } = props;
	const location = useLocation();
	const header: string | object = AppLocationsMap[location.pathname ?? "..."];
	let headerText = "";
	if (typeof header !== "string") {
		const ctx: string[] = new URLSearchParams(location.search)
			.getAll("label")
			.filter((e) => e.includes("IDX_"));
		if (ctx[0] && header[ctx[0]]) {
			headerText = header[ctx[0]];
		}
	} else {
		headerText = header;
	}

	const [state, setState] = useState(defaultState());
	const { collapsed, breakpoint, collapsedWidth, siderWidth } = state;
	function updateCollapsed(v = !collapsed) {
		setState(collapsedLens.set(state, v));
	}
	const toggle = () => updateCollapsed();
	const updateWindowDimensions = () => setState(defaultState());

	useLayoutEffect(() => {
		window.addEventListener("resize", updateWindowDimensions);
		return () => window.removeEventListener("resize", updateWindowDimensions);
	}, []);

	return (
		<BaseLayout className="AppLayout">
			<Sider
				className="AppLayout-left-sider"
				breakpoint={breakpoint}
				collapsedWidth={collapsedWidth}
				theme="light"
				onBreakpoint={updateCollapsed}
				width={siderWidth}
				collapsible
				collapsed={collapsed}
				trigger={null}
			>
				<AppMenu collapsed={collapsed} />
			</Sider>
			<BaseLayout className="AppLayout">
				<Header id="app-header" className="AppLayout-header">
					{createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: "trigger",
						onClick: toggle,
					})}
					<span className="AppLayout-header-title">{headerText}</span>
				</Header>
				{/* <AppBreadcrumbs /> */} {/* TODO: REDO Breadcrumbs */}
				<Content className="Content">{children}</Content>
			</BaseLayout>
			<Sidebar />
		</BaseLayout>
	);
};

export default Layout;
