import { Action as ReduxAction } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import Vue, { defaultSuggestions, View } from "../../model/vue";
import Search from "../../model/search";
import {
	vueLens,
	dataLens,
	suggestionsLens,
	searchLens,
	loadingLens,
	searchSortLens,
	searchParamsLens,
	widgetSearchParamsLens as wsp,
} from "../../components/Widget/lenses";
import {
	SET_ACCEPTED_SUGGESTION,
	SET_DATA,
	SET_ERROR,
	SET_LOADING,
	SET_SEARCH,
	SET_SORTING,
	SET_SUGGESTIONS,
	SET_VUE,
	SET_VUE_AND_DATA,
} from "./actions";
import {
	Action,
	DataResponse,
	setVueAndData,
	WidgetState as _WidgetState,
} from "./interfaces";
import { WidgetStore } from "../../components/Widget";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { loginFailedAction } from "../login";
import { Indicator } from "../../components/Indicators/Management/interface";
import { Map } from "../../interfaces/Utils";

export type WidgetState = _WidgetState;
export interface MinimalWidgetState {
	widget: WidgetState;
}
export const defaultSearch: Search = {};

const initialState: WidgetState = {};

async function fetchT<T>(
	userId: string,
	urlSuffix: string,
	headers?: Record<string, string>,
	params?: Map<string>,
): Promise<T> {
	const url = new URL(ROOT_API_URL + urlSuffix);
	if (params !== undefined) {
		for (let key in params) {
			const value = params[key];
			url.searchParams.append(key, value)
		}
	}

	const headers_ = headers ?? withAuthorization(userId, newHeaders());
	return fetch(url.href, { headers: headers_ }).then(async (r) => {
		if (r.ok) {
			try {
				const b: T = await r.json();
				return b;
			} catch {
				throw `${urlSuffix} not reached: CODE: ${r.status}`;
			}
		}
		throw `${urlSuffix} not reached: CODE: ${r.status}`;
	});
}

function fetchWidgetData(id: string, userId: string) {
	const url = new URL(`${ROOT_API_URL}/api/v1/widget/${id}`);
	const headers = withAuthorization(userId, newHeaders());
	return fetch(url.href, { headers: headers }).then(async r => {
		if (r.ok) {
			const { data: { idx, view, } }: {
				data: {
					idx: Indicator,
					view: View,
					project: Map<any>,
				}
			} = await r.json();
			return { idx: idx, view: view };
		}
	})
}

function fetchallhere(id: string, userId: string, options?: { editable?: boolean, lang?: string }) {
	const params: Map<string> = { uid: id };
	if (options?.editable === true) {
		params["_with-uid"] = "true";
	}
	if (options?.lang) {
		params["_lang"] = options?.lang;
	}

	return Promise.all([
		fetchT<DataResponse>(userId, "/api/v1/data", undefined, params),
		fetchWidgetData(id, userId),
	]);
}

export type WidgetThunkAction = ThunkAction<void, WidgetStore, null, ReduxAction<any>>;
export function ThunkGetVueAndData(id: string, options?: { editable?: boolean, lang?: string }): WidgetThunkAction {
	return async function (dispatch, getState) {
		const {
			login: { userId },
		} = getState();
		if (!userId) {
			dispatch(loginFailedAction(401));
			return;
		}

		const datum = await fetchallhere(id, userId, options).catch(err => {
			return { err: err };
		});
		if ("err" in datum) {
			dispatch(({ type: SET_ERROR, payload: { err: datum.err } }));
			return
		}
		const [data, widgetData] = datum;
		const { idx: ridx, view: rvue } = widgetData ?? {};
		if (!ridx || !rvue) {
			return;
		}
		const rdata = data.data;
		const v: Vue = {
			id: rvue.uid ?? ridx.uid,
			title: rvue.title ?? ridx.title,
			type: rvue.type,
			params: rvue.search_params ?? {},
			default_columns: rvue.default_columns ?? ridx.default_columns,
			keys: ridx.key,
			rawView: rvue,
			rawIdx: ridx,
		};
		dispatch(setVueAndData(v, rdata));
	};
}

export const searchReducer = (
	state: WidgetState = initialState,
	action: Action
): WidgetState => {
	if (!action) {
		return state;
	}
	switch (action.type) {
		case SET_VUE_AND_DATA: {
			const { vue, data } = action.payload;
			return { ...state, vue: vue, data: data };
		}
		case SET_VUE: {
			return vueLens.set(state, action.payload.vue);
		}
		case SET_DATA: {
			return dataLens.set(state, action?.payload?.data);
		}
		case SET_SUGGESTIONS: {
			const suggs = action?.payload?.suggestions;
			return suggestionsLens.set(state, suggs ?? defaultSuggestions);
		}
		case SET_SORTING: {
			return searchSortLens.set(state, action?.payload?.sorting);
		}
		case SET_SEARCH: {
			return searchLens.set(state, action?.payload?.search ?? defaultSearch);
		}
		case SET_LOADING: {
			return loadingLens.set(state, !!action?.payload?.loading);
		}
		case SET_ACCEPTED_SUGGESTION: {
			const param = action?.payload?.param;
			if (!param) return state;
			const { key, value } = param;
			// first remove suggestions from the state
			const s1 = suggestionsLens.set(state, defaultSuggestions);
			// then set the new values in the params
			const paramsLens = searchParamsLens(key);
			const searchParams = wsp.get(s1);
			const oldParams = paramsLens.get(searchParams);
			const allParams = [...(oldParams || []), ...value];
			const newParams = paramsLens.set(searchParams, allParams);
			// and update the state with the accepted suggestions
			return wsp.set(s1, newParams);
		}
		case SET_ERROR: {
			return {
				...state,
				err: action?.payload?.err,
				loading: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default searchReducer;
