import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";

const defaultProps = {
	lang: 'fr',
};

type UniqueSearchBarProps = {
	all: string[];
	setAll: (all: string[]) => void;
	lang?: string;
} & typeof defaultProps;

function UniqueSearchBar(props: UniqueSearchBarProps) {
	const { all, setAll, lang } = props;
	const [buf, setBuf] = useState("");
	const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setBuf(value);
	const onPressEnter = (_: any) => {
		if (all.find((e) => e === buf)) return;
		setBuf("");
		setAll([...all, buf]);
	};

	const helpText = lang === 'en'
		? <>To search by column use the <SearchOutlined /> at the top of each column.</>
		: <>Pour une recherche par colonne utilisez les <SearchOutlined /> en tête de chaque colonne.</>;

	return (
		<div>
			<Input
				placeholder={
					lang === 'en'
						? "Search all fields"
						: "Rechercher dans tous les champs ..."
				}
				value={buf}
				style={{ minWidth: 450, maxWidth: 600, marginBottom: 6, marginTop: 6 }}
				onChange={onChange}
				onPressEnter={onPressEnter}
			/>
			<div>
				{helpText}
			</div>
		</div>
	);
}

UniqueSearchBar.defaultProps = defaultProps;

export default UniqueSearchBar;
