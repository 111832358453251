import _uniqueId from "lodash/uniqueId";

import { Maybe } from "../../interfaces/Utils";
import { Suggestions } from "../../model/vue";

interface SuggTagProps {
	index: string;
	suggestions: Maybe<Suggestions>;
	updateSugg(v: string): void;
}

function TagRenderer({ suggestions, updateSugg, index }: SuggTagProps) {
	if (!suggestions || suggestions.index !== index) return null;
	const onClick: (val: string) => React.MouseEventHandler<HTMLSpanElement> =
		(val: string) => (_) =>
			updateSugg(val);

	return (
		<div style={{ width: 250 }}>
			{suggestions.values
				.sort(({ doc_count: acount }, { doc_count: bcount }) => bcount - acount)
				.map(({ key, doc_count }, i) => (
					<div
						style={{ cursor: "pointer" }}
						onClick={onClick(key)}
						key={_uniqueId("ntn_tag_suggs-")}
					>
						{`${key} (~${doc_count})`}
					</div>
				))}
		</div>
	);
}

export default TagRenderer;
