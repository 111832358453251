import { AutoComplete, Select, Tag } from "antd";
import { uniqueId, values } from "lodash";
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { mapper, Opt } from ".";
import { Map, Maybe } from "../../../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../../lib/fetch";
import { RenderArg, RenderValueMRef, TagArgs } from "./interface";

export default function renderer(
	key: string,
	data: Map<any> | Map<any>[],
	args: TagArgs,
	disabled: boolean,
	rs: RenderValueMRef
): JSX.Element {
	const value = args?.renderer?.value ?? ".";
	const label = args?.renderer?.label ?? value;
	const resultType = args?.result ?? "object";

	const dat: Map<any>[] = Array.isArray(data) ? data : data === undefined ? [] : [data];
	const mapperF = mapper(label, value);
	const [state, setState] = useState<{
		values: Opt[];
		options: Opt[];
		buf: string;
	}>({
		values: dat?.map(mapperF) ?? [],
		options: [],
		buf: "",
	});
	const { values: vls, options: opts } = state;
	rs.current[key] = () =>
		state.values.map((v) =>
			resultType === "object" ? { code: v.value, name: v.label } : v.value
		);
	const userId = useStore().getState()?.login?.userId as Maybe<string>;
	if (!userId) {
		return <div>Session expirée...</div>;
	}
	return (
		<div style={{ width: "100%" }}>
			{vls.map(({ label, value }) => (
				<Tag
					closable={!disabled}
					onClose={(e) =>
						setState({
							options: [],
							buf: "",
							values: [...vls.filter((v) => v.value !== value)],
						})
					}
					key={uniqueId(`select-${key}`)}
				>
					{label}
				</Tag>
			))}
			<AutoComplete
				disabled={disabled}
				placeholder="Nom de domaine à ajouter"
				value={state.buf}
				options={opts}
				style={{ width: "100%" }}
				onChange={(v) => setState({ buf: v, options: opts, values: vls })}
				onSelect={(v) => {
					const val = opts.find((opt) => opt.value === v);
					if (val !== undefined) {
						setState({
							options: [],
							buf: "",
							values: [...vls, val],
						});
					}
				}}
				onSearch={(v) => {
					if (v.length < 3) {
						return;
					}
					const url = new URL(`${ROOT_API_URL}/api/v1/sugg/node`);
					const labels = Array.isArray(args.suggest.labels)
						? args.suggest.labels
						: [args.suggest.labels];
					url.searchParams.append(
						"labels",
						labels.map((s) => s.toLowerCase()).join("_")
					);
					url.searchParams.append(
						"search",
						window.btoa(`${args.suggest.match}=${v}`)
					);
					const headers = withAuthorization(userId, newHeaders());
					fetch(url.href, { headers: headers }).then(async (r) => {
						if (r.ok) {
							const resp: Map<any>[] = await r.json();
							const lopts = resp.map(mapperF).map((o) => ({
								...o,
								key: uniqueId("opt-tags-"),
							}));
							setState({
								values: vls,
								options: lopts,
								buf: v,
							});
						} else {
							console.error(`oups: ${r.status}`);
						}
					});
				}}
			/>
		</div>
	);
}
