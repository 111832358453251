import { Row, Typography } from "antd";

import { Maybe, RSetter } from "../../../interfaces/Utils";
import { PayloadError, ProjectBuilderData } from "./interfaces";
import ViewsUpload from "./ViewsUpload";

const { Title } = Typography;

interface ProjectVuesProps {
	viewErrors: Maybe<PayloadError[]>;
	projectId: Maybe<string>;
	userId: string;
	state: ProjectBuilderData;
	setState: RSetter<ProjectBuilderData>;
}
function ProjectVues(props: ProjectVuesProps) {
	const {
		state,
		viewErrors,
		setState,
		projectId,
		userId,
	} = props;

	return (
		<>
			<Row justify="space-between">
				<div id="views">
					<Title level={3}>Visualisations du projet</Title>
				</div>
			</Row>
			<Row justify="start" align="middle">
				<ViewsUpload
					state={state}
					setState={setState}
					projectId={projectId}
					userId={userId}
				/>
			</Row>
			{viewErrors && viewErrors.length > 0
				? viewErrors.map((e) => {
					const { msg, uid } = e;
					return (
						<div style={{ color: "red", fontSize: "90%" }}>
							<i>
								Erreur: la vue <b>{uid}</b> {msg}
							</i>
						</div>
					);
				})
				: null}
		</>
	);
}

export default ProjectVues;
