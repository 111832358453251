import { AutoComplete, Input, message } from "antd";
import { trim, uniqueId } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { useStore } from "react-redux";
import { mapper, Opt } from ".";
import { Map, Maybe } from "../../../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../../lib/fetch";
import getDomainColor from "../../../../tools/getDomainColor";
import { RenderValueMRef, SingleArg, Suggest } from "./interface";

const { TextArea } = Input;

const textLengthThreshold = 50;
const spanStyle = { color: getDomainColor(false) };

function parseValue(data: any, args: Maybe<SingleArg>): string {
	const value =
		args !== undefined && args.defaultValue === data
			? args.onDefault === "replace"
				? args.replace ?? ""
				: ""
			: data;
	let s = "";
	if (typeof value !== "string") {
		if (value === null || value === undefined) {
			s = "";
		} else {
			s = "toString" in value ? value.toString?.() ?? "" : `${value}`;
		}
	} else {
		s = value;
	}
	return s;
}

function DefaultWithSuggestion(props: {
	suggs: Suggest;
	disabled: boolean;
	setValue: Dispatch<SetStateAction<string>>;
	mapperF: ReturnType<typeof mapper>;
	value?: string;
}) {
	const { suggs, disabled, setValue, mapperF, value } = props;
	const [state, setState] = useState<{
		options: Opt[];
		buf: string;
	}>({ options: [], buf: "" });
	const { options, buf } = state;
	const userId = useStore().getState()?.login?.userId as Maybe<string>;

	if (!userId) {
		return <>ID utilisateur invalide, merci de vous reconnecter.</>;
	}

	const uid = window.location.pathname.split("/").at(-1);

	const labels = Array.isArray(suggs.labels) ? suggs.labels : [suggs.labels];
	const isCommune =
		uid === "2a028238-5e9a-4d7b-9610-7e227b4e863b" && labels[0] === "COMMUNE";
	return (
		<AutoComplete
			defaultValue={value}
			style={{ minWidth: 100, width: "100%" }}
			options={options}
			disabled={disabled}
			onSelect={(v) => {
				setState({
					options: [],
					buf: v,
				});
				setValue(v);
			}}
			onSearch={async (v) => {
				if (v.length < 3) {
					setState({
						options: [],
						buf: v,
					});
					return;
				}
				const url = new URL(`${ROOT_API_URL}/api/v1/sugg/node`);
				url.searchParams.append(
					"labels",
					labels.map((s) => s.toLowerCase()).join("_")
				);
				url.searchParams.append("search", window.btoa(`${suggs.match}=${v}`));
				const headers = withAuthorization(userId, newHeaders());
				fetch(url.href, { headers: headers }).then(async (r) => {
					if (r.ok) {
						const resp: Map<any>[] = await r.json();
						const lopts = resp.map(mapperF).map((o) => ({
							...o,
							key: uniqueId("opt-tags-"),
							label: /* isCommune
								? `${o.data.label}${
										o.data.code ? ` (${o.data.code})` : ""
								  }`
								: */ o.label,
						}));
						setState({
							options: lopts,
							buf: v,
						});
					} else {
						console.error(`oups: ${r.status}`);
					}
				});
			}}
		/>
	);
}

export default function renderer(
	key: string,
	data: any,
	args: Maybe<SingleArg>,
	disabled: boolean,
	rs: RenderValueMRef
) {
	const [value, setValue] = useState(parseValue(data, args));
	rs.current[key] = () => value;
	if (args?.suggest) {
		const v = args?.renderer?.value ?? ".";
		const label = args?.renderer?.label ?? v;
		return (
			<DefaultWithSuggestion
				suggs={args.suggest}
				disabled={disabled}
				setValue={setValue}
				mapperF={mapper(label, v)}
				value={value}
			/>
		);
	}
	return value.length >= textLengthThreshold ? (
		<TextArea
			onChange={(v) => setValue(v.target.value)}
			value={value}
			disabled={disabled}
		/>
	) : (
		<Input
			onChange={(v) => setValue(v.target.value)}
			value={value}
			disabled={disabled}
		/>
	);
}
