function splitLoc(s: string): [string, string] {
	const reg = /(_FR|_fr|_EN|_en)$/;
	const match = s.match(reg);
	if (match !== null) {
		const index = match.index ?? s.length - 1 - 3;
		return [s.slice(0, index), s.slice(index)];
	}
	return [s, ""];
}
const locOrdering = ["_FR", "_EN"];

export function compareLocated(a: string, b: string): number {
	const [aval, aloc] = splitLoc(a);
	const [bval, bloc] = splitLoc(b);

	if (aval === bval) {
		if (aloc === "" || bloc === "") {
			return a.localeCompare(b);
		}
		const aindex = locOrdering.indexOf(aloc);
		const bindex = locOrdering.indexOf(bloc);
		return aindex - bindex;
	} else {
		return a.localeCompare(b);
	}
}
