export const CLASSNAMES = Object.freeze({
    GEDATA_NOT_LOADED: "gedata-not-loaded",
    REACT_ROOT_UNLOADED: "gedata-not-loaded",
    GEDATA_WIDGET: "js_gedata-widget",
    GEDATA_ROOT: "gedata-root",
    GEDATA_REACT_ROOT: "gedata-react-root",
});

export const ERRORS = Object.freeze({
    invalidID: "Identité invalide ou non reconnue",
    invalidResource: "Ressource invalide ou non reconnue",
});

export const DATA_ATTRIBUTES = Object.freeze({
    ID: "data-id",
    Token: "data-token",
    Lang: "data-lang",
});

export const _classesForInjection = [
    CLASSNAMES.GEDATA_ROOT,
    CLASSNAMES.REACT_ROOT_UNLOADED,
    CLASSNAMES.GEDATA_WIDGET,
];

export const classesForInjection = _classesForInjection.join(" ");
export const TOKEN_GENERATOR_TEMPLATE = "[gedata-token]";

export const isWidget = (process?.env.REACT_APP_IS_WIDGET ?? false) === "true";
export const testWidget = (process?.env.REACT_APP_TEST_WIDGET ?? false) === "true";
export const tokenId = process?.env?.REACT_APP_TOKEN_ID;
export const widgetId = process?.env?.REACT_APP_WIDGET_ID;
