import { useState, useEffect, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Button, Row, Spin, Result as AntdResult } from "antd";
import { ExceptionStatusType } from "antd/lib/result";
import { SmileOutlined } from "@ant-design/icons";

import { loginFailedAction } from "../../../store/login";
import { MinimalLoginState } from "../../../store/login/interface";
import { Maybe } from "../../../interfaces/Utils";
import {
	AllowOrigin,
	newHeaders,
	ROOT_API_URL,
	withAuthorization,
} from "../../../lib/fetch";
import { Error, FlagDescription, IndicatorData, NeoIndicator } from "./interface";

import Result from "../../Result";

import CardInformations from "./CardInformations";
import CardStructure from "./CardStructure";
import CardTranslate from "./CardTranslate";

import "../../../css/indicators/management.css";
import IndicatorsNav from "../IndicatorsNav";
import { TimedResponse } from "../../../store/widget/interfaces";
import { Indicator, MPublication } from "../interface";
import CardPublicationForm from "./CardPublicationForm";
import indicatorData, { IndicatorMoreData } from "./indicatorMoreData";

interface Answer<T> {
	code: number;
	value?: T;
	error?: string;
}

// #region call to back
function fetchNeoApi(userId: Maybe<string>): <T>(url: URL) => Promise<Answer<T>> {
	return async function <T>(url: URL): Promise<Answer<T>> {
		if (!userId) {
			return {
				code: 401,
				error: "Unauthorized",
			};
		}

		const headers = {
			...AllowOrigin,
			Authorization: userId,
		};

		return fetch(url.href, { headers: headers }).then(async (r) => {
			if (!r.ok) {
				return {
					code: r.status,
					error: r.statusText,
				};
			}

			try {
				return {
					code: r.status,
					value: await r.json(),
				};
			} catch (e: any) {
				return {
					code: r.status,
					error: e.toString(),
				};
			}
		});
	};
}
// #endregion

// !TODO: @Clemence Peut-être un `Skeleton` à la place quand tu auras le temps
function LoadingOrBack() {
	const h = useHistory();

	const innerButton = (
		<Button type="primary" onClick={h.goBack}>
			Retour
		</Button>
	);

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<AntdResult
				icon={<SmileOutlined />}
				title="Vos données sont en train d'être chargées."
				extra={innerButton}
			/>
			<Spin size="large" />
		</div>
	);
}

function IndicatorManagement() {
	const { id } = useParams<{ id: string }>();
	const {
		login: { userId },
	} = useStore<MinimalLoginState>().getState();
	const [err, setErr] = useState<Error>();
	const [state, setState] = useState<{
		cleanliness: IndicatorData;
		indicator: NeoIndicator;
		flags: FlagDescription[];
	}>();
	const [idxData, setIdxData] = useState<Maybe<Indicator>>(undefined);
	const reduxDispatch = useDispatch();
	const fetcher = fetchNeoApi(userId);
	const refOnFinish = useRef<(dates: MPublication) => void>();

	useEffect(() => {
		fetcher<NeoIndicator>(new URL(`${ROOT_API_URL}/neo/idx/${id}`)).then(
			({ code, value, error }) => {
				switch (code) {
					case 401: {
						reduxDispatch(loginFailedAction(401));
						return;
					}
					case 200: {
						if (!value) {
							setErr({ code: 500, message: "Donnée(s) manquante(s)." });
							return;
						}
						const { idx_label } = value;
						const flagURL = new URL(`${ROOT_API_URL}/neo/clean/flag`);
						const idxUrl = new URL(`${ROOT_API_URL}/neo/assert-indicator`);
						flagURL.searchParams.append("idx", idx_label);
						idxUrl.searchParams.append("idx", idx_label);
						Promise.all([
							fetcher<FlagDescription[]>(flagURL),
							fetcher<IndicatorData>(idxUrl),
						]).then(([flags, idx]) => {
							if (flags.error || !flags.value) {
								setErr({
									code: flags.code,
									message: flags.error || "flags manquant",
								});
								return;
							}
							if (idx.error || !idx.value) {
								setErr({
									code: idx.code,
									message: idx.error || "definition de l'indicateur",
								});
								return;
							}
							setState({
								indicator: value,
								flags: flags.value,
								cleanliness: idx.value,
							});
						});
						return;
					}
					default: {
						setErr({ code: code, message: error ?? "" });
					}
				}
			}
		);
		const url = new URL(`${ROOT_API_URL}/api/v1/doc/idx/${id}`);
		const headers = withAuthorization(userId ?? "", newHeaders());
		fetch(url.href, { headers: headers }).then(async (r) => {
			switch (r.status) {
				case 401:
					reduxDispatch(loginFailedAction(401));
					return;
				case 200:
					const { data }: TimedResponse<Indicator, never> = await r.json();
					setIdxData(data);
					return;
				default:
					console.error(`answer error: ${r.status}: ${r.statusText}`);
			}
		});
	}, []);

	if (!id) {
		return <Result status={404} />;
	}
	if (!userId) {
		return <Result status={403} />;
	}
	if (err) {
		return <Result status={err.code as ExceptionStatusType} message={err.message} />;
	}
	if (!state) {
		return <LoadingOrBack />;
	}

	const { indicator, cleanliness, flags } = state;
	const rcleanliness = indicatorData[indicator.idx_label].cleanliness ?? cleanliness;
	return (
		<>
			<Row style={{ height: 56, width: "100%" }}>
				<IndicatorsNav current="admin" id={indicator.uid} />
				{/* <Button
					type="primary"
					href={`/indicators/add/${id}`}
					style={{ margin: "auto 0" }}
				>
					Ajouter des données
				</Button> */}
				{/* REACTIVATE */}
				{/* <Button
					type="default"
					href="/visualisations/vue/list"
					style={{ margin: "auto 8px" }}
				>
					Infographies de l'indicateur
				</Button> */}
				{/* REACTIVATE */}
				{/* <Button
					type="default"
					href="/visualisations/vue/new"
					style={{ margin: "auto 8px" }}
				>
					Créer une infographie
				</Button> */}
			</Row>
			<Row>
				<CardInformations
					id={id}
					userId={userId}
					indicator={indicator}
					flags={flags}
				/>
				<div className="exception">
					<CardStructure
						id={id}
						cleanliness={rcleanliness}
						labels={
							indicatorData[indicator.idx_label].labels ??
							state?.cleanliness.labels.map((l) => l.labels)
						}
						userId={userId}
						indicator={indicator}
					/>
					{/* REACTIVATE */}
					{/* <CardHistory /> */}
				</div>
				{/* REACTIVATE */}
				{/* <CardSetRules id={id} userId={userId} /> */}
				{/* REACTIVATE */}
				{/* <CardAdmin id={id} indicatorId={indicator.uid} /> */}
				{indicator.idx_label === "IDX_SPT_DEALTRACKER" ? (
					<div>
						<CardTranslate userId={userId} />
					</div>
				) : null}
			</Row>
		</>
	);
}

export default IndicatorManagement;
