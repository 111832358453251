import { useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { Collapse, Result, Space, Typography } from "antd";

import { loginFailedAction } from "../../../store/login";
import { LoginState, MinimalLoginState } from "../../../store/login/interface";
import { SidebarState } from "../../../store/sidebar";
import { infoSetTriple, InfoState, MinimalInfoState } from "../../../store/infographics";
import Vue, { Key, VueData } from "../../../model/vue";
import { Map, Maybe } from "../../../interfaces/Utils";
import ftb_ from "../../Indicators/json/ftb.json";
import { Indicator } from "../../Indicators/Management/interface";
import { ConnectedDataWidget } from "../../Widget/DataWidget";
import InfographicDescription from "./InfographicDescription";
import DataSourceFilter from "./Filters";
import DataSourceOrder from "./DataSourceOrder";
import { getTriple } from "..";
import { VueTriple } from "../interface";
import DataSourceSorter from "./DataSourceSorter";

const { Title } = Typography;

const labelMap: Map<string> = ftb_.labelsMap;
const { conversions } = ftb_;

type InfoWithLoginState = MinimalInfoState & MinimalLoginState;

export interface CurrentStore {
	login: LoginState;
	info: InfoState;
	sidebar: SidebarState;
}

function filterDefaultColumns(
	default_columns: Maybe<string[]>,
	newColums: string[]
): Maybe<string[]> {
	return default_columns?.filter((c) => newColums.indexOf(c) !== -1);
}
function filterKeys(keys: Map<Key>, newColums: string[]): Map<Key> {
	return Object.keys(keys).reduce<Map<Key>>(
		(acc, cur) =>
			newColums.indexOf(cur) !== -1 ? { ...acc, [cur]: keys[cur] } : acc,
		{}
	);
}

// #region store connection
interface StateProps {
	uid: Maybe<string>;
	checked: React.Key[];
	userId: Maybe<string>;
	vue?: Vue;
	idx?: Indicator;
	data?: VueData;
	type?: Maybe<string>;
}
function mapStateToProps(state: InfoWithLoginState): StateProps {
	const {
		info: { checked, props, vueTriple },
		login: { userId },
	} = state;
	return {
		checked: checked,
		uid: props?.definition?.uid,
		userId: userId,
		vue: vueTriple?.vue,
		idx: vueTriple?.idx,
		data: vueTriple?.data,
		type: props?.definition?.idx_label,
	};
}

interface DispatchProps {
	setTriple: (triple?: VueTriple) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setTriple: function (triple?: VueTriple): void {
			dispatch(infoSetTriple(triple));
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type StepInfCustomProps = ConnectedProps<typeof connector>;
// #endregion

function StepInfCustom(props: StepInfCustomProps) {
	// Les colonnes sont définies dans le fichier ftb.json
	const { userId, checked, uid: puid, setTriple, vue, idx, data, type } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		if (!userId) {
			dispatch(loginFailedAction(401));
			return;
		}
		if (!puid || (!!vue && !!idx && !!data)) {
			return;
		}

		getTriple(puid, userId).then((rsp) => {
			if (rsp) {
				setTriple(rsp);
			}
		});
	}, [puid]);

	if (!userId) {
		dispatch(loginFailedAction(401));
		return <div>Forbidden</div>;
	}

	if (!vue || !idx || !data) {
		return <Result title="Merci de patienter..." />;
	}

	const newColums = [];
	let uid = "";
	let dw: Maybe<JSX.Element>;

	if (!!data && !!vue && !!idx) {
		for (let i = 0; i < checked.length; i++) {
			const cur = checked[i];
			if (typeof cur === "string") {
				const split = cur.split(".");
				if (split.length === 3) {
					const [_uid, labels, key] = split;
					uid = uid || _uid;
					const cleanlabels = labels
						.split(",")
						.filter((s) => !(s.match(/^[A-Z]{3}_/) && !s.startsWith("IDX_")))
						.sort()
						.join(".");
					const realPath = conversions[`${labelMap[cleanlabels]}.${key}`];
					newColums.push(realPath);
				}
			}
		}

		const lvue = {
			...vue,
			default_columns: filterDefaultColumns(vue.default_columns, newColums),
			keys: filterKeys(idx.key, newColums),
			params: {
				...(vue.params || {}),
				hideCol: true,
			},
		};

		dw = (
			<ConnectedDataWidget
				id={uid}
				userId={userId}
				color="#722ed1"
				vue={lvue}
				data={data}
			/>
		);
	}

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<InfographicDescription />
			<Space direction="vertical">
				<Title level={4}>Paramétrage du tableau</Title>
				<DataSourceFilter type={type} />
				<DataSourceOrder />
				<DataSourceSorter />
			</Space>
			{/* {dw} */}
		</Space>
	);

	// return (
	// 	<Collapse defaultActiveKey={["1"]}>
	// 		<Panel key="0" header="Visuel">
	// 			{dw}
	// 		</Panel>
	// 		<Panel key="1" header="Informations sur l'infographie">
	// 			<InfographicDescription />
	// 		</Panel>
	// 		<Panel key="2" header="Configuration des données">
	// 			<Space direction="vertical">
	// 				<DataSourceFilter />
	// 				<DataSourceOrder />
	// 				<DataSourceSorter />
	// 			</Space>
	// 		</Panel>
	// 	</Collapse>
	// );
}

export default connector(StepInfCustom);
