import { useEffect, useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import _uniqueId from "lodash/uniqueId";

import { Pagination, Result, Row } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { Fetched, TimedResponse } from "../../store/widget/interfaces";
import { loginFailedAction } from "../../store/login";
import { Maybe } from "../../interfaces/Utils";
import { Sorting } from "../../model/search";
import { Project } from "./interface";
import ProjectDisplay from "./Display";

import "../../css/projects/list.css";
import DateSelector, { dateKeys } from "../tools/DateSelector";
import MaybeSpinner from "../tools/MaybeSpinner";

const dateDefaultKey = 1;
const defaultSize = 10;
const defaultSorting: Sorting = { key: dateKeys[dateDefaultKey].value, ordering: "desc" };

function respToErr(r: Response): string {
	return `${r.status}: ${r.statusText}`;
}

function ProjectList() {
	const store = useStore();
	const [state, setState] = useState<{
		projects?: Project[];
		loading?: boolean;
		sorting: Sorting;
		err?: string;
		page?: number;
		pageSize?: number;
		total?: number;
	}>({ sorting: defaultSorting, });
	const {
		sorting: { key },
		loading,
		err,
		total, page, pageSize,
	} = state;
	const userId: Maybe<string> = store.getState().login?.userId;
	const dispatch = useDispatch();

	function setSorting(sorting: Sorting) {
		setState({ ...state, sorting: sorting });
	}
	const setter = (value: string) => setSorting({
		...state.sorting,
		key: value,
	});
	const setterRef = useRef(setter);
	useEffect(() => {
		if (!userId) {
			dispatch(loginFailedAction(401));
			return;
		}

		setState({ ...state, loading: true });
		const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
		url.searchParams.append("_sort", state.sorting.key);
		const headers = withAuthorization(userId, newHeaders());
		if (page) {
			url.searchParams.append("_page[offset]", `${page - 1}`);
		}
		if (pageSize) {
			url.searchParams.append("_page[size]", `${pageSize}`);
		}
		fetch(url.href, { headers: headers }).then(async (r) => {
			if (r.ok) {
				const payload: TimedResponse<Fetched<Project[]>, never> = await r.json();
				const { data: { total, data: projects } } = payload;
				setState({ ...state, projects: projects, total: total });
			} else {
				if (r.status === 401) {
					store.dispatch(loginFailedAction(r.status, r.statusText));
				}
				setState({ ...state, loading: false, err: respToErr(r) });
			}
		});
	}, [state.sorting, state.page, state.pageSize]);

	if (err) {
		return (
			<Result
				title="Une erreur s'est produite"
				icon={<SmileOutlined />}
				extra={err}
			/>
		);
	}
	// const showPagination = (total !== undefined && total > (pageSize ?? defaultSize));
	const totalStyle = { width: 200, textAlign: "right" as const };
	const pagination = (
		<Pagination
			size="small"
			total={total ?? state.projects?.length ?? 0}
			current={page ?? 1}
			pageSize={pageSize ?? defaultSize}
			onChange={(ppage, ppageSize) => setState({
				...state,
				page: ppage,
				pageSize: ppageSize,
			})}
			showSizeChanger
			showTotal={(total, range) => (
				<div style={totalStyle}>
					{`${range[0]}-${range[1]} sur ${total} Visualisations`}
				</div>
			)}
		/>
	);
	return (
		<MaybeSpinner loading={loading}>
			<div className="project-content-wrapper project-list-wrapper">
				<Row
					justify='space-between'
					style={{ marginBottom: "10px" }}
				>
					{pagination}
					<DateSelector
						value={key}
						setter={setterRef}
					/>
				</Row>
				<Row justify="space-between">
					{state.projects?.map((project) => (
						<ProjectDisplay key={_uniqueId("project-")} project={project} />
					))}
				</Row>
				{pagination}
			</div>
		</MaybeSpinner>
	);
}

export default ProjectList;
