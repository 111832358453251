import { noop, uniqueId } from "lodash";
import { useStore } from "react-redux";

import { Button, message, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { View } from "../../model/vue";
import { Maybe, RSetter } from "../../interfaces/Utils";
import VisNavigator, { KEYS } from "../Projects/VisNavigator";
import MiniPreview from "./Home/MiniPreview";
import InfographicsList from "./InfographicsList";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { waitDeletion } from "../../lib/functions";

async function removeAndWait(
	userId: string,
	uid: string,
	onEnd: () => void,
	onErr: (err: string) => void
) {
	const url = new URL(`${ROOT_API_URL}/api/v1/doc/view/${uid}`);
	const headers = withAuthorization(userId, newHeaders());
	const method = "DELETE";
	const init = { headers: headers, method: method };
	const r = await fetch(url.href, init);
	if (!r.ok) {
		onErr(
			`Une erreur s'est produite durant la suppression (code #${r.status}), veuillez réessayer ultérieurement ou prévenir l'équipe Techno`
		);
		return;
	}
	waitDeletion("view", userId, uid, onEnd);
}

function miniPreviewWrapper(setLoading: RSetter<boolean>, refresh: () => void) {
	return function (view: View) {
		const {
			info: {
				private: { title, description },
			},
			uid,
			news_tank,
			idx,
			type,
			static: staticV,
			// dynamic,
		} = view;
		const userId: string = useStore().getState()?.login?.userId;
		const actions = [];
		if (
			uid !== "2665b443-9333-4b96-afd8-133560f1add8" &&
			uid !== "e41bbcb6-3c6f-4f98-9822-a3001180bb79"
		) {
			actions[0] = (
				<Popconfirm
					title="Êtes-vous sûr de vouloir supprimer cette visualisation ?"
					okText="Supprimer"
					cancelText="Annuler"
					onConfirm={() => {
						setLoading(true);
						removeAndWait(
							userId,
							uid,
							() => {
								refresh();
								setLoading(false);
							},
							(err: string) => {
								message.error(err);
								setLoading(false);
							}
						);
					}}
				>
					<Button>
						<DeleteOutlined />
						Supprimer
					</Button>
				</Popconfirm>
			);
		}

		return (
			<MiniPreview
				key={uniqueId("view-")}
				id={uid}
				title={title}
				description={description}
				indicator={idx}
				nt={news_tank}
				type={type}
				// isDefault={isDefault /** TODO: Fix it properly */}
				isStatic={!!staticV}
				dates={view?.meta?.dates}
				author={view?.meta?.author}
				actions={actions}
				filters={view?.filters}
			/>
		);
	};
}

function InfographicsRemove() {
	return (
		<>
			<VisNavigator current={KEYS.VIEWS} />
			<InfographicsList>
				{(data, setLoading, refresh) =>
					data.map(miniPreviewWrapper(setLoading, refresh))
				}
			</InfographicsList>
		</>
	);
}

export default InfographicsRemove;
