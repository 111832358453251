import { useHistory } from "react-router";
import moment from "moment";

import { message } from "antd";

import { Map, Maybe, RSetter } from "../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../lib/fetch";
import { EmptyProject } from "../interface";
import { LoginState } from "../../../store/login/interface";
import { TimedResponse } from "../../../store/widget/interfaces";
import { History, LocationDescriptor } from "history";
import { Callbackize, waitDeletion } from "../../../lib/functions";
import { ProjectBuilderData, ProjectBuilderState } from "./interfaces";
import { FetchProjectResponse } from "./fetchProject";

const errUncaught = "une erreur inattendue est survenue";

interface UpdateObject {
	id: string;
	params: Map<{
		action: string;
		value: any;
	}>;
}

interface IdPayload {
	id: string;
}

interface Options {
	urlCreator?: (projectId: string) => string;
	delay?: number;
	waitMessage?: string;
	projectInject?: Map<any>,
	noRedirect?: boolean,
	onOk?: (projectId: string) => void,
}

function saveProject(
	title: Maybe<string>,
	body: any,
	userId: string,
	h: History<unknown>,
	options?: Options,
) {
	if (!title || title === "") {
		message.error("Il manque un titre au projet.");
		return;
	}

	const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
	const headers = withAuthorization(userId, newHeaders());

	const payload = JSON.stringify(body);
	fetch(url.href, { headers: headers, body: payload, method: "POST" }).then(
		async (r) => {
			if (r.ok) {
				const body: TimedResponse<IdPayload, never> = await r.json();
				const { data: { id: projectId } } = body;
				if (options?.delay) {
					if (options?.waitMessage) {
						message.success(options.waitMessage);
					}
					options?.onOk?.(projectId);
					if (!options?.noRedirect && options?.urlCreator) {
						const urlCreator = options.urlCreator;
						setTimeout(() => {
							h.push(urlCreator(projectId));
						}, options?.delay);
					}
				} else {
					options?.onOk?.(projectId);
					if (!options?.noRedirect && options?.urlCreator) {
						h.push(options.urlCreator(projectId));
					}
				}
			} else {
				message.error(
					`Erreur lors de la sauvegarde du projet (code #${r.status}: ${r.statusText})`
				);
			}
		}
	);
}

function publishProject(ls: Maybe<LoginState>, projectID: string) {
	if (!ls || !ls.userId || !ls.userData) {
		if (!ls) {
			message.error("Il manque vos données de session");
		} else if (!ls.userId) {
			message.error("Token de session invalide");
		} else if (!ls.userData) {
			message.error("Il manque les données de votre profil");
		}
		// !TODO: silent error, please fix
		return;
	}
	const {
		userId,
		userData: { firstname, lastname },
	} = ls;
	const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
	const body = JSON.stringify({
		id: projectID,
		params: {
			"dates.published": {
				action: "set",
				value: {
					date: moment.parseZone(moment.now()).format("DD/MM/YYYY"),
					author: `${firstname} ${lastname}`,
				},
			},
		},
	} as UpdateObject);
	const headers = withAuthorization(userId, newHeaders());
	const method = "PATCH";
	fetch(url.href, { headers: headers, body: body, method: method }).then((r) => {
		if (!r.ok) {
			message.error(`${r.status}: ${r.statusText}`);
		} else {
			message.success(`Votre project bien d'être publié.`);
			setTimeout(() => window.location.reload(), 200);
		}
	});
}

function unpublishProject(userId: Maybe<string>, projectID: string) {
	if (!userId) {
		message.error("Token de session invalide");
		// !TODO: silent error, please fix
		return;
	}
	const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
	const body = JSON.stringify({
		id: projectID,
		params: {
			dates: {
				action: "unset",
				value: "published",
			},
		},
	} as UpdateObject);
	const headers = withAuthorization(userId, newHeaders());
	const method = "PATCH";
	fetch(url.href, { headers: headers, body: body, method: method }).then((r) => {
		if (!r.ok) {
			message.error(`${r.status}: ${r.statusText}`);
		} else {
			message.success(`Votre project bien d'être depublié.`);
			setTimeout(() => window.location.reload(), 200);
		}
	});
}

function deleteProject(id: string, userId: string, onOk: () => void) {
	const url = new URL(`${ROOT_API_URL}/api/v1/doc/project/${id}`);
	const method = "DELETE";
	const headers = withAuthorization(userId, newHeaders());
	fetch(url.href, { headers: headers, method: method }).then((r) => {
		if (!r.ok) {
			message.error(`Échec lors de la suppression du projet (code ${r.status}`);
		} else {
			onOk();
		}
	});
}



// reduceFilter is a `filter` doing also a "map" ; predmapper tries to extract
// a value, if present, it is added to an array, otherwise the current
// accumulator is returned
function reduceFilter<T, U>(predMapper: (t: T) => Maybe<U>) {
	return function actualReducer(acc: U[], curr: T) {
		const e = predMapper(curr);
		return e ? [...acc, e] : acc;
	}
}

function localDelete(
	projectId: string,
	userId: string,
	navigate: (location: LocationDescriptor<any>) => void,
	setLoading: RSetter<boolean>,
) {
	return function () {
		setLoading(true);
		deleteProject(
			projectId ?? "",
			userId,
			() => waitDeletion("project", userId, projectId ?? "", () => {
				setLoading(false);
				navigate("/visualisations/projects/list");
			}),
		);
	}
}

function builderFetch(state: ProjectBuilderState, setState: RSetter<ProjectBuilderState>) {
	return async function handleErrors(r: FetchProjectResponse) {
		const { err, viewErrors, project, views } = r;
		if (!err && !project) {
			// Nothing happened here, no project to fetch, nor error
			// arose during the process
			return;
		} else if (err != null) {
			// an error occured during the fetching
			setState({
				...state,
				loading: false,
				err: err,
			});
		} else {
			if (!project || !views) {
				// an uncaught error happened
				setState({
					...state,
					loading: false,
					err: errUncaught,
				});
			} else {
				setState({
					loading: false,
					data: {
						project: project,
						views: views,
						viewErrors: viewErrors,
					}
				});
			}
		}
	};
}

export {
	saveProject, publishProject, unpublishProject, deleteProject, localDelete,
	reduceFilter, builderFetch
};
