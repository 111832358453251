import { Action, combineReducers, configureStore, EmptyObject } from "@reduxjs/toolkit";
import thunk, { ThunkAction } from "redux-thunk";
import loginReducer, { } from "./login";
import { LoginState } from "./login/interface";
import sidebarReducer, { SidebarState } from "./sidebar";

const rootReducer = combineReducers({
	login: loginReducer,
	sidebar: sidebarReducer,
});

export type RootState = EmptyObject & {
	login: LoginState,
	sidebar: SidebarState,
}// ReturnType<typeof rootReducer>;

export interface BaseAction<A, P> {
	type: A;
	payload?: P;
}

export type TAction<R, A> = ThunkAction<R, RootState, null, Action<A>>;

export default configureStore({
	reducer: rootReducer,
	middleware: (m) => {
		const middleware = process.env.NODE_ENV !== 'production' ?
			[require('redux-immutable-state-invariant').default(), thunk] :
			[thunk];
		return m().concat(middleware);
		// return m().concat(thunk);
	},
});
