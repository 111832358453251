import _uniqueId from "lodash/uniqueId";
import { createElement, useState, useEffect } from "react";

import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import { EditTwoTone } from "@ant-design/icons";

import "../../../css/indicators/management.css";
import "../../../css/tools/elements.css";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../../lib/fetch";

const { Paragraph } = Typography;

interface CardTranslateProps {
	userId: string;
}

function CardTranslate(props: CardTranslateProps) {
	const { userId } = props;
	const [contractstatus, setContractstatus] = useState(false);
	const [contractvisibility, setContractvisibility] = useState(false);
	const [contracttype, setContracttype] = useState(false);
	const [sector, setSector] = useState(false);
	async function getMissing(setter: any, endofurl: string) {
		const url = new URL(`${ROOT_API_URL}/neo/missingtranslations/${endofurl}`);
		const headers = withAuthorization(userId, newHeaders());
		await fetch(url.href, { method: "GET", headers: headers }).then(async (r) => {
			if (!r.ok) {
				const message = `COULD NOT RECEIVE MISSING TRANSLATIONS`;
				console.error(message);
				console.log(message);
				return;
			}
			setter(Object.values(await r.json()).length);
		});
	}
	useEffect(() => {
		getMissing(setContractstatus, "contractstatus");
		getMissing(setContractvisibility, "contractvisibility");
		getMissing(setContracttype, "contracttype");
		getMissing(setSector, "sector");
	}, []);
	const iconElement = createElement(EditTwoTone, {
		twoToneColor: "#9254de",
		style: { marginRight: 3 },
	});

	return (
		<Card
			className="card-structure"
			title={
				<Row justify="space-between" style={{ justifyContent: "center" }}>
					<span>Traductions manquantes</span>
				</Row>
			}
			style={{ textAlign: "center" }}
		>
			<div>
				<Row gutter={0} justify="space-between">
					<Col span={10}>
						<Paragraph strong>Nature des données</Paragraph>
					</Col>
					<Col>
						<Paragraph strong>Nombre</Paragraph>
					</Col>
					<Col span={10}>
						<Paragraph strong>Action</Paragraph>
					</Col>
				</Row>
				<Row gutter={0} justify="space-between" key={_uniqueId("label-row-")}>
					<Col span={10}>
						<span>Status</span>
					</Col>
					<Col>
						<span>{contractstatus}</span>
					</Col>
					<Col span={10}>
						<Space>
							<Button
								className="discrete-link"
								type="link"
								href="/indicators/translate/contractstatus"
							>
								{iconElement}
								Corriger
							</Button>
						</Space>
					</Col>
					<Divider style={{ margin: 4 }} />
				</Row>
				<Row gutter={0} justify="space-between" key={_uniqueId("label-row-")}>
					<Col span={10}>
						<span>Visibilités</span>
					</Col>
					<Col>
						<span>{contractvisibility}</span>
					</Col>
					<Col span={10}>
						<Space>
							<Button
								className="discrete-link"
								type="link"
								href="/indicators/translate/contractvisibility"
							>
								{iconElement}
								Corriger
							</Button>
						</Space>
					</Col>
					<Divider style={{ margin: 4 }} />
				</Row>
				<Row gutter={0} justify="space-between" key={_uniqueId("label-row-")}>
					<Col span={10}>
						<span>Types de contrats</span>
					</Col>
					<Col>
						<span>{contracttype}</span>
					</Col>
					<Col span={10}>
						<Space>
							<Button
								className="discrete-link"
								type="link"
								href="/indicators/translate/contracttype"
							>
								{iconElement}
								Corriger
							</Button>
						</Space>
					</Col>
					<Divider style={{ margin: 4 }} />
				</Row>
				<Row gutter={0} justify="space-between" key={_uniqueId("label-row-")}>
					<Col span={10}>
						<span>Secteurs</span>
					</Col>
					<Col>
						<span>{sector}</span>
					</Col>
					<Col span={10}>
						<Space>
							<Button
								className="discrete-link"
								type="link"
								href="/indicators/translate/sector"
							>
								{iconElement}
								Corriger
							</Button>
						</Space>
					</Col>
					<Divider style={{ margin: 4 }} />
				</Row>
			</div>
		</Card>
	);
}

export default CardTranslate;
