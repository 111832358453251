import { useStore } from "react-redux";
import { useParams } from "react-router";
import { MinimalLoginState } from "../../store/login/interface";
import { DataWidget } from "../Widget";

function InfographicsEdit() {
	const {
		login: { userId },
	} = useStore<MinimalLoginState>().getState();
	const { id: uid } = useParams<{ id: string }>();

	return (
		<div>
			<DataWidget id={uid} userId={userId} color="#722ed1" />
		</div>
	);
}

export default InfographicsEdit;
