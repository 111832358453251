import React, { useEffect, useReducer, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router";

import { Pagination, Row, Spin } from "antd";

import _uniqueId from "lodash/uniqueId";

import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { loginFailedAction } from "../../store/login";
import { MinimalLoginState } from "../../store/login/interface";
import { RSetter } from "../../interfaces/Utils";
import { Fetched, TimedResponse } from "../../store/widget/interfaces";
import { View } from "../../model/vue";
import DateSelector, { dateKeys } from "../tools/DateSelector";
import MaybeSpinner from "../tools/MaybeSpinner";

type FetchDataResp<T> = { body: T } | { status: number };

async function fetchData<T>(
    userId: string, sort: string, page?: number, size?: number,
): Promise<FetchDataResp<T>> {
    const headers = withAuthorization(userId, newHeaders());
    const url = new URL(`${ROOT_API_URL}/api/v1/fetch/vue`);
    url.searchParams.append("_sort", `meta.${sort}`);
    if (page) {
        url.searchParams.append("_page[offset]", `${page - 1}`);
    }
    if (size) {
        url.searchParams.append("_page[size]", `${size}`);
    }
    const resp = await fetch(url.href, {
        headers: headers,
    })
    if (resp.ok) {
        const body: T = await resp.json();
        return ({
            body: body,
        });
    }
    return ({
        status: resp.status,
    });
}

type T = TimedResponse<Fetched<View[]>, never>;
const defaultSize = 10;
const dateDefaultKey = 1;

interface ViewSearchState {
    nt?: string;
    mode?: "vue" | "idx";
    page?: number;
    size?: number;
    err?: { code: number; message?: string };
    data?: any[];
    selected?: string;
    sort?: string;
    total?: number;
    loading?: boolean;
}
const defaultSort = dateKeys[dateDefaultKey].value
const defaultState: ViewSearchState = {
    sort: defaultSort,
};

const SET_NT = "SET_NT";
const SET_MODE = "SET_MODE";
const SET_PAGE = "SET_PAGE";
const SET_SIZE = "SET_SIZE";
const SET_DATA = "SET_DATA";
const SET_ERR = "SET_ERR";
const SET_SELECTED = "SET_SELECTED";
const SET_SORT = "SET_SORT";
const SET_PAGE_AND_SIZE = "SET_PAGE_AND_SIZE";
const SET_TOTAl_AND_DATA = "SET_TOTAl_AND_DATA";
const SET_LOADING = "SET_LOADING";

type ViewActions =
    | typeof SET_NT
    | typeof SET_MODE
    | typeof SET_PAGE
    | typeof SET_SIZE
    | typeof SET_DATA
    | typeof SET_ERR
    | typeof SET_SELECTED
    | typeof SET_SORT
    | typeof SET_PAGE_AND_SIZE
    | typeof SET_TOTAl_AND_DATA
    | typeof SET_LOADING
    ;

type ViewPayload = ViewSearchState;

interface Action {
    type: ViewActions;
    payload: ViewPayload;
}

const ActionsMap = {
    SET_NT: "nt",
    SET_MODE: "mode",
    SET_PAGE: "page",
    SET_SIZE: "size",
    SET_DATA: "data",
    SET_ERR: "err",
    SET_SELECTED: "selected",
    SET_SORT: "sort",
    SET_LOADING: "loading",
};

function reducer(state: ViewSearchState, action: Action): ViewSearchState {
    if (!state || !action) return state ?? defaultState;
    const { type, payload } = action;
    const updateState = (state: ViewSearchState, payload: ViewPayload, key: string) => ({
        ...state,
        [key]: (payload as any)[key],
    });

    if (type === SET_PAGE_AND_SIZE) {
        const { page, size } = payload;
        return {
            ...state,
            page: page ?? state.page,
            size: size ?? state.size,
        };
    } else if (type === SET_TOTAl_AND_DATA) {
        const { data, total } = payload;
        return {
            ...state,
            data: data,
            total: total,
        };
    }

    const key = ActionsMap[type];
    return !!key ? updateState(state, payload, key) : state;
}

interface InfographicsListProps {
    children: (
        data: any[],
        setLoading: RSetter<boolean>,
        refresh: () => void,
    ) => (React.ReactNode[] | React.ReactNode);
}

function InfographicsList(props: InfographicsListProps) {
    const {
        login: { userId },
    } = useStore<MinimalLoginState>().getState();
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { sort, page, size, loading } = state;
    const dispatcher = useDispatch();

    function predispatch(resp: FetchDataResp<T>) {
        if ("body" in resp) {
            const { body: { data: { data, total } } } = resp;
            dispatch({
                type: SET_TOTAl_AND_DATA,
                payload: { data: data, total: total },
            });
            return;
        }
        if (resp.status === 401) {
            dispatcher(loginFailedAction(resp.status));
        } else {
            dispatch({ type: SET_ERR, payload: { err: { code: resp.status } } });

        }
    }

    function updater() {
        if (!userId) {
            dispatcher(loginFailedAction(401));
            return;
        }

        fetchData<T>(userId, sort ?? defaultSort, page, size)
            .then(predispatch);
    }

    function setSort(sort: string) { dispatch({ type: SET_SORT, payload: { sort: sort } }); }
    const setterRef = useRef(setSort);

    useEffect(updater, [state.size, state.page, state.sort]);

    const { data } = state;
    if (!data) return <Spin>Loading...</Spin>;

    function setLoading(value: boolean) { dispatch({ type: SET_LOADING, payload: { loading: value } }); }

    const totalStyle = { width: 200, textAlign: "right" as const };
    const pagination = (
        <Pagination
            size="small"
            total={state.total ?? defaultSize}
            current={state.page ?? 1}
            pageSize={state.size ?? defaultSize}
            onChange={(ppage, ppageSize) =>
                dispatch({
                    type: SET_PAGE_AND_SIZE,
                    payload: {
                        page: ppage,
                        size: ppageSize,
                    },
                })
            }
            showSizeChanger
            showTotal={(total, range) => (
                <div
                    style={totalStyle}
                >{`${range[0]}-${range[1]} sur ${total} Visualisations`}</div>
            )}
        />
    );
    return (
        <MaybeSpinner loading={loading}>
            <div>
                <Row align="middle" justify="space-between" style={{ marginBottom: 8 }}>
                    <DateSelector value={sort ?? defaultSort} setter={setterRef} />
                    {pagination}
                </Row>
                <Row justify="space-between" gutter={[0, 16]} style={{ margin: "18px 0px" }}>
                    {props.children(data, setLoading, updater)}
                </Row>
                <MaybeSpinner loading={loading}>
                    <Row justify="end" style={{ marginTop: 8 }}>
                        {pagination}
                    </Row>
                </MaybeSpinner>
            </div>
        </MaybeSpinner>
    );
}

export default InfographicsList;