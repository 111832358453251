/* eslint-disable prettier/prettier */
import { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps, Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "moment/locale/fr";

import { Spin } from "antd";

import Widget from "./components/Widget";
import Auth from "./components/Auth";
import AuthRoute from "./components/Auth/AuthRoute";
import rootReducer from "./store";

import { setNewstanksAction, ThunkUserData } from "./store/login";
import NotFound from "./components/Result/NotFound";

import HomeDashboard from "./components/Dashboard";

import HomeIndicators from "./components/Indicators/Home";
import { IndicatorsNew, IndicatorsEdit } from "./components/Indicators";
import IndicatorManagement from "./components/Indicators/Management";
import { LabelPage } from "./components/Indicators/Label";
import SpecManagement from "./components/Indicators/Management/SpecManagement";
import Translate from "./components/Indicators/Management/Translate";

import Projects, { ProjectEdit } from "./components/Projects";
import { InfographicsNew, InfographicsSelect } from "./components/Infographics";

import NewsTankPreview from "./components/NewsTankPreview";
import { Maybe } from "./interfaces/Utils";
import { ROOT_API_URL } from "./lib/fetch";
import {
	LoginState,
	NewstanksInfo,
	NewstanksInfoResponse,
} from "./store/login/interface";
import InfographicsRemove from "./components/Infographics/InfographicsRemove";

// #region RootRouter connection to store
interface MinimalLogin {
	login: LoginState;
}
type StateProps = LoginState;
interface DispatchProps {
	getUserData(id: string): void;
	setNewstanks(nts: NewstanksInfo[]): void;
}
const mapStateToProps = ({ login }: MinimalLogin): StateProps => login;
const mapDispatchToProps = (
	dispatch: ThunkDispatch<MinimalLogin, void, any>
): DispatchProps => ({
	getUserData: (id: string) => {
		dispatch(ThunkUserData(id));
	},
	setNewstanks: function (nts: NewstanksInfo[]): void {
		dispatch(setNewstanksAction(nts));
	},
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type RootRouterProps = ConnectedProps<typeof connector>;
// #endregion

function assertNewstankInfos(
	nts: Maybe<NewstanksInfo[]>,
	setNewstanks: (nts: NewstanksInfo[]) => void
) {
	if (nts) {
		return;
	}

	const url = new URL(`${ROOT_API_URL}/webservice/nts`);
	fetch(url.href, {}).then(async (r) => {
		if (r.ok) {
			const ntresp: NewstanksInfoResponse = await r.json();
			setNewstanks(ntresp.data);
		}
	});
}

// function AdminHome() {
// 	return (<div>Admin Home</div>);
// }

// const adminRoutes = (
// 	<StrictRouter path='/admin'>
// 		<AdminMW>
// 			<Layout>
// 				<Switch>
// 					<Route path="/s/:id" component={Session} strict exact />
// 					<Route path="/sessions" component={Sessions} strict exact />
// 					<Route path="/" component={AdminHome} strict exact />
// 					<Route component={() => <NotFound redirection='/' />} />
// 				</Switch>
// 			</Layout>
// 		</AdminMW>
// 	</StrictRouter>
// );

const RootRouter = connector(function URootRooter({
	userId,
	userData,
	getUserData,
	newstanks,
	setNewstanks,
}: RootRouterProps) {
	if (userId && (!userData?.firstname || !userData?.lastname)) {
		getUserData(userId);
		return (
			<>
				{/* <Result
					status="403"
					title="403"
					subTitle="Nous chargeons les données manquantes, veuillez patienter."
				/> */}
				<div
					style={{
						width: "100%",
						height: "100%",
						margin: "auto",
					}}
				>
					<Spin size="large" />
				</div>
			</>
		);
	}

	useEffect(() => assertNewstankInfos(newstanks, setNewstanks), []);
	return (
		<Router basename="/">
			<Switch>
				<Route component={Auth} path="/login" />
				<AuthRoute component={Widget} path="/vue/:id" withLayout exact strict />
				<AuthRoute
					component={HomeDashboard}
					path="/dashboard"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={LabelPage}
					path="/indicators/label/edit"
					withLayout
				/>
				<AuthRoute
					component={LabelPage}
					path="/indicators/label/clean"
					withLayout
				/>
				<AuthRoute
					component={SpecManagement}
					path="/indicators/edit/spec/:id"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={Translate}
					path="/indicators/translate/:field"
					withLayout
				/>
				{/* <AuthRoute component={MetaManagement} path="/indicators/edit/meta/:id" withLayout exact strict /> */}
				<AuthRoute
					component={IndicatorsEdit}
					path="/indicators/add/:id"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={IndicatorManagement}
					path="/indicators/edit/:id"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={IndicatorsNew}
					path="/indicators/new"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={HomeIndicators}
					path="/indicators/list"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={NewsTankPreview}
					path="/visualisations/vue/preview/:id"
					exact
					strict
				/>
				<AuthRoute
					component={InfographicsNew}
					path="/visualisations/vue/edit/:id"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={InfographicsNew}
					path="/visualisations/vue/new"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={InfographicsSelect}
					path="/visualisations/vue/select"
					withLayout
					exact
					strict
				/>
				{/* <AuthRoute component={HomeInfographics} path="/visualisations/vue" withLayout exact strict /> */}
				{/* <AuthRoute component={ProjectSettings} path="/visualisations/projects/settings" withLayout exact strict /> */}
				<AuthRoute
					component={ProjectEdit}
					path="/visualisations/projects/edit/:id"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={ProjectEdit}
					path="/visualisations/projects/new"
					withLayout
					exact
					strict
				/>
				{/* <AuthRoute component={ProjectPreview} path="/visualisations/projects/preview" withLayout exact strict /> */}
				<AuthRoute
					component={Projects}
					path="/visualisations/projects/list"
					withLayout
					exact
					strict
				/>
				<AuthRoute
					component={InfographicsRemove}
					path="/visualisations/vue/list"
					withLayout
					exact
					strict
				/>
				{/* <AuthRoute component={UserProfile} path="/user/profile" withLayout exact strict /> */}
				{/* <AuthRoute component={UserSettings} path="/user/settings" withLayout exact strict /> */}
				<AuthRoute component={HomeDashboard} path="/" withLayout exact strict />
				<AuthRoute component={NotFound} withLayout />
			</Switch>
		</Router>
	);
});

function App() {
	return (
		<Router>
			<Provider store={rootReducer}>
				<RootRouter />
			</Provider>
		</Router>
	);
}

export default App;
