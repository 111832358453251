import { useEffect, useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";

import { Button, Checkbox, Modal, Radio, Select, Space, Table, Typography } from "antd";
import { Maybe, RDSA, RSetter } from "../../../interfaces/Utils";
import { Sorting } from "../../../model/search";
import {
	buildInfoAction,
	InfoState,
	INFO_SET_SORTING,
} from "../../../store/infographics";
import { processValue } from "./dataSourceOrderHandlers";

const { Text } = Typography;

interface SelectKey {
	label: string;
	value: string;
}
type Keys = SelectKey[];

interface SelectorProps {
	keys: Keys;
	state: Maybe<Sorting>;
	setStateRef: React.MutableRefObject<RSetter<Maybe<Sorting>>>;
}
function Selectors(props: SelectorProps) {
	const { state, setStateRef, keys } = props;
	const setState = setStateRef?.current;
	const elements: JSX.Element[] = [];

	if (!state) {
		return null;
	}

	elements.push(
		<Select
			style={{ width: "200px" }}
			onChange={(v) => setState({ key: v as string, ordering: "desc" })}
			value={state.key}
		>
			{keys.map(({ label, value }) => (
				<Select.Option value={value}>{label}</Select.Option>
			))}
		</Select>
	);

	if (state.key && state.key !== "") {
		elements.push(
			<Select
				style={{ width: "200px" }}
				onChange={(v) =>
					setState({ ...state, ordering: v === "asc" ? "asc" : "desc" })
				}
				value={state.ordering}
			>
				<Select.Option value="asc">Ascendant</Select.Option>
				<Select.Option value="desc">Descendant</Select.Option>
			</Select>
		);
	}

	return <Space>{elements}</Space>;
}

function DataSourceSorter() {
	const { info }: { info?: InfoState } = useStore().getState();
	const cols = info?.checked;
	const sorting = info?.desc.sorting;

	function processer(title: string, _value: string, mask: string): SelectKey {
		return { label: title, value: mask };
	}

	function reducer(acc: SelectKey[], value: React.Key) {
		const pv = processValue(value, processer);
		return pv ? [...acc, pv] : acc;
	}

	const keys = cols?.reduce(reducer, [
		{
			label: "Meta : Date d'ajout",
			value: "_created",
		},
	]);
	const status = useState<Maybe<Sorting>>(sorting);
	const [state, setState] = status;
	const setStateRef = useRef(setState);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(buildInfoAction(INFO_SET_SORTING, { sorting: state }));
	}, [state]);
	// Application d'un tri par défaut si besoin
	if (keys) {
		const check = keys.filter((e) => e.value === state?.key).length > 0;
		if (!state || !state.key || !check) {
			if (info?.props?.definition.idx_label === "IDX_BOAMP") {
				setState({ key: "_created", ordering: "desc" });
			} else {
				setState({ key: "contract.officialisation", ordering: "desc" });
			}
		}
	}
	return (
		<Space style={{ height: 30 }}>
			{/* <Checkbox
				checked
				onChange={({ target: { checked } }) =>
					setState(checked ? { key: "", ordering: "desc" } : undefined)
				}
				hidden
			>
				Appliquer un tri par défaut
			</Checkbox> */}
			Tri :{/* {state ? ( */}
			<Selectors keys={keys ?? []} state={state} setStateRef={setStateRef} />
			{/*  ) : null} */}
		</Space>
	);
}

export default DataSourceSorter;
