import { Tag } from "antd";
import { StarFilled } from "@ant-design/icons";

import "../../css/tools/TagViewGenerator.css";

const white = "#ffffff";
const black = "rgba(0, 0, 0, 0.65)";

// #region tags
interface tagTypeProps {
	type: string;
	text: string;
	tagType?: string;
	tagColor?: string;
	textColor?: string;
	icon?: JSX.Element;
}

const defaultTag: tagTypeProps = {
	type: "error",
	text: "Erreur",
	tagType: "error",
};

const tagTypes: tagTypeProps[] = [
	{
		type: "une",
		text: "À la Une",
		tagColor: "#faad14",
		textColor: white,
		icon: <StarFilled />,
	},
	{
		type: "rubrique",
		text: "Rubrique Data",
		tagColor: "#87e8de",
		textColor: black,
	},
	{
		type: "article",
		text: "Article",
		tagColor: "#d3adf7",
		textColor: black,
	},
	{
		type: "published",
		text: "Publié",
		tagColor: "purple",
	},
	{
		type: "draft",
		text: "Brouillon",
		tagColor: "gold",
	},
	{
		type: "default",
		text: "Vue par défaut",
		tagColor: "#722ed1",
		icon: <StarFilled />,
	},
	{
		type: "dynamic",
		text: "Vue dynamique",
		tagColor: "purple",
	},
	{
		type: "static",
		text: "Vue statique",
		tagColor: "gold",
	},
];
// #endregion

interface TagViewGeneratorProps {
	type: string;
}

function TagViewGenerator(props: TagViewGeneratorProps) {
	const { type } = props;
	const tag = tagTypes.find((t) => t.type === type) ?? defaultTag;

	return (
		<Tag
			id="inside-tag"
			color={tag.tagColor}
			icon={tag.icon}
			style={{ color: tag.textColor, height: 22}}
		>
			{tag.text}
		</Tag>
	);
};

export default TagViewGenerator;
