import { useState } from "react";

import { Select } from "antd";

import { RenderValueMRef, SelectArgs } from "./interface";

export default function renderer(
    key: string,
    data: any,
    args: SelectArgs,
    disabled: boolean,
    rs: RenderValueMRef,
): JSX.Element {
    const [value, setValue] = useState<string>(data);
    if ((args?.values?.length ?? 0) === 0) {
        return (
            <div style={{ color: 'red' }}>
                <i>Cette colonne a mal été configurée.<br />Expected Tags got nothing for {key}</i>
            </div>
        );
    }

    rs.current[key] = () => value;

    return <Select
        style={{ minWidth: 300, width: "100%" }}
        value={value}
        mode={(args.multiple ?? false) ? 'multiple' : undefined}
        options={args.values}
        onChange={(v) => setValue(v)}
        disabled={disabled}
    />
}
