import { Spin } from "antd";
import { ReactNode } from "react";
import { Maybe } from "../../interfaces/Utils";

interface MaybeSpinnerProps {
	children: Maybe<ReactNode[] | ReactNode>;
	loading: Maybe<boolean>;
	size?: "small" | "default" | "large";
}

function MaybeSpinner(props: MaybeSpinnerProps) {
	const { children, loading, size } = props;
	return loading ? <Spin size={size}>{children}</Spin> : <>{children}</>;
}

export default MaybeSpinner;
