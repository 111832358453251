import { useState } from "react";
import { useStore } from "react-redux";
import {
	SortableContainer as sortableContainer,
	SortableElement as sortableElement,
	SortableHandle as sortableHandle,
	SortEnd,
	SortEvent,
} from "react-sortable-hoc";

import { Button, message, Modal, Radio, RadioChangeEvent, Table, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { InfoState, MinimalInfoState, setDescription } from "../../../store/infographics";
import { getCols, sortCols, visibilities } from "./dataSourceOrderHandlers";
import { ColumnProps, visibilityOptions } from "./interfaces";
import { Map, RSetter } from "../../../interfaces/Utils";
import { DeepObject } from "../../../lib/array";

const SortableContainer = sortableContainer((props: any) => <tbody {...props} />);
const SortableItem = sortableElement((props: any) => <tr {...props} />);
const DragHandle = sortableHandle(({ active }: { active: boolean }) => (
	<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));

function findIndex(restProps: {
	[key: string]: any;
}): (value: ColPropsWIndex) => boolean {
	return function ({ index }) {
		return index === restProps["data-row-key"];
	};
}

function DraggableBodyRow(
	dataSource: ColPropsWIndex[],
	selectedItems: number[],
	setSelectedItems: (selectedItems: number[]) => void
) {
	return function (props: Map<any>) {
		const { style, className, ...restProps } = props;
		const index = dataSource.findIndex(findIndex(restProps));
		return (
			<SortableItem
				index={index}
				{...restProps}
				selected={selectedItems.length}
				onClick={(e: any) => {
					if (e?.ctrlKey || e?.metaKey) {
						selectedItems.includes(index)
							? selectedItems.splice(selectedItems.indexOf(index), 1)
							: selectedItems.push(index);
						setSelectedItems(selectedItems);
					} else {
						setSelectedItems([]);
					}
				}}
			/>
		);
	};
}

export { DraggableBodyRow, DragHandle };

function DraggableContainer(onSortEnd: any) {
	return function (props: any) {
		return (
			<SortableContainer
				useDragHandle
				disableAutoscroll
				helperClass="row-dragging"
				onSortEnd={onSortEnd}
				{...props}
			/>
		);
	};
}

function merge<T>(a: T[], b: T[], i = 0) {
	const aa = [...a];
	return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
}

const { Text } = Typography;

type ColPropsWIndex = ColumnProps & { index: number };

interface DataState {
	visible: boolean;
	cols: ColPropsWIndex[];
}

const defaultState: DataState = { visible: false, cols: [] };

function onChange(
	title: string,
	cols: ColPropsWIndex[],
	setCols: RSetter<ColPropsWIndex[]>
): (e: RadioChangeEvent) => void {
	return function (e: RadioChangeEvent) {
		const {
			target: { value },
		} = e;
		const index = cols.findIndex(({ title: cTitle }) => cTitle === title);
		if (index === -1) {
			return;
		}
		const n = [...cols];
		cols[index].visibility = value;
		setCols(n);
	};
}

function DataSourceOrder() {
	const store = useStore();
	const [state, setState] = useState<DataState>(defaultState);
	const setCols = (cols: ColPropsWIndex[]) => setState({ ...state, cols: cols });
	const { visible: isModalVisible, cols } = state;
	const [selectedItems, setSelectedItems] = useState<number[]>([]);

	// #region FilterModal
	function showModal() {
		const { info }: { info?: InfoState } = store.getState();
		const defCols =
			info?.desc.default_columns ?? info?.vueTriple?.vue.default_columns;
		const c: ColPropsWIndex[] = getCols(defCols, info, cols)
			.map((e, i) => ({ ...e, index: i }))
			.filter(({ maskKey }) => maskKey && !maskKey.endsWith(".en"));
		setState({ visible: true, cols: c });
	}

	function handleOk() {
		const [default_, filtered] = sortCols(cols);
		const e: MinimalInfoState = store.getState();
		if (default_.length === 0 || filtered.length === cols.length) {
			message.error("Au moins une colonne doit être affichée par défaut.");
			return;
		}
		store.dispatch(
			setDescription({
				...e.info.desc,
				filtered_columns: filtered,
				default_columns: default_,
			})
		);
		setState({ visible: false, cols: state.cols });
	}

	function handleSort() {
		const sorted: ColPropsWIndex[] = [...cols];
		sorted.sort((a, b) => {
			const av = visibilities.indexOf(a.visibility);
			const bv = visibilities.indexOf(b.visibility);
			return av === bv ? 0 : av - bv;
		});
		setState({
			...state,
			cols: sorted,
		});
	}

	function onSortEnd(sort: SortEnd, _: SortEvent) {
		const { oldIndex, newIndex } = sort;
		let tempDataSource = cols;

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				const movingItem = tempDataSource[oldIndex];
				tempDataSource.splice(oldIndex, 1);
				tempDataSource = merge(tempDataSource, [movingItem], newIndex);
			} else {
				const filteredItems: ColPropsWIndex[] = [];
				selectedItems.forEach((d) => {
					filteredItems.push(tempDataSource[d]);
				});
				const newData: ColPropsWIndex[] = [];
				tempDataSource.forEach((d, i) => {
					if (!selectedItems.includes(i)) {
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = merge(tempDataSource, filteredItems, newIndex);
			}
			setState({
				...state,
				cols: tempDataSource,
			});
			setSelectedItems([]);
		}
	}

	const handleCancel = () => setState(defaultState);
	// #endregion
	// Initialise l'ordre et la visibilité des collonnes lors de la création d'une nouvelle vue
	if (cols.length === 0) {
		const { info }: { info?: InfoState } = store.getState();
		const defCols =
			info?.desc.default_columns ?? info?.vueTriple?.vue.default_columns;
		const c: ColPropsWIndex[] = getCols(defCols, info, cols)
			.map((e, i) => ({ ...e, index: i }))
			.filter(({ maskKey }) => maskKey && !maskKey.endsWith(".en"));
		setState({ visible: false, cols: c });
	}
	return (
		<>
			<Button onClick={showModal}>Paramétrer les colonnes</Button>
			<Modal
				title={<Text>Colonnes et tri</Text>}
				style={{
					minWidth: 768,
				}}
				bodyStyle={{ padding: 0 }}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					// <Text>Ce filtre inclue {filters.length} clause{filters.length > 1 ? 's' : null}.</Text>,
					// <Text style={{ marginRight: 650 }}>
					// 	4 colonnes affichées par défaut.
					// </Text>,
					<Button key="back" onClick={handleSort}>
						Trier
					</Button>,
					<Button key="back" onClick={handleCancel}>
						Annuler
					</Button>,
					<Button key="use" type="primary" onClick={handleOk}>
						Sauvegarder
					</Button>,
				]}
			>
				<Table
					scroll={{ x: 500, y: 400 }}
					pagination={cols.length > 20 ? { pageSize: 20 } : false}
					dataSource={cols}
					rowKey="index"
					components={{
						body: {
							wrapper: DraggableContainer(onSortEnd),
							row: DraggableBodyRow(cols, selectedItems, setSelectedItems),
						},
					}}
					columns={[
						{
							title: "",
							dataIndex: "",
							width: 50,
							render: (_d, _dd, i) => (
								<DragHandle active={selectedItems.includes(i)} />
							),
						},
						{
							title: "Nom",
							dataIndex: "title",
						},
						{
							title: "Visibilité",
							dataIndex: "title",
							align: "center",
							width: 370,
							render: (title, col) => {
								const { visibility } = col;
								return (
									<Radio.Group
										options={visibilityOptions}
										onChange={onChange(title, cols, setCols)}
										value={visibility ?? "hidden"}
										// optionType="button"
									/>
								);
							},
						},
					]}
				/>
			</Modal>
		</>
	);
}

export default DataSourceOrder;
