import { Card, Row, Typography, } from 'antd';

import "../../../css/indicators/management.css";

import IntegrationCode, { toHighlightF } from '../../tools/IntegrationCode';

const { Title, Paragraph, } = Typography;


export const codeSnippet = toHighlightF;

interface CardAdminProps {
	id: string,
	indicatorId: string
}

const CardAdmin = (props: CardAdminProps) => {
	const { id, indicatorId } = props;

	return (
		<Card
			className="management-cards semi-cards card-admin"
			title="Contacter la techno au sujet de cet indicateur"
			style={{ width: "100%", userSelect: 'none' }}
		>
			<Row>
				<Title level={5}>En cas de besoin ... !</Title>
			</Row>
			<Row>
				<Paragraph copyable={{ tooltips: ['Copier', 'Copié !'], text: indicatorId }}>{"Un problème avec cet indicateur ? Merci de joindre l'identifiant unique avec votre message sur redmine !"}</Paragraph>
			</Row>
			<Row>
				<IntegrationCode id={id} />
			</Row>
		</Card>
	)
}

export default CardAdmin;
