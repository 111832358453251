import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import _ from "lodash";
import _uniqueId from "lodash/uniqueId";

import { useForm } from "antd/lib/form/Form";
import { CloseOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	DatePicker,
	Input,
	InputNumber,
	Row,
	Checkbox,
	Form,
	Card,
	Space,
	Select,
	Modal,
} from "antd";

import { Map, Maybe, RDSA } from "../../../../interfaces/Utils";
import { AllowOrigin, ROOT_API_URL } from "../../../../lib/fetch";
import { capitalize } from "../../../../lib/strings";
import { MinimalLoginState } from "../../../../store/login/interface";
import { CloseAction } from "../../../../store/sidebar";

import { Node } from "../../interface";

import MaybeSpinner from "../../../tools/MaybeSpinner";
import inputMap from "./inputMap.json";
import defaultMasks from "../common/masks";
import { cardStyle } from "../common/constants";
import metaOptions from "./meta_sectors.json";

const im: Map<Map<string>> = inputMap;

interface LoadAndError {
	loading?: boolean;
	err?: string;
}

function editor(node: Node, setNode: RDSA<Node>) {
	return (k: string) => (value: any) =>
		setNode({
			labels: node.labels,
			attributes: {
				...node.attributes,
				[k]: value,
			},
		});
}
// TODO Typage
function reindex(headers, props, id, label, onOk, onErr) {
	fetch(`${ROOT_API_URL}/neo/index`, {
		headers: headers,
		method: "PUT",
		body: JSON.stringify({
			props: { ...props, uid: id },
			uid: id,
			label: label,
		}),
	}).then((r) => {
		if (r.ok) onOk(r);
		else onErr(r);
	});
}

function SaveNode(
	userId: Maybe<string>,
	id: string,
	node: Node,
	onOk: (r: Response) => void,
	onErr: (r: Response) => void,
	setLoading: (v: boolean) => void
) {
	return () => {
		if (!userId) {
			console.error("No access granted!");
			return;
		}

		const headers = {
			...AllowOrigin,
			Authorization: userId,
		};

		setLoading(true);
		fetch(`${ROOT_API_URL}/neo/node/edit/${id}`, {
			headers: headers,
			method: "POST",
			body: JSON.stringify({
				labels: node.labels,
				attrs: node.attributes,
			}),
		}).then((r) => {
			if (r.ok) {
				// HOTFIX PERMETTANT DE REINDEXER CORRECTEMENT LES CHAMPIONSHIPS (CAS PARTICULIER CAR 3 LAB) DANS ELS
				if (node.labels.includes("Championship")) {
					reindex(
						headers,
						node.attributes,
						id,
						"Championship",
						reindex(
							headers,
							node.attributes,
							id,
							"Organization",
							onOk,
							onErr
						),
						onErr
					);
				} else if (node.labels.includes("Organization")) {
					reindex(headers, node.attributes, id, "Championship", onOk, onErr);
				} else {
					onOk(r);
				}
			} else onErr(r);
		});
	};
}

//! TODO: Keep but move elsewhere, behaviour to fix
function useAutoclose(callback: () => void) {
	const location = useLocation();
	const locRef = useRef<any>();
	useEffect(() => {
		if (locRef.current === undefined) {
			locRef.current = location;
		} else {
			locRef.current = undefined;
			callback();
		}
	}, [location]);
}

function getElementByType(type: string): JSX.Element | null {
	switch (type) {
		case "bigint":
		case "number":
			return <InputNumber decimalSeparator="," />;
		case "date":
			return <DatePicker />;
		case "boolean":
			return <Checkbox />;
		case "string":
		default:
			return <Input />;
	}
}

interface NodeEditProps {
	data: Node;
}

function NodeEdit(props: NodeEditProps) {
	const { data } = props;
	const {
		login: { userId },
	}: MinimalLoginState = useStore().getState();
	const [node, setNode] = useState<Node>(data);
	const [loadAndMessage, setLoadAndMessage] = useState<LoadAndError>({});
	const { loading, err: error } = loadAndMessage;
	const reduxDispatch = useDispatch();
	const {
		attributes,
		attributes: { uid: id },
	} = node;
	const joinedLabels = node.labels.sort().join(".");
	const types = im[joinedLabels] ?? {}; // Add its fetching
	function closeSidebar() {
		reduxDispatch(CloseAction);
	}
	// const onChange = editor(node, setNode);

	const onDiscard = closeSidebar;
	const [form] = useForm();

	const [open, setOpen] = useState(true);
	const card = (
		<Modal
			title="Édition"
			centered
			visible={open}
			onCancel={() => {
				closeSidebar();
				setOpen(false);
			}}
			footer={null}
			width="90vw"
			// height="90vh"
			bodyStyle={{ height: "fit-content", overflow: "auto" }}
		>
			{error ? <Alert message={error} type="error" /> : null}
			<Form
				form={form}
				name="edit-form"
				initialValues={attributes}
				onFinish={(values) => {
					SaveNode(
						userId,
						id,
						{
							labels: data.labels,
							attributes: {
								...values,
								_from: props.data.attributes._from,
								_to: props.data.attributes._to,
							},
						},
						() => window.location.reload(),
						(r) =>
							setLoadAndMessage({
								loading: false,
								err: `${r.status} - ${r.statusText}`,
							}),
						(l) => setLoadAndMessage({ loading: l })
					)();
					setOpen(false);
				}}
				onError={(err) => {
					console.log(err);
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault(); // On désactive le press enter pour valider le formulaire
					}
				}}
			>
				{_.reduce(
					attributes,
					(acc, _, key) => {
						if (key === "uid" || key.startsWith("_")) {
							return acc;
						}
						const type = types[key] ?? "string";
						const title = defaultMasks[key] ?? capitalize(key);
						const e = getElementByType(type);
						const elem = (
							<Form.Item
								key={_uniqueId("form_item-")}
								name={key}
								label={title}
							>
								{e}
							</Form.Item>
						);
						return [...acc, elem];
					},
					[] as JSX.Element[]
				)}
				<Form.Item>
					<Row justify="end">
						<Space>
							<Button type="primary" htmlType="submit">
								Sauvegarder
							</Button>
							<Button onClick={onDiscard}>Annuler</Button>
						</Space>
					</Row>
				</Form.Item>
			</Form>
		</Modal>
	);

	return <MaybeSpinner loading={loading}>{card}</MaybeSpinner>;
}

export default NodeEdit;
