import { Map } from "./Utils";
import { OLD_API_URL } from "../lib/fetch";
import { defaultPaginationSize } from "../model/search";

export interface Pagination {
	size: number;
	page: number;
}

export const defaultPagination: Pagination = {
	size: defaultPaginationSize,
	page: 1,
};

export interface Param {
	value: string;
	fixed: boolean;
}

export interface SearchParams {
	url: string;
	params: Map<Param[]>;
	pagination: Pagination;
}

export interface Results<T> {
	total: number;
	results: T[];
}

export function defaultResults<T>() {
	return { total: 0, results: [] as T[] };
}

/**
 * the Href of the URL data type
 */
export type Href = string;

export function ToURL(sp: SearchParams): URL {
	const url = new URL(OLD_API_URL + sp.url);
	Object.keys(sp.params).forEach((k) =>
		sp.params[k].forEach(({ value }) => url.searchParams.append(k, value))
	);

	url.searchParams.append("size", sp.pagination.size.toString());
	url.searchParams.append("page", sp.pagination.page.toString());
	return url;
}

export function ToHref(sp: SearchParams): Href {
	return ToURL(sp).href;
}

export function AddParam(sp: SearchParams) {
	return function (key: string, value: Param | Param[]): SearchParams {
		if (!Array.isArray(value)) {
			value = [value];
		}
		return {
			...sp,
			params: {
				...sp.params,
				[key]: [...sp.params[key], ...value],
			},
		};
	};
}

export class SearchParamsData {
	private url: string;
	private params: Map<string[]>;
	private pagination: Pagination;

	/**
	 * constructs a base instance of the SearchParamsData class
	 * @param url - the targeted trailing URL (after the API_URL path)
	 * @param params - the base params for the url if some are mandatory
	 * @param pagination - the base pagination value
	 */
	constructor(url: string, params: Map<string[]>, pagination?: Pagination) {
		this.url = url;
		this.params = params;
		this.pagination = pagination ?? defaultPagination;
	}

	/**
	 * addParam to the current parameters map
	 * @param key - the key to add to
	 * @param value - the value to add
	 */
	public addParam(key: string, value: string | string[]) {
		if (typeof value === "string") {
			value = [value];
		}

		this.params[key] = [
			...this.params[key],
			...value,
		];
	}

	/**
	 * @returns the constructed URL based on the registered params and the
	 * current pagination
	 */
	public get href(): string {
		return this.URL.href;
	}

	public get URL(): URL {
		const url = new URL(OLD_API_URL + this.url);
		Object.keys(this.params).forEach((k) =>
			this.params[k].forEach((p) => url.searchParams.append(k, p))
		);

		url.searchParams.append("size", this.pagination.size.toString());
		url.searchParams.append("page", this.pagination.page.toString());
		return url;
	}
}
