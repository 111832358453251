import { TAction } from "../store";
import { loginFailedAction } from "../store/login";

export const newHeaders = (): Record<string, string> => ({
	"Access-Control-Allow-Origin": "*",
});

export const withAuthorization = (
	userId: string,
	rec: Record<string, string>
): Record<string, string> => ({
	...rec,
	Authorization: userId,
});

export const AllowOrigin: Record<string, string> = {
	"Access-Control-Allow-Origin": "*",
};

// const Authorization = (v: string): Record<string, string> => ({
// 	Authorization: v,
// });

function insecure(): boolean {
	return process?.env.REACT_APP_INSECURE === "true";
}

function secure(): boolean {
	return !insecure();
}

function protocol(): string {
	return secure() ? "https" : "http";
}

export const buildApiUrl = ({
	host,
	port,
	prefix,
}: {
	host?: string;
	port?: string;
	prefix?: string;
}): string => {
	let addr = `${protocol()}://${host}`;
	if (port) addr += port.startsWith(":") ? port : `:${port}`;
	if (prefix) addr += prefix.startsWith("/") ? prefix : `/${prefix}`;
	return `${addr}/api`;
};

export function rootApiUrl(): string {
	if (!process) {
		return "";
	}
	const host = process.env.REACT_APP_API_HOST ?? "localhost";
	const port = !!process.env.REACT_APP_API_PORT
		? process.env.REACT_APP_API_PORT.startsWith(":")
			? process.env.REACT_APP_API_PORT
			: ":" + process.env.REACT_APP_API_PORT
		: undefined;
	const prefix = process.env.REACT_APP_API_PREFIX;
	let addr = `${protocol()}://${host}`;
	if (port) addr += port.startsWith(":") ? port : `:${port}`;
	if (prefix) addr += prefix.startsWith("/") ? prefix : `/${prefix}`;
	return addr;
}

export function genApiUrl(): string {
	if (!process) {
		return "";
	}
	const host = process.env.REACT_APP_API_HOST ?? "localhost";
	const port = !!process.env.REACT_APP_API_PORT
		? process.env.REACT_APP_API_PORT.startsWith(":")
			? process.env.REACT_APP_API_PORT
			: ":" + process.env.REACT_APP_API_PORT
		: undefined;
	const prefix = process.env.REACT_APP_API_PREFIX;
	return buildApiUrl({ host, port, prefix });
}

export const OLD_API_URL = genApiUrl();
export const ROOT_API_URL = rootApiUrl();
export const PREVIEW_URL = process?.env.REACT_APP_PREVIEW_URL
	?? "https://dev3-football.newstank.eu/fr/preview/data";

export interface Response<T> {
	code: number;
	error?: string;
	value?: T;
}

export type AndThen<T> = (r: Response<T>) => void;

export const ThunkFetch =
	<T>({
		url,
		body,
		andThen,
	}: {
		url: URL;
		body?: BodyInit;
		andThen?: AndThen<T>;
	}): TAction<void, any> =>
		async (dispatch, getState) => {
			const state = getState();
			const userId = state?.login?.userId;
			if (!userId) {
				dispatch(loginFailedAction(401));
				return;
			}

			const headers = {
				...AllowOrigin,
				...(!!userId ? { Authorization: userId } : {}),
			};

			let resp: Response<T> | undefined;
			try {
				resp = await fetch(url.href, {
					headers: headers,
					body: body,
					method: body ? "POST" : "GET",
				}).then(async (r) => {
					if ("code" in r) {
						return r as unknown as Response<T>;
					}
					if (r.ok) {
						return { code: r.status, value: await r.json() };
					}
					let msg: string | undefined;
					try {
						msg = await r.json();
					} finally {
						return { code: r.status, error: msg ?? r.statusText };
					}
				});
			} catch (e: any) {
				resp = { code: 404, error: e ?? "could not be reached" };
			} finally {
				resp = resp ?? { code: 404, error: "could not be reached" };
				if (resp.code === 401) {
					dispatch(loginFailedAction(401));
				} else if (andThen !== undefined) {
					andThen(resp);
				}
			}
		};
