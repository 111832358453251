interface LogoProps {
	collapsed: boolean;
}

const Logo = (props: LogoProps) => {
	const { collapsed } = props;
	return (
		<div
			style={{
				height: 45,
				marginBottom: 12
			}}
		>
			{!collapsed
				? <img
					className="full-logo"
					height={45}
					src={window.location.origin + '/logo_gedata.svg'}
					alt="logo-gedata"
				/>
				: <img
					className="mini-logo"
					height={45}
					src={window.location.origin + '/favicon.svg'}
					alt="logo-gedata-compressé"
				/>
			}
		</div>
	);
}

export default Logo;