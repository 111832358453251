import { Table } from "antd";
import Vue from "../../../../model/vue";

function calcSummary(vue: Vue, len: number) {
	return function (_source: readonly Map<any>[]) {
		const sources = vue?.rawView?.meta?.sources;
		if (sources) {
			return (
				<Table.Summary fixed>
					<Table.Summary.Row>
						<Table.Summary.Cell index={0} colSpan={len}>
							<h3>Source :</h3>
							<br />
							<i>{sources}</i>
						</Table.Summary.Cell>
					</Table.Summary.Row>
				</Table.Summary>
			);
		}
		return undefined;
	};
}

export default calcSummary;
