import Vue from "../../../../model/vue";

function TableHeader({ vue, lang }: { vue: Vue; lang: string }) {
	const description = vue.rawView?.info.public?.description;
	if (!description) {
		return null;
	}
	let tableD;
	try {
		tableD = JSON.parse(description);
	} catch (e) {
		tableD = { fr: description, en: description };
	}
	return <div>{tableD[lang] ?? tableD.fr}</div>;
}

function TableTitle({ title, lang }: { title?: string; lang: string }) {
	if (!title) {
		return null;
	}
	let tableT;
	try {
		tableT = JSON.parse(title);
	} catch (e) {
		tableT = { fr: title, en: title };
	}
	return <h1>{tableT[lang] ?? tableT.fr}</h1>;
}

function ViewInfo(props: ViewInfoProps) {
	const { hideInfo, vue, lang } = props;

	if (hideInfo || window.location.pathname.startsWith("/data/dashboard")) {
		return null;
	}

	return (
		<>
			<TableTitle title={vue.rawView?.info.public?.title} lang={lang ?? "fr"} />
			<TableHeader vue={vue} lang={lang ?? "fr"} />
		</>
	);
}

export default ViewInfo;
