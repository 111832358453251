function rec(spath: string[], obj: any): any {
	if (spath.length === 0) {
		return obj;
	}

	if (Array.isArray(obj)) {
		const v = obj.map(e => rec(spath, e));
		return v;
	}

	const head = spath[0];
	const npath = spath.slice(1,);
	if (typeof obj === "object" && head in obj) {
		return rec(npath, obj[head]);
	}
	return undefined;
}

function explorer(path: string | string[], obj: Object) {
	if (path === undefined) {
		return undefined;
	}
	if (typeof path === "string") {
		path = path.split(".");
	}
	const val = rec(path, obj);
	return val;
}

export function setter<T>(path: string | string[], obj: any, value: T) {
	if (obj === undefined) {
		obj = {};
	}
	if (path.length === 1) {
		path = path[0];
	}
	if (typeof path === "string") {
		obj[path] = value;
	} else {
		const head = path.splice(0, 1)[0];
		setter(path, obj[head], value);
	}
}

export function deepMerge(sources: Object[]) {
	let acc: Object = {}
	for (const source of sources) {
		for (let [key, value] of Object.entries(source)) {
			if (value instanceof Object && key in acc) {
				value = deepMerge([((acc as any)[key]) as Object, value])
			}
			acc = { ...acc, [key]: value }
		}
	}
	return acc;
}

export default explorer;
