import { useEffect, useReducer, useState } from "react";
import { useDispatch, useStore } from "react-redux";

import { Button, Col, Input, Row, Spin } from "antd";

import { loginFailedAction } from "../../../store/login";
import { MinimalLoginState, Newstank } from "../../../store/login/interface";
import { AllowOrigin, ROOT_API_URL } from "../../../lib/fetch";
import { Map } from "../../../interfaces/Utils";
import { Indicator } from "../Management/interface";

import IndicatorDisplay from "../Display";

import "../../../css/indicators/home.css";
// import SelectNewsTank from "../../tools/SelectNewsTank";
// import ComingSoonModal from "../../tools/ComingSoonModal";

// #region data
interface IndicatorSearchState {
	nt?: string;
	mode?: "vue" | "idx";
	page?: number;
	size?: number;
	err?: {
		code: number;
		message?: string;
	};
	data?: Indicator[];
}

const defaultState: IndicatorSearchState = {
	mode: "idx",
};

const SET_NT = "SET_NT";
const SET_MODE = "SET_MODE";
const SET_PAGE = "SET_PAGE";
const SET_SIZE = "SET_SIZE";
const SET_DATA = "SET_DATA";
const SET_ERR = "SET_ERR";

type IndicatorActions =
	| typeof SET_NT
	| typeof SET_MODE
	| typeof SET_PAGE
	| typeof SET_SIZE
	| typeof SET_DATA
	| typeof SET_ERR;

type IndicatorPayload = IndicatorSearchState;

interface Action {
	type: IndicatorActions;
	payload: IndicatorPayload;
}

const ActionsMap = {
	SET_NT: "nt",
	SET_MODE: "mode",
	SET_PAGE: "page",
	SET_SIZE: "size",
	SET_DATA: "data",
	SET_ERR: "err",
};
// #endregion

function reducer(state: IndicatorSearchState, action: Action): IndicatorSearchState {
	if (!state || !action) return state ?? defaultState;
	const { type, payload } = action;
	const updateState = (
		state: IndicatorSearchState,
		payload: IndicatorPayload,
		key: string
	) => ({ ...state, [key]: (payload as any)[key] });

	const key = ActionsMap[type];
	return !!key ? updateState(state, payload, key) : state ?? defaultState;
}

function HomeIndicators() {
	const {
		login: { userId, userData },
	} = useStore<MinimalLoginState>().getState();
	const [state, reactDispatch] = useReducer(reducer, defaultState);
	const reduxDispatch = useDispatch();

	const colors: Map<string> = (
		userData ?? { newstanks: [] as Newstank[] }
	).newstanks.reduce((prev, { name, color }) => ({ ...prev, [name]: color }), {});

	function updater() {
		if (!userId) {
			reduxDispatch(loginFailedAction(401));
			return;
		}

		const headers = {
			...AllowOrigin,
			Authorization: userId,
		};

		const url = new URL(`${ROOT_API_URL}/neo/idx/all`);

		fetch(url.href, {
			headers: headers,
		}).then((r) => {
			if (r.ok) {
				r.json().then((v) => {
					reactDispatch({ type: SET_DATA, payload: { data: v } });
				});
			} else if (r.status === 401) {
				reduxDispatch(loginFailedAction(401));
			} else {
				reactDispatch({ type: SET_ERR, payload: { err: { code: r.status } } });
			}
		});
	}
	useEffect(updater, []);

	if (!state.data) {
		return <Spin>Loading...</Spin>;
	}
	const { data: indicators } = state;

	return (
		<>
			{/* REACTIVATE */}
			{/* <Row align="middle">
				<Col flex="auto">
					<Input
						className="HomeIndicators-input"
						placeholder="Rechercher un indicateur ..."
						addonAfter={<SelectNewsTank nts={nts} setNT={setNT} />}
					/>
				</Col>
				<Col>
					<Row justify="end">
						<Button type="primary" onClick={showModal}>
							Créer un nouvel indicateur
						</Button>
						<ComingSoonModal
							isModalVisible={isModalVisible}
							setIsModalVisible={setIsModalVisible}
							timePeriod={"l'automne 2022"}
						/>
					</Row>
				</Col>
			</Row> */}

			{indicators.map((idx) => {
				const { title, lastUpdate, cleanliness, uid, nt, description } = idx;
				return (
					<IndicatorDisplay
						title={title}
						lastUpdate={lastUpdate}
						cleanliness={cleanliness}
						uid={uid}
						description={description}
						color={colors[nt]}
						home
					/>
				);
			})}
		</>
	);
}

export default HomeIndicators;
