import { Map, Maybe } from "../../../interfaces/Utils";
import { DataType } from "../../../model/filters";

export interface ConditionTypeDef {
	value: string;
	label: string;
	component: string;
}

export function getConditionType(dt?: DataType): Maybe<ConditionTypeDef[]> {
	return dt ? conditionTypes[dt] : undefined;
}

const conditionTypes: Map<ConditionTypeDef[]> = {
	string: [
		{
			value: "contains",
			label: "Contient",
			component: "input",
		},
		{
			value: "equalTo",
			label: "Égal à (=)",
			component: "input",
		},
		{
			value: "strictEqualTo",
			label: "Strictement égal à (==)",
			component: "input",
		},
		{
			value: "startsWith",
			label: "Commence par",
			component: "input",
		},
		{
			value: "endsWith",
			label: "Fini par",
			component: "input",
		},
		{
			value: "isEmpty",
			label: "Est une valeur vide",
			component: "input",
		},
	],
	number: [
		{
			value: "equalTo",
			label: "Égal à (=)",
			component: "input-number",
		},
		{
			value: "between",
			label: "Entre (>= ET =<)",
			component: "input-number-double",
		},
		{
			value: "superiorOrEqualTo",
			label: "Supérieur ou égal à (>=)",
			component: "input-number",
		},
		{
			value: "superiorTo",
			label: "Supérieur à (>)",
			component: "input-number",
		},
		{
			value: "inferiorOrEqualTo",
			label: "Inférieur ou égal à (<=)",
			component: "input-number",
		},
		{
			value: "inferiorTo",
			label: "Inférieur à (<)",
			component: "input-number",
		},
		{
			value: "isEmpty",
			label: "Est une valeur vide",
			component: "input-number",
		},
	],
	date: [
		{
			value: "equalTo",
			label: "Égal à (=)",
			component: "input-date",
		},
		{
			value: "between",
			label: "Entre (>= ET =<)",
			component: "input-period-date",
		},
		{
			value: "before",
			label: "Jusqu'au",
			component: "input-date",
		},
		{
			value: "after",
			label: "À partir du",
			component: "input-date",
		},
	],
};
conditionTypes["array:string"] = conditionTypes.string;
conditionTypes["array:number"] = conditionTypes.number;

export default conditionTypes;
