import locale from "antd/lib/date-picker/locale/fr_FR";
import { DatePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import { Maybe } from "../../../../../interfaces/Utils";
import { RenderArg, RenderValueMRef } from "./interface";

function parseDate(data: any): Maybe<moment.Moment> {
	let date: Maybe<moment.Moment>;
	if (data === undefined) {
		date = undefined;
	} else {
		const d = moment.isMoment(data) ? data : moment(data);
		if (!d.isValid()) {
			date = undefined;
		} else {
			date = d;
		}
	}
	return date;
}
export default function renderer(
	key: string,
	data: any,
	args: RenderArg,
	disabled: boolean,
	rs: RenderValueMRef
): JSX.Element {
	const [value, setValue] = useState(parseDate(data));
	rs.current[key] = () => value;
	return (
		<DatePicker
			locale={locale}
			format="DD MMM YYYY"
			value={value}
			disabled={disabled}
			onChange={(
				v // Transformation pour avoir toujours le bon format en sortie
			) => setValue(v ? moment(v.format("YYYY-MM-DDTHH:mm:ssZ")) : undefined)}
		/>
	);
}
