import ProjectList from "./ProjectList";
import ProjectEdit from "./Edit";
import ListHeader from "./ListHeader";

import "../../css/projects/list.css";
import VisNavigator, { KEYS } from "./VisNavigator";

function Projects() {
	return (
		<>
			<VisNavigator current={KEYS.PROJECTS} />
			<ListHeader />
			<ProjectList />
		</>
	);
}

export default Projects;

export { ProjectList, ProjectEdit };
