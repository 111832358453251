import { useHistory } from "react-router-dom";

import { Button, Result as AntResult } from "antd";
import { ExceptionStatusType } from "antd/lib/result";

interface ResultProps {
	status: ExceptionStatusType;
	message?: string;
	redirection?: string;
	pagename?: string;
}

function Result(props: ResultProps) {
	const { redirection, pagename, status, message } = props;
	const h = useHistory();
	const onClick = () => {
		if (!!h) {
			h.push(redirection ?? "/dashboard");
		} else {
			window.location.assign(window.location.origin);
		}
	};
	const extra = (
		<Button
			style={{
				backgroundColor: "#4b7cf3",
			}}
			onClick={onClick}
			type="primary"
		>
			Retour{!!pagename ? ` à ${pagename}` : ""}
		</Button>
	);

	return (
		<AntResult
			status={status}
			title={status}
			subTitle={message ?? status}
			extra={extra}
		/>
	);
}

export default Result;
