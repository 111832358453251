import { Map } from "../../../../../interfaces/Utils";
import textRenderer from "./default-text";
import numberRenderer from "./default-number";
import dateRenderer from "./date";
import selectRenderer from "./select";
import selectNewstankRender from "./select-newstank";
import tagsRenderer from "./tags";
import { Renderer } from "./interface";

export interface Opt { label: string; value: string }

// mapper is used in renderer with suggestion to map the returned data from API
// to a valid format for the list
export function mapper(label: string, value: string) {
    return function (data: any) {
        if (value === ".") {
            return {
                label: data,
                value: data,
                data: data,
            };
        } else {
            if (value in data && label in data) {
                return {
                    label: data[label],
                    value: data[value],
                    data: data,
                }
            } else {
                return {
                    label: data,
                    value: data,
                    data: data,
                }
            }
        }
    }
}

const keys = Object.freeze({
	DEFAULT_TEXT: "default-text",
	DEFAULT_NUMBER: "default-number",
	DATE: "date",
	SELECT: "select",
	TAGS: "tags",
	SELECT_NEWSTANK: "select-newstank",
});

const handlers = Object.freeze({
	[keys.DEFAULT_TEXT]: textRenderer,
	[keys.DEFAULT_NUMBER]: numberRenderer,
	[keys.DATE]: dateRenderer,
	[keys.SELECT]: selectRenderer,
	[keys.TAGS]: tagsRenderer,
	[keys.SELECT_NEWSTANK]: selectNewstankRender,
});

function getHandler(key?: string): Renderer {
	return !key ? (textRenderer as Renderer) : handlers[key] ?? textRenderer;
}

export default getHandler;
