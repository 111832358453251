import { useEffect } from "react";
import { connect, ConnectedProps, useDispatch, useStore } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { combineReducers } from "@reduxjs/toolkit";

import { Spin, Result as AntResult } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import {
	SetDataAction,
	SetSearchAction,
	SetSuggestionsAction,
} from "../../store/widget/creators";
import { Action as WidgetAction, TimedResponse } from "../../store/widget/interfaces";
import {
	defaultSearch,
	searchReducer,
	ThunkGetVueAndData,
	WidgetState,
	WidgetThunkAction,
} from "../../store/widget";
import Search, { createSearchParams } from "../../model/search";
import DisplayWidget from "./DisplayWidget";
import { AndThen, ROOT_API_URL, ThunkFetch } from "../../lib/fetch";
import Vue, {
	Aggregation,
	defaultSuggestions,
	Suggestions,
	VueData,
} from "../../model/vue";
import loginReducer from "../../store/login";
import { SET_DATA, SET_VUE, SET_VUE_AND_DATA } from "../../store/widget/actions";
import { LoginState } from "../../store/login/interface";
import sidebarReducer from "../../store/sidebar";

type InnerDataWidgetProps = WidgetStore;
const mapStateToProps = (s: WidgetStore): InnerDataWidgetProps => s;

interface DispatchProps {
	updateSearch(
		id: string,
		options?: { editable?: boolean; lang?: string }
	): (s: Search) => void;
	updateSuggestions(id: string): (index: string, key: string, lang?: string) => void;
}

type TD = ThunkDispatch<WidgetState, void, any>;
const mapDispatchToProps = (dispatch: TD): DispatchProps => ({
	updateSearch: function (
		id: string,
		options?: { editable?: boolean; lang?: string }
	): (s: Search) => void {
		return function (s: Search) {
			const url = new URL(`${ROOT_API_URL}/api/v1/data`);
			url.search = createSearchParams(s).toString();
			url.searchParams.append("id", id);
			if (options?.editable) {
				url.searchParams.append("_with-uid", "true");
			}
			if (options?.lang) {
				url.searchParams.append("_lang", options.lang);
			}
			const andThen: AndThen<any> = (r) => {
				const { code, value } = r;
				if (code === 200) {
					const { data }: TimedResponse<VueData, never> = value;
					dispatch(SetDataAction({ ...data }));
				} else {
					dispatch(SetDataAction({ count: 0, hits: [] }));
				}
			};
			dispatch(ThunkFetch({ url: url, andThen: andThen }));
			dispatch(SetSearchAction(s));
		};
	},
	updateSuggestions: function (
		id: string
	): (index: string, key: string, lang?: string) => void {
		return function (index: string, key: string, lang?: string): void {
			const url = new URL(`${ROOT_API_URL}/api/v1/suggs`);
			url.searchParams.append("id", id);
			url.searchParams.append("key", index);
			url.searchParams.append("value", key);
			if (lang) {
				url.searchParams.append("lang", lang);
			}

			const andThen: AndThen<Aggregation[]> = (r) => {
				if (r.code === 200) {
					const suggs: Suggestions = {
						index: index,
						key: key,
						values: (r.value ?? []).sort(
							({ doc_count: dca }, { doc_count: dcb }) => dca - dcb
						),
					};
					dispatch(SetSuggestionsAction(suggs));
				} else {
					dispatch(SetSuggestionsAction(defaultSuggestions));
				}
			};
			dispatch(ThunkFetch({ url: url, andThen: andThen }));
		};
	},
});

const connector = connect(mapStateToProps, mapDispatchToProps);
interface Props extends ConnectedProps<typeof connector> {
	id: string;
	userId: string;
	color: string;
	showCols?: boolean;
	editable?: boolean;
	vue?: Vue;
	data?: VueData;
	hideInfo?: boolean;
	lang?: string;
}

function InnerDataWidget(props: Props) {
	const {
		vue: forceVue,
		data: forceData,
		widget: { search, vue, data, loading, suggestions, err },
		id,
		color,
		showCols,
		editable,
		hideInfo,
		lang,
		updateSearch: _updateSearch,
		updateSuggestions: _updateSuggestions,
	} = props;

	const dispatch = useDispatch();
	useEffect(() => {
		let action: WidgetAction | WidgetThunkAction;
		if (forceVue && forceData) {
			action = {
				type: SET_VUE_AND_DATA,
				payload: { vue: forceVue, data: forceData },
			};
		} else if (forceVue) {
			action = {
				type: SET_VUE,
				payload: { vue: forceVue },
			};
		} else if (forceData) {
			action = {
				type: SET_DATA,
				payload: { data: forceData },
			};
		} else {
			// On vide les données avant au cas ou on ai encore les données d'un ancien tableau
			dispatch({
				type: SET_VUE_AND_DATA,
				payload: { vue: {}, data: {} },
			});
			action = ThunkGetVueAndData(id, { editable: editable, lang: lang });
		}
		dispatch(action);
	}, [forceVue, forceData]);

	const updateSearch = _updateSearch(id, { editable: editable, lang: lang });
	const updateSuggestions = _updateSuggestions(id);
	// Only and only if the full data is loaded we can render it
	if (vue && data) {
		return (
			<DisplayWidget
				id={id}
				color={color}
				showCols={showCols}
				editable={editable}
				vue={vue}
				data={data}
				loading={loading ?? false}
				search={search ?? defaultSearch}
				updateSearch={updateSearch}
				suggestions={suggestions ?? defaultSuggestions}
				updateSuggestions={updateSuggestions}
				hideInfo={hideInfo}
				lang={lang}
			/>
		);
	}
	if (err !== undefined) {
		return (
			<AntResult
				icon={<SmileOutlined />}
				title="Cette visualisation n'a aucune donnée à vous afficher"
			/>
		);
	}
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				overflowX: "scroll",
				display: "flex",
				justifyContent: "center",
				marginTop: 50,
				marginBottom: 50,
			}}
		>
			<Spin size="large" />
		</div>
	);
}

export const ConnectedDataWidget = connector(InnerDataWidget);

export interface WidgetStore {
	login: LoginState;
	widget: WidgetState;
}

interface DataWidgetProps {
	id?: string;
	userId?: string;
	editable?: boolean;
	color: string;
	hideInfo?: boolean;
	lang?: string;
}

function DataWidget(props: DataWidgetProps) {
	const { id, userId, editable, color, hideInfo, lang } = props;
	const store = useStore();
	store.replaceReducer(
		combineReducers({
			login: loginReducer,
			widget: searchReducer,
			sidebar: sidebarReducer,
		})
	);

	if (!!id && !!userId) {
		return (
			<ConnectedDataWidget
				id={id}
				userId={userId}
				color={color}
				editable={editable}
				hideInfo={hideInfo}
				lang={lang}
			/>
		);
	}
	return <>Nothing to show</>;
}

export default DataWidget;
