import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router";

import { message, Row, Spin } from "antd";

import { DataWidget } from "../Widget";
import { MinimalLoginState } from "../../store/login/interface";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../lib/fetch";
import { Indicator } from "./interface";

import { TimedResponse } from "../../store/widget/interfaces";
import IndicatorsNav from "./IndicatorsNav";

function IndicatorsEdit() {
	const storeState = useStore<MinimalLoginState>().getState();
	const { login: { userId } } = storeState;
	const { id } = useParams<{ id: string }>();
	const [{ loading, idx }, setState] = useState<{
		loading: boolean;
		idx?: Indicator;
	}>({ loading: true });

	useEffect(() => {
		if (!userId) return; // rejecting call, unauthorized!

		pullDef(id, userId).then((def) => {
			if (def.error) {
				message.error(def.error);
				return;
			}
			setState({ loading: false, idx: def.idx });
		})
	}, [id, userId]);

	if (loading) {
		return <Spin />;
	}

	return (
		<>
			<IndicatorsNav current="edit" id={id} />
			<Row justify="center">
				<DataWidget
					id={idx?.default_view}
					userId={userId}
					color="#722ed1"
					editable
					hideInfo
				/>
			</Row>
		</>
	);
}

export default IndicatorsEdit;

async function pullDef(id: string, userId: string) {
	const url = new URL(`${ROOT_API_URL}/api/v1/doc/idx/${id}`);
	const headers = withAuthorization(userId, newHeaders());
	const r = await fetch(url.href, { headers: headers });
	if (r.ok) {
		const resp: TimedResponse<Indicator, never> = await r.json();
		return { idx: resp.data };
	}
	return { error: `${r.status} - ${r.statusText}` };
}
