import { Spin, Button } from "antd";
import { ExceptionStatusType } from "antd/lib/result";
import { useReducer, useEffect, } from "react";
import { useDispatch, useStore } from "react-redux";
import { useParams, } from "react-router";
import { Link } from "react-router-dom";
import { Map } from "../../../interfaces/Utils";
import { AllowOrigin, ROOT_API_URL } from "../../../lib/fetch";
import { loginFailedAction } from "../../../store/login";
import { MinimalLoginState, Newstank } from "../../../store/login/interface";
import Result from "../../Result";
import { Definition, Error, } from "./interface";
import MetaCard from "./MetaCard";

interface State {
	idx?: Definition;
	err?: Error;
	loading?: boolean;
}
const setIdx = "SET_IDX";
const setErr = "SET_ERR";
const setLoading = "SET_LOADING";
type Actions = typeof setIdx | typeof setErr | typeof setLoading;
type Payload = State;

interface Action {
	type: Actions;
	payload: Payload;
}

const invalidPayload: Error = { code: 400, message: "invalid payload" };
const invalidPayloadState = (state: State): State => ({
	...state, err: invalidPayload,
});

const initialState: State = ({ loading: true });

function reducer(state = initialState, action: Action): State {
	const { type, payload } = action;
	switch (type) {
		case setIdx:
			if (!payload.idx) return invalidPayloadState(state);
			return { ...state, idx: payload.idx, loading: false, };
		case setErr:
			if (!payload.err) return invalidPayloadState(state);
			return { ...state, err: payload.err, };
		case setLoading:
			if (!payload.err) return invalidPayloadState(state);
			return { ...state, loading: payload.loading, };
		default: return state;
	}
}

// const IndicatorDescriptor = ({ idx, color }: { idx: Indicator, color: string }) => {
// 	const {
// 		nt, index, idx_label, title, cleanliness, description, written_rules,
// 	} = idx;

// 	return (
// 		<Card
// 			style={{
// 				marginTop: 12,
// 				marginBottom: 12,
// 				width: 400
// 			}}
// 		>
// 			{
// 				description
// 					? <>
// 						<Row><Title level={5}>Description</Title></Row>
// 						<Row style={{ marginBottom: 12 }}>{description}</Row>
// 					</>
// 					: null
// 			}
// 			{
// 				written_rules
// 					? <Row>
// 						<Title level={5}>Règles de l'indicateur</Title>
// 					</Row>
// 					: null
// 			}
// 			<Row style={{ marginBottom: 12 }}>
// 				{written_rules}
// 			</Row>
// 			<Row>
// 				<Title level={5}>Propreté</Title>
// 			</Row>
// 			<Row>
// 				<div
// 					style={{
// 						width: 170,
// 						marginBottom: 12
// 					}}
// 				>
// 					<Progress
// 						percent={cleanliness ?? 0}
// 					/>
// 				</div>
// 			</Row>
// 			<Row>
// 				<Title level={5}>Index elastic</Title>
// 			</Row>
// 			<Row style={{ marginBottom: 12 }}>
// 				{index}
// 			</Row>
// 			<Row>
// 				<Title level={5}>Label Neo4J de l'indicateur</Title>
// 			</Row>
// 			<Row style={{ marginBottom: 12 }}>
// 				{idx_label}
// 			</Row>
// 		</Card>
// 	);
// };

const SpecManagement = () => {
	const { login: { userData, userId } } = useStore<MinimalLoginState>().getState();
	const { id, } = useParams<{ id: string }>();
	const [state, reactDispatch] = useReducer(reducer, initialState);
	const reduxDispatch = useDispatch();

	const colors: Map<string> = (userData ?? { newstanks: [] as Newstank[] }).newstanks.reduce((prev, curr) => ({
		...prev,
		[curr.name]: curr.color,
	}), {} as Map<string>);
	const color = (state.idx?.definition.nt ? colors[state.idx.definition.nt] : undefined) ?? 'gray';

	useEffect(() => {
		if (!userId) return;

		const headers = ({
			...AllowOrigin,
			Authorization: userId,
		});

		const url = new URL(ROOT_API_URL + `/neo/clean/spec`);
		url.searchParams.append("idx", id);
		fetch(url.href, { headers, })
			.then(async r => {
				if (r.ok) {
					reactDispatch({ type: setIdx, payload: { idx: await r.json(), }, });
				} else if (r.status === 401) {
					reduxDispatch(loginFailedAction(401));
				} else {
					reactDispatch({ type: setErr, payload: { err: { code: r.status, message: r.statusText, }, }, });
				}
			})
	}, []);

	if (state.err?.code) {
		return <Result status={state.err.code as ExceptionStatusType} />
	} else if (state.loading) {
		return <Spin>LOADING</Spin>
	} else if (!state.idx) {
		return <Result status={(state.err?.code || 500) as ExceptionStatusType} />
	}

	const { idx: { metas, }, } = state;
	return (
		<>
			{/* <IndicatorDescriptor
				color={color}
				idx={definition}
			/> */}
			<Link to={`/indicators/edit/${id}`}>
				<Button style={{ marginBottom: 12, }}>
					Retour à l'indicateur
				</Button>
			</Link>
			{metas.map((m, i) => (
				<MetaCard
					key={i}
					uid={id}
					color={color}
					meta={m}
				/>
			))}
		</>
	);
};

export default SpecManagement;
