import { Typography } from "antd";

import { EmptyProject } from "./interface";
import { dateLine } from "./Display";

const { Paragraph } = Typography;

function MetaInfo(props: { project?: EmptyProject }) {
	const { project } = props;
	if (!project || !project.dates) {
		return null;
	}

	const { dates, authors, uid: id } = project;
	const dateLineElement = dateLine(dates, authors ?? []);
	
	return (
		<Paragraph type="secondary" style={{ fontSize: "80%" }}>
			{dateLineElement}
			<br />
			<span>ID du projet: {id}</span>
		</Paragraph>
	);
}

export default MetaInfo;
