import { ColumnProps } from "../columns";

import defaultTextHandler from "./text";
import defaultNumberHandler from "./number";
import variationHandler from "./variation";
import dateHandler from "./date";
import linkInternalHandler from "./linkInternal";
import linkExternalHandler from "./linkExternal";
import tagsHandler from "./tags";
import actionsHandler from "./actions";
import arrayStringHandler from "./stringArray";

const handlerMap: { [key: string]: (props: ColumnProps, i: number) => any } = {
	"default-text": defaultTextHandler,
	"default-number": defaultNumberHandler,
	variation: variationHandler,
	"link-internal": linkInternalHandler,
	"link-external": linkExternalHandler,
	date: dateHandler,
	tags: tagsHandler,
	actions: actionsHandler,
	"array:string": arrayStringHandler,
};

export default handlerMap;
