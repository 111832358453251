import { TablePaginationConfig } from "antd";
import {
	FilterValue,
	SorterResult,
	TableCurrentDataSource,
} from "antd/lib/table/interface";

import { Map } from "../../../interfaces/Utils";

export type OnChangeEvent = (
	_pagination: TablePaginationConfig,
	_filters: Record<string, FilterValue | null>,
	sorter: SorterResult<Map<any>> | SorterResult<Map<any>>[],
	_extra: TableCurrentDataSource<Map<any>>
) => void;
export type OnChangeEvents = Map<OnChangeEvent>;

export interface Checkable {
	key: string;
	checked: boolean;
}

export type EditionStatus = number;
export const EDITION_STATUSES = Object.freeze({
	EXISTING: (1 << 4) as EditionStatus,
	NEW: (1 << 5) as EditionStatus,
	EDITED: (1 << 0) as EditionStatus,
	DELETED: (1 << 1) as EditionStatus,
	toggle: function toggle(status: EditionStatus, value: EditionStatus): EditionStatus {
		return status ^ value;
	},
	has: function has(status: EditionStatus, value: EditionStatus): boolean {
		return (status & value) !== 0;
	},
	add: function add(status: EditionStatus, value: EditionStatus): EditionStatus {
		return status | value;
	},
	remove: function remove(status: EditionStatus, value: EditionStatus): EditionStatus {
		return status & (~value);
	},
});

export interface Keyed {
	key: React.Key;
}

export type Item = Map<any> & Keyed & { __status: EditionStatus };

export interface EditState {
	editingKey: React.Key;
	oldValue?: Item;
	saving: boolean;
	errors?: string[];
}
