import { Map } from "../../interfaces/Utils";

const idxNames: Map<string> = {
	spt: "Sport",
	clt: "Culture",
	cts: "Cities",
	her: "Higher Education & Research",
	rh: "RH",
	ftb: "Football",
};

const nameIdx: Map<string> = {
	Sport: "spt",
	Culture: "clt",
	Cities: "cts",
	RH: "rh",
	Football: "ftb",
	"Higher Education & Research": "her",
};

const idxKeys = Object.keys(nameIdx);

function abbrv(key: string): string | undefined {
	return nameIdx[key];
}
function name(key: string): string | undefined {
	return idxNames[key];
}

export { idxKeys as keys, idxNames as names, abbrv, name };
