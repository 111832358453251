import { Redirect, useHistory } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import { Form, Input, Button, Checkbox, Card, Alert } from "antd";

import { ThunkLogin } from "../../store/login";
import { LoginError } from "../../store/login/interface";
import { RootState } from "../../store";
import { Map, Maybe } from "../../interfaces/Utils";

import "../../css/auth/index.css";

// #region store boilerplate
interface StateProps {
	userId?: string;
	loginError?: LoginError;
	loading?: boolean;
}

interface DispatchProps {
	doLogin: typeof ThunkLogin;
}

function mapStateToProps({
	login: { userId, loginError, loading },
}: RootState): StateProps {
	return { userId, loginError, loading };
}

const mapDispatchToProps: DispatchProps = {
	doLogin: ThunkLogin,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

interface LoginProps {
	login: string;
	password: string;
	remember: boolean;
}

interface ComponentProps extends ConnectedProps<typeof connector> {}

function Auth(props: ComponentProps) {
	const { userId, loginError, loading, doLogin } = props;
	const h = useHistory();
	if (!!userId) {
		// h.goBack();
		// return null;
		return <Redirect to={(h?.location?.state as Maybe<Map<any>>)?.from || "/"} />;
	}

	const onFinish = ({ login, password, remember }: LoginProps) => {
		doLogin({ login, password, remember });
	};

	return (
		<div
			className="login-page"
			style={{
				height: "100vh",
				width: "100%",
			}}
		>
			<div className="auth-header">
				<div className="logo-container">
					<img
						height={45}
						src={`${window.location.origin}/logo_gedata.svg`}
						alt="logo gedata"
					/>
				</div>
			</div>
			<div className="auth-body">
				<Card className="auth-wrapper card">
					<div className="auth-title">Connexion</div>
					<Form
						name="basic"
						initialValues={{ remember: true }}
						layout="vertical"
						onFinish={onFinish}
					>
						<Form.Item
							label={<div className="auth-form">Identifiant</div>}
							name="login"
							rules={[
								{
									required: true,
									message: "Saisissez votre identifiant",
								},
							]}
						>
							<Input autoComplete="username" />
						</Form.Item>
						<Form.Item
							label={<div className="auth-form">Mot de passe</div>}
							name="password"
							rules={[
								{
									required: true,
									message: "Entrez votre mot de passe",
								},
							]}
						>
							<Input.Password autoComplete="current-password" />
						</Form.Item>
						<Form.Item name="remember" valuePropName="checked">
							<Checkbox className="auth-remember">
								Garder ma session connectée
							</Checkbox>
						</Form.Item>
						<Form.Item style={{ marginBottom: "-0.30rem" }}>
							<Button
								className="auth-btn-login"
								type="primary"
								htmlType="submit"
								loading={!!loading}
							>
								Se connecter
							</Button>
							<Button
								className="auth-forgotten"
								type="link"
								htmlType="submit"
							>
								Identifiants oubliés ? Contactez un administrateur !
							</Button>
						</Form.Item>
					</Form>
					{loginError ? (
						<div id="alert-login-error">
							<Alert
								style={{ marginTop: 10 }}
								message={loginError?.message ?? "Accès refusé"}
								type="error"
								showIcon
								closable
								onClose={() => {
									document
										.getElementById("alert-login-error")
										?.remove();
								}}
							/>
						</div>
					) : undefined}
				</Card>
			</div>
		</div>
	);
}

export default connector(Auth);
