import { BarChartOutlined, FolderOpenOutlined } from "@ant-design/icons";
import Navigator, { TabData } from "../tools/Navigator";

export const KEYS = Object.freeze({
    PROJECTS: "projects",
    VIEWS: "views",
});

const tabs:TabData[] = [{
    key: KEYS.PROJECTS,
    label: "Projets",
    url: "/visualisations/projects/list",
    icon: <FolderOpenOutlined />,
}, {
    key: KEYS.VIEWS,
    label: "Visualisations",
    url: "/visualisations/vue/list",
    icon: <BarChartOutlined />,
}];

interface VisNavigatorProps {
    current: string
}

function VisNavigator(props: VisNavigatorProps) {
    const {current} = props;
    return <Navigator defaultKey={current} tabs={tabs} />
}

export default VisNavigator;
