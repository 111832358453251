import Result from ".";

interface NotFoundProps { redirection?: string, pagename?: string, }

function NotFound(props: NotFoundProps) {
	return (
		<Result
			status="404"
			message="Désolé, la page que vous avez demandé n'existe pas."
			{...props}
		/>
	);
}

export default NotFound;
