function getDomainColor(tags = false): string {
	const {
		location: { hostname },
	} = window;

	if (hostname.indexOf("gedata") !== -1 || hostname.indexOf("sport") !== -1) {
		return tags ? "purple" : "#9254de";
	}
	if (hostname.indexOf("football") !== -1) {
		return tags ? "green" : "#008A46";
	}
	if (hostname.indexOf("culture") !== -1) {
		return tags ? "pink" : "#e32b86";
	}
	if (hostname.indexOf("cities") !== -1) {
		return tags ? "gold" : "#ca8c1a";
	}
	if (hostname.indexOf("rh") !== -1) {
		return tags ? "red" : "#c00444";
	}
	if (hostname.indexOf("education") !== -1) {
		return tags ? "blue" : "#00a9d2";
	}
	if (hostname.indexOf("mobilites") !== -1) {
		return tags ? "cyan" : "#17a396";
	}
	if (hostname.indexOf("energies") !== -1) {
		return tags ? "geekblue" : "#2868a8";
	}
	return tags ? "purple" : "#9254de"; // default color
}

export default getDomainColor;
