import { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps, useDispatch, useStore } from "react-redux";

import {
	Button,
	Space,
	Spin,
	Table,
	TablePaginationConfig,
	Typography,
	Row,
	Tag,
} from "antd";
import {
	FilterValue,
	SorterResult,
	TableCurrentDataSource,
} from "antd/lib/table/interface";
import { SyncOutlined } from "@ant-design/icons";

import { labels as labelsTranslator } from "../../../tools/mappings";
import { Map, Maybe } from "../../../../interfaces/Utils";
import { capitalize } from "../../../../lib/strings";
import { safeIntCast } from "../../../../lib/safeCast";
import { pushFront } from "../../../../lib/functions";
import { compareLocated } from "../../../../lib/location";
import { CloseAction, OpenAction } from "../../../../store/sidebar";
import UniqueSearchBar from "../../../Widget/UniqueSearchBar";
import {
	CurrentEditStore,
	fetchedAction,
	loadingAction,
	selectedNodesAction,
	specAction,
	ThunkFetchNode,
} from "../store";
import { IndicatorSpec, Node, NodeFetch } from "../../interface";
import trlfetch, { fetchIdxData } from "../common/linefetch";
import defaultMasks from "../common/masks";
import reIndex from "../common/reindex";
import FilterDropdown from "../common/FilterDropdown";

import "../../../../css/tools/elements.css";

const { Title } = Typography;

interface ColProps {
	title: string;
	dataIndex?: string | string[];
	render?: any;
	width?: number;
	fixed?: "left" | "right";
	align?: "left" | "center" | "right";
	sorter?: boolean;
}

type KeyedNode = Node & { key: number };

// #region store connection
interface StateProps {
	loading?: boolean;
	spec?: IndicatorSpec;
	selectedNodes: Node[];
	fetched: NodeFetch;
}

function mapStateToProps({
	edit: { loading, spec, selectedNodes, fetched },
}: CurrentEditStore): StateProps {
	return {
		loading: loading,
		spec: spec,
		selectedNodes: selectedNodes,
		fetched: fetched,
	};
}

interface DispatchProps {
	setLoading(v: boolean): void;
	setSpec(spec: IndicatorSpec): void;
	setSelectedNodes(nodes: Node[]): void;
	setFetched(fetched: NodeFetch): void;
	changeSize(page: number, labels: string[]): void;
}

function mapDispatchToProps(
	dispatch: ThunkDispatch<CurrentEditStore, void, any>
): DispatchProps {
	return {
		setLoading: function setLoading(v: boolean): void {
			dispatch(loadingAction(v));
		},
		setSpec: function setSpec(spec: IndicatorSpec): void {
			dispatch(specAction(spec));
		},
		setSelectedNodes: function setSelectedNodes(nodes: Node[]): void {
			dispatch(selectedNodesAction(nodes));
		},
		setFetched: function setFetched(fetched: NodeFetch): void {
			dispatch(fetchedAction(fetched));
		},
		changeSize: function changeSize(page: number, labels: string[]) {
			dispatch(ThunkFetchNode(labels, 1, page));
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

interface NodeListMergeProps extends ConnectedProps<typeof connector> {
	labels: string[];
	searchParams: Map<string[]>;
}

function NodeListMerge(props: NodeListMergeProps) {
	const {
		labels,
		searchParams,
		fetched,
		setFetched,
		loading,
		spec,
		selectedNodes,
		setLoading,
		setSpec,
		setSelectedNodes,
		changeSize,
	} = props;

	const {
		login: { userId },
		sidebar: { collapsed, data },
	}: CurrentEditStore = useStore().getState();

	let masks: Maybe<Map<string>>;
	if (labels) {
		masks = spec?.metas
			.find((m) => m.labels.split(",").sort().join(".") === labels.sort().join("."))
			?.props.reduce(
				(acc, { name, mask }) => ({
					...acc,
					[name]: mask,
				}),
				{} as Map<string>
			);
		if (masks) {
			masks = {
				...masks,
				flag: "Alerte(s)",
			};
		}
	}

	const noded: KeyedNode[] = fetched.values.map((f, i) => ({
		labels: labels,
		attributes: f,
		key: i,
	}));

	// const onChange = onChangeUncurr(searchParams, labels, pagNodes, setPagNodes, userId);
	const fetchOnChange = trlfetch({
		searchParams: searchParams,
		labels: labels,
		fetched: fetched,
		setFetched: setFetched,
		userId: userId,
	});

	const reIndexCurried = reIndex(fetchOnChange, labels, setLoading, userId);

	useEffect(() => {
		// onChange();
		fetchOnChange();
		if (labels && labels.length > 0) {
			fetchIdxData(
				userId,
				labels.find((s) => s.startsWith("IDX_")),
				async (r) => {
					if (!r.ok) {
						// TODO: handle error
						console.error(
							`error fetching counts: code: ${r.status} - message: ${r.statusText}`
						);
						return;
					}
					const specs = await r.json();
					setSpec(specs);
				}
			);
		}
	}, [labels]);

	// #region Sidebar
	const reduxDispatch = useDispatch();
	function openMergeSidebar(mergeData: Node[]): () => void {
		return () => {
			reduxDispatch(loadingAction(true));
			return reduxDispatch(
				OpenAction("node-merge", { mergeData: mergeData, labels: labels })
			);
		};
	}
	// #endregion

	// #region RowSelection
	const rowSelection = {
		onChange: (_: React.Key[], selectedRows: Node[]) => {
			setSelectedNodes(selectedRows);
		},
	};
	// #endregion

	// #region onTableChange
	function onTableChange(
		_pagination: TablePaginationConfig,
		_filters: Record<string, FilterValue | null>,
		sorter: SorterResult<KeyedNode> | SorterResult<KeyedNode>[],
		extra: TableCurrentDataSource<KeyedNode>
	): void {
		if (extra.action !== "sort" || Array.isArray(sorter)) {
			return;
		}
		const { field, order, column } = sorter;
		let attr: string;
		let orderAsc: boolean;
		let newFetched: NodeFetch;
		if (column === undefined || field === undefined) {
			if (Array.isArray(field) && field.length >= 2 && field[1] === "_sort_count") {
				newFetched = {
					...fetched,
					sortCount: undefined,
				};
			} else {
				const { sort, ...restFetched } = fetched;
				newFetched = restFetched;
			}
		} else {
			if (Array.isArray(field)) {
				attr = `${field[1]}`;
			} else {
				attr = `${field}`;
			}
			orderAsc = order !== "descend";
			if (attr === "_sort_count") {
				newFetched = {
					...fetched,
					sortCount: orderAsc,
				};
			} else {
				newFetched = {
					...fetched,
					sort: {
						key: attr,
						asc: orderAsc,
					},
				};
			}
		}
		trlfetch({
			searchParams: searchParams,
			labels: labels,
			fetched: newFetched,
			setFetched: setFetched,
			userId: userId,
		})();
	}
	// #endregion

	// #region updateSearch
	function updateSrcKV(key: string): (values: string[]) => void {
		return (values: string[]) => {
			const { src } = fetched;
			const newFetched = {
				...fetched,
				src: {
					...src,
					[key]: values,
				},
			};

			trlfetch({
				searchParams: searchParams,
				labels: labels,
				fetched: newFetched,
				setFetched: setFetched,
				userId: userId,
			})();
		};
	}
	// #endregion

	// #region columnsGen
	let cols: ColProps[] = [];
	const label = labels.filter((s) => !s.startsWith("IDX_"))[0];
	const labelTitle = labelsTranslator[label];
	if (noded && noded.length > 0) {
		const ignoreList = ["uid", "parent", "_from", "_to"];
		let colkeys: string[] = pushFront(
			"name_EN",
			Object.keys(noded[0].attributes)
				.filter((k) => ignoreList.indexOf(k) === -1)
				.sort(compareLocated)
		);
		colkeys = pushFront("name_FR", colkeys);
		cols = colkeys.map((k) => {
			const title = (masks ?? {})[k] ?? defaultMasks[k] ?? capitalize(k);
			return {
				title: title,
				dataIndex: ["attributes", k],
				filterDropdown: () => {
					return (
						<FilterDropdown
							searchkey={k}
							label={label}
							values={fetched.src[k]}
							updateValues={updateSrcKV(k)}
						/>
					);
				},
				sorter: true,
			};
		});
		if (labels && labels.indexOf("Sector") !== -1) {
			cols.push({
				title: "Occurrences",
				width: 150,
				align: "center",
				dataIndex: ["attributes", "_sort_count"],
				render: (_: Map<any>, node: Node) => {
					const { _from, _to } = node.attributes;
					const total = safeIntCast(_from) + safeIntCast(_to);
					return <div>{total}</div>;
				},
				sorter: true,
			});
		}
		// REACTIVATE
		// cols.push({
		// 	title: "Actions",
		// 	dataIndex: ["attributes", "uid"],
		// 	render: renderEdit,
		// 	fixed: "left",
		// });
	}
	// #endregion

	// #region tableGen
	const pagination: TablePaginationConfig = {
		pageSize: fetched.size ?? 20,
		current: fetched.page ?? 1,
		total: fetched.count,
		showTotal: (total: number, range: [number, number]): React.ReactNode => {
			return (fetched !== undefined && fetched.size && total > fetched.size) ?? 20
				? `${range[0]}-${range[1]} de ${total} éléments`
				: `Total: ${total} éléments`;
		},
		position: ["topRight", "bottomRight"],
		onChange: fetchOnChange,
		onShowSizeChange: (_, size) => changeSize(size, labels),
	};

	let table: JSX.Element;
	if (fetched.values) {
		const searched: JSX.Element[] = Object.keys(fetched.src).reduce((acc, cur) => {
			const values = fetched.src[cur];
			const updater = updateSrcKV(cur);
			return values.length > 0
				? [
						...acc,
						...values.map((v, i) => {
							function onClose() {
								const newValues = [...values];
								newValues.splice(i);
								updater(newValues);
							}
							return (
								<Tag onClick={onClose} onClose={onClose} closable>
									{defaultMasks[cur] ?? cur}: {v}
								</Tag>
							);
						}),
				  ]
				: acc;
		}, [] as JSX.Element[]);
		const {
			src: { _all },
		} = fetched;
		const setAll = updateSrcKV("_all");
		const canMerge = selectedNodes.length > 1;
		table = (
			<Table
				title={() => (
					<>
						<Row
							style={{ marginLeft: 16, marginRight: 16, height: 48 }}
							justify="space-between"
							align="middle"
						>
							<Space align="center">
								<Title level={3} style={{ marginTop: 10 }}>
									{labelTitle}
								</Title>
								<Button type="link" onClick={reIndexCurried}>
									<SyncOutlined />
								</Button>
								{canMerge
									? `${selectedNodes.length} lignes sélectionnées`
									: ""}
								<UniqueSearchBar all={_all ?? []} setAll={setAll} />
							</Space>
							<Button
								type="primary"
								onClick={openMergeSidebar(selectedNodes)}
								disabled={!canMerge}
							>
								Fusionner
							</Button>
						</Row>
						{searched.length > 0 ? <Row>{searched}</Row> : null}
					</>
				)}
				dataSource={noded}
				sticky
				scroll={{
					scrollToFirstRowOnChange: true,
				}}
				size="small"
				onChange={onTableChange}
				columns={cols}
				pagination={pagination}
				rowSelection={{
					type: "checkbox",
					...rowSelection,
				}}
			/>
		);
		if (loading) {
			table = <Spin>{table}</Spin>;
		}
	} else {
		table = <Spin size="large">Chargement en cours...</Spin>;
	}
	// #endregion
	return table;
}

export default connector(NodeListMerge);
