import {
	TableOutlined,
	BarChartOutlined,
	DotChartOutlined,
	PieChartOutlined,
	LineChartOutlined,
	// AreaChartOutlined,
	// RadarChartOutlined,
	// BoxPlotOutlined
} from "@ant-design/icons";

import { VisSelector } from "./VisSelector";

const vis: VisSelector[] = [
	{
		idTable: "table",
		visIcon: <TableOutlined className="Infographics-StepCreate-icons-title" />,
		visTitle: "Tableaux",
		visChildren: [
			{
				title: "Tableau simple",
			},
			// {
			// title: "Tableau croisé dynamique",
			// }
		],
	},
	{
		idTable: "pie",
		visIcon: <PieChartOutlined className="Infographics-StepCreate-icons-title" />,
		visTitle: "Camemberts",
		visChildren: [
			{
				title: "Camembert classique",
			},
			{
				title: "Donut",
			},
			// {
			// 	title: "Jauge",
			// },
			// {
			// 	title: "Sunburst",
			// }
		],
	},
	{
		idTable: "bar",
		visIcon: <BarChartOutlined className="Infographics-StepCreate-icons-title" />,
		visTitle: "Barres",
		visChildren: [
			{
				title: "Barres classiques",
			},
			// {
			// 	title: "Barres empilées",
			// },
			// {
			// 	title: "Barres empilées 100%",
			// }
		],
	},
	{
		idTable: "line",
		visIcon: <LineChartOutlined className="Infographics-StepCreate-icons-title" />,
		visTitle: "Courbes",
		visChildren: [
			{
				title: "Courbes classiques",
			},
			{
				title: "Courbes lissées",
			},
			// {
			// title: "Courbes nombreuses",
			// }
		],
	},
	{
		idTable: "dot",
		visIcon: <DotChartOutlined className="Infographics-StepCreate-icons-title" />,
		visTitle: "Nuages",
		visChildren: [
			{
				title: "Nuages de points",
			},
			{
				title: "Nuages de bulles",
			},
		],
	},
	// {
	// 	idTable: "area",
	// 	visIcon: (<AreaChartOutlined className="Infographics-StepCreate-icons-title" />),
	// 	visTitle: "Aires",
	// 	visChildren: [
	// 		{
	// 			title: "Aires empilées",
	// 		},
	// 		{
	// 			title: "Aires empilées 100%",
	// 		},
	// 		{
	// 			title: "Aires superposées",
	// 		}
	// 	]
	// },
	// {
	// 	idTable: "radar",
	// 	visIcon: (<RadarChartOutlined className="Infographics-StepCreate-icons-title" />),
	// 	visTitle: "Toiles d'araignées",
	// 	visChildren: [
	// 		{
	// 			title: "Radiales",
	// 		},
	// 		{
	// 			title: "Barres",
	// 		},
	// 		{
	// 			title: "Stellaire",
	// 		}
	// 	]
	// },
	// {
	// 	idTable: "box",
	// 	visIcon: (<BoxPlotOutlined className="Infographics-StepCreate-icons-title" />),
	// 	visTitle: "Boites à moustaches",
	// 	visChildren: [
	// 		{
	// 			title: "Boite à moustache",
	// 		}
	// 	]
	// },
];

export default vis;
