import { KeyParent } from "../../Widget/Table/edit/interfaces";

const boamp: KeyParent[] = [
	{
		name: "Newstank",
		prefix: "newstank",
		children: [
			{
				name: "name",
				mask: "Newstank",
				type: "string",
			},
		],
	},
	{
		name: "Deal",
		prefix: "deal",
		children: [
			{
				name: "objet",
				mask: "Objet",
				type: "string",
				fixed: true,
				priority: "REQUIRED",
			},
			{
				name: "date_parution",
				mask: "Date de parution",
				type: "date",
				priority: "REQUIRED",
			},
			{
				name: "date_limite_rep",
				mask: "Date limite de réponse",
				type: "date",
				priority: "REQUIRED",
			},
			{
				name: "date_fin_diffusion",
				mask: "Date de fin de diffusion",
				type: "date",
				priority: "REQUIRED",
			},
			{
				name: "url_doc",
				mask: "Source",
				type: "string",
				priority: "REQUIRED",
			},
			{
				name: "global_amount",
				mask: "Montant total",
				type: "number",
			},
			{
				name: "lot_count",
				mask: "Nombre de lot(s)",
				type: "number",
			},
		],
	},
	{
		name: "publication",
		prefix: "deal.location",
		children: [
			{
				name: "dep_code",
				mask: "Code lieu de publication",
				type: "string",
			},
		],
	},
	{
		name: "Domaine",
		prefix: "domaine",
		children: [
			{
				name: "name",
				mask: "Domaine",
				type: "string",
			},
			// {
			//     name: "code",
			//     mask: "Code domaine",
			//     type: "string"
			// }
		],
	},
	{
		name: "Famille",
		prefix: "famille",
		children: [
			{
				name: "name",
				mask: "Famille",
				type: "string",
			},
			// {
			//     name: "code",
			//     mask: "Code famille",
			//     type: "string"
			// }
		],
	},
	{
		name: "Procedure",
		prefix: "procedure",
		children: [
			{
				name: "name",
				mask: "Procédure",
				type: "string",
			},
			// {
			//     name: "code",
			//     mask: "Code procédure",
			//     type: "string"
			// }
		],
	},
	{
		name: "Emetteur",
		prefix: "emetteur",
		children: [
			{
				name: "name",
				mask: "Émetteur",
				type: "string",
			},
		],
	},
	{
		name: "Commune Emetteur",
		prefix: "emetteur.location",
		children: [
			{
				name: "commune",
				mask: "Commune émetteur",
				type: "string",
			},
			// {
			// 	name: "zip_code",
			// 	mask: "Code lieu émetteur",
			// 	type: "string",
			// },
			// {
			//     name: "dep_code",
			//     mask: "Code département émetteur",
			//     type: "string"
			// },
			// {
			//     name: "dep",
			//     mask: "Département émetteur",
			//     type: "string"
			// },
			// {
			//     name: "reg",
			//     mask: "Région émetteur",
			//     type: "string"
			// }
		],
	},
];

export default boamp;
