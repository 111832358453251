import { Progress, Row, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { Map } from "../../../../interfaces/Utils";
import { priorities, types } from "./constants";
import { updateSpec } from "./network";

const { Paragraph } = Typography;

export interface DataDescription {
	key: string;
	title?: string;
	frozen?: boolean;
	inputType?: "string" | "number" | "date" | "select"; // defaults to string input
	selectData?: string[];
	description?: string | React.ReactNode;
	render?: (v: any, rec: any) => string | React.ReactNode;
	icon?: (data: Map<any>) => React.ReactNode;
}

export const dataDescription = (userId: string): DataDescription[] => [
	{
		key: "mask",
		title: "Nom pour les clients",
		description: "Nom affiché pour les utilisateurs externes.",
	},
	{
		key: "cleanliness",
		frozen: true,
		title: "Propreté",
		render: (v: any) => <Progress percent={v ?? 0} />,
		icon: ({ uid }) => (
			<SyncOutlined
				onClick={() => {
					updateSpec(uid, userId);
				}}
			/>
		),
	},
	{
		key: "flag",
		title: "Valeur si vide",
		description: <Row>Valeur affichée si valeur manquante.</Row>,
	},
	{
		key: "priority",
		title: "Priorité",
		description:
			"Niveau d'importance de la donnée. Sert au calcul de propreté de l'indicateur",
		inputType: "select",
		selectData: priorities,
	},
	{
		key: "type",
		title: "Type de données",
		inputType: "select",
		selectData: types,
	},
	{
		key: "uid",
		frozen: true,
		title: "Référence interne",
		render: (v: any, rec: any) => {
			const internalName = rec.name ?? "";
			console.log(rec, v);
			return (
				<div>
					{internalName}
					<Paragraph copyable>{v}</Paragraph>
				</div>
			);
		},
	},
];
