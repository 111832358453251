import { useLocation } from "react-router";

import QueryString, { parse } from "qs";

import { Maybe } from "../interfaces/Utils";


export function getStringParam(params: QueryString.ParsedQs, key: string): Maybe<string> {
	return getString(params[key]);
}

export function getStringFromParams(key: string): Maybe<string> {
	const params = useQueryParams();
	return getStringParam(params, key);
}

export function getString(v: Maybe<string | string[] | QueryString.ParsedQs | QueryString.ParsedQs[]>): Maybe<string> {
	if (typeof v === "string") {
		return v;
	} else if (Array.isArray(v)) {
		const last = v[v.length - 1];
		if (typeof last === "string") {
			return last;
		}
	}
	return undefined;
}

// A custom hook that builds on useLocation and qs to parse the query string.
export function useQueryParams(): QueryString.ParsedQs {
	const { search } = useLocation();
	return parse(search, { ignoreQueryPrefix: true });
}
