import { useState } from "react";
import { useStore } from "react-redux";

import { Button, Input, List } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { Maybe } from "../../../../interfaces/Utils";
import { MinimalLoginState } from "../../../../store/login/interface";
import { JSAggregation } from "../../interface";
import { fetchSuggs } from "./linefetch";

interface FilterDropdownProps {
	updateValues(vls: string[]): void;
	values: string[];
	searchkey: string;
	label: string;
}


function FilterDropdown(props: FilterDropdownProps) {
	const { updateValues, values, searchkey, label, } = props;
	const [suggs, setSuggs] = useState<JSAggregation[]>([]);
	const [buf, setBuf] = useState<string>("");
	const { login: { userId: checkUserId } } = useStore<MinimalLoginState>().getState();

	if (!checkUserId) {
		return (<div>Veuillez vous reconnecter</div>);
	}

	// This "forces" typescript to acknowledge userId is not undefined as check
	// right before. Without this, any function after this point would not know
	// userId is not undefined, because of the compiler.
	const userId = checkUserId;

	function onChange(e?: React.ChangeEvent<HTMLInputElement>) {
		const s = e?.target.value ?? "";
		if (s.length > 2) {
			fetchSuggs(userId, searchkey, label, s)
				.then(v => setSuggs(v.data.map(({ key, value }) => ({ k: key, value: value }))));
		}
		setBuf(e?.target.value ?? "");
	}

	const list: Maybe<JSX.Element> = (suggs.length > 0)
		? (
			<div style={{ overflowY: 'scroll', height: 400, width: 280, }}>
				<List
					size='small'
					itemLayout='horizontal'
					dataSource={suggs}
					renderItem={({ k, value }, i) => (
						<List.Item
							onClick={_ => {
								updateValues([...(values ?? []), k])
								// setValue(k);
								setSuggs([]);
								setBuf("");
							}}
							key={'' + i}
							style={{ cursor: 'pointer' }}
						>
							<div>{k} ({value})</div>
						</List.Item>
					)}
				/>
			</div>
		)
		: undefined;

	return (
		<>
			<Input.Group
				compact
				style={{ padding: 8, width: 280 }}
			>
				<Input
					value={buf}
					onChange={onChange}
					style={{ width: 'calc(100% - 47px)' }}
					onPressEnter={() => {
						const v = buf;
						setBuf("");
						updateValues([...(values ?? []), v]);
					}}
					/>
				<Button
					onClick={() => {
						const v = buf;
						setBuf("");
						updateValues([...(values ?? []), v]);
					}}
				>
					<SearchOutlined />
				</Button>
			</Input.Group>
			{/* <Row>
				{values && values.length > 0
					? values.map((v, i) =>
						<Tag
							onClick={(_) => {
								const newValues = [...values];
								newValues.splice(i);
								updateValues(newValues)
							}}
							closable
							style={{ marginLeft: 12 }}
						>
							{v}
						</Tag>
					)
					: null}
			</Row> */}
			{list}
		</>
	);
}

export default FilterDropdown;